import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@ionic-native/file';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ToolsService } from '~/app/shared/services/tools.service';
var MenuComponent = (function () {
    function MenuComponent(applicationState, toolsService, applicationData, modalService, localize, file, router) {
        this.applicationState = applicationState;
        this.toolsService = toolsService;
        this.applicationData = applicationData;
        this.modalService = modalService;
        this.localize = localize;
        this.file = file;
        this.router = router;
        this.environment = environment;
        this.ContextTypes = ContextTypes;
        this.state = new EventEmitter();
    }
    MenuComponent.prototype.ngOnInit = function () {
        this.catalogs = this.applicationData.getMediaByType(2).filter(function (item) {
            return item.isInMenu;
        });
    };
    MenuComponent.prototype.openLink = function (e) {
        if (environment.CONTEXT == ContextTypes.APP) {
            e.preventDefault();
            cordova.InAppBrowser.open(e.currentTarget.href, '_blank', 'location=no');
        }
    };
    /**
     * Close
     */
    MenuComponent.prototype.close = function () {
        this.state.emit({
            state: false
        });
    };
    return MenuComponent;
}());
export { MenuComponent };
