import { EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, animate, style, transition } from '@angular/animations';
import { File } from '@ionic-native/file';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ApplicationData } from '~/app/shared/data/application.data';
export var videoTransition = trigger('videoTransition', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)')
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0 })),
    ])
]);
/**
 * This class represents the video component.
 */
var VideoComponent = (function () {
    /**
     * Constructor
     */
    function VideoComponent(applicationData, localize, location, router, route, file) {
        this.applicationData = applicationData;
        this.localize = localize;
        this.location = location;
        this.router = router;
        this.route = route;
        this.file = file;
        this.modalClose = new EventEmitter();
        this.videoPlayed = true;
    }
    /**
     * On init
     */
    VideoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeSub = this.route.params.subscribe(function (params) {
            _this.videoId = +params['id'];
            if (environment.CONTEXT == ContextTypes.APP) {
                _this.file.resolveDirectoryUrl(environment.BASE + '/api/uploads/').then(function (dir) {
                    _this.file.getFile(dir, _this.applicationData.getAsset(_this.applicationData.getMedia(_this.videoId).libraryId), { create: false }).then(function (fileEntry) {
                        _this.videoFile = _this.applicationData.convertFilePath(fileEntry.nativeURL);
                    });
                });
            }
            else {
                _this.videoFile = environment.BASE + '/api/uploads/' + _this.applicationData.getAsset(_this.applicationData.getMedia(_this.videoId).libraryId);
            }
        });
        this.videoPlayer.nativeElement.ontimeupdate = function () {
            var durationRatio = _this.videoPlayer.nativeElement.currentTime / _this.videoPlayer.nativeElement.duration;
            _this.progressBar.nativeElement.style.width = durationRatio * 100 + '%';
        };
    };
    VideoComponent.prototype.videoControlButton = function () {
        if (this.videoPlayer.nativeElement.paused) {
            this.videoPlayer.nativeElement.play().catch(function (error) {
            }).then(function () {
            });
            ;
            this.videoPlayed = true;
        }
        else {
            this.videoPlayer.nativeElement.pause();
            this.videoPlayed = false;
        }
    };
    VideoComponent.prototype.videoControlBar = function (event) {
        var positionRatio = event.offsetX / this.progressBg.nativeElement.offsetWidth;
        this.videoPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.duration * positionRatio;
    };
    /**
     * Close
     */
    VideoComponent.prototype.closeModal = function ($event) {
        if ($event === void 0) { $event = null; }
        if (history.length <= 1) {
            this.router.navigate([this.localize.translateRoute('/')]);
        }
        else {
            this.location.back();
        }
        this.modalClose.next($event);
    };
    return VideoComponent;
}());
export { VideoComponent };
