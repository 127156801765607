<div class="cart__container">
  <div class="screen__container" *ngIf="!submitted">
    <ng-container *ngIf="media.length > 0">
      <div class="my-selection">
        <p class="_title-1" translate>CART.SELECTION.TITLE
          <span class="_modal-close _hidden-sm">
            <button type="button" class="icon-close" (click)="closeModal()"></button>
          </span></p>
        <ul class="my-selection__container">
          <li class="product" *ngFor="let item of media">
            <div class="product__image-container _va-parent">
              <input class="_hidden-radio-button" type="checkbox" id="delete{{item.id}}" [(ngModel)]="mediaToDelete[item.id]" type="checkbox" name="delete{{item.id}}" value="1" [checked]="mediaToDelete[item.id] == '1'" (click)="itemSelected = true">
              <label  for="delete{{item.id}}">
                <img class="_va-child" [src]="item.image | safeUrl" />
              </label>
              <span class="product__image-container__chip" [class.--selected]="mediaToDelete[item.id] == '1'">
                <i class="product__image-container__chip__color"></i>
              </span>
            </div>
            <p class="product__label" [innerHTML]="item.titleRef | translate"></p>
          </li>
        </ul>
        <div class="delete-container" *ngIf="canDelete && itemSelected">
          <button class="icon-bin" (click)="toggleModal()"></button>
        </div>
      </div>
      <form class="form" #registerForm="ngForm" (ngSubmit)="registerForm.form.valid && type != '-1' && !submiting && register()">
        <p class="_title-1" translate>CART.CONTACT_DETAILS.TITLE
          <span class="_modal-close _hidden-xs">
            <button type="button" class="icon-close" (click)="closeModal()"></button>
          </span>
        </p>
        <div class="form__container">
          <div class="_select" [class.--error]="registerForm.submitted && (typeField.errors || type == '-1')">
            <select name="type" id="type" #typeField="ngModel" [(ngModel)]="type" required>
              <option value="-1">{{'FORM.ROLE' | translate}}*</option>
              <option value="1">{{'FORM.P1' | translate}}</option>
              <option value="2">{{'FORM.P2' | translate}}</option>
              <option value="3">{{'FORM.P3' | translate}}</option>
              <option value="4">{{'FORM.P4' | translate}}</option>
              <option value="5">{{'FORM.P5' | translate}}</option>
              <option value="6">{{'FORM.P6' | translate}}</option>
              <option value="7">{{'FORM.P7' | translate}}</option>
              <option value="8">{{'FORM.CLIENT' | translate}}</option>
              <option value="9">{{'FORM.OTHER' | translate}}</option>
            </select>
            <div class="_select__arrow"></div>
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && typeDetailField.errors" *ngIf="type == 8 || type == 9" style="margin-top: -2vh;margin-bottom: 2vh;">
            <input class="_form-input__input" [(ngModel)]="typeDetail" type="text" name="typeDetail" id="typeDetail" #typeDetailField="ngModel" required />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && nameField.errors">
            <label class="_form-input__label" for="name">{{'FORM.LASTNAME' | translate}}*</label>
            <input class="_form-input__input" [(ngModel)]="name" type="text" name="name" id="name" #nameField="ngModel" required />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && surnameField.errors">
            <label class="_form-input__label" for="surname">{{'FORM.FIRSTNAME' | translate}}*</label>
            <input class="_form-input__input" [(ngModel)]="surname" type="text" name="surname" id="surname" #surnameField="ngModel" required />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && positionField.errors">
            <label class="_form-input__label" for="position">{{'FORM.JOB' | translate}}</label>
            <input class="_form-input__input" [(ngModel)]="position" type="text" name="position" id="position" #positionField="ngModel" />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && companyField.errors">
            <label class="_form-input__label" for="company">{{'FORM.COMPANY' | translate}}*</label>
            <input class="_form-input__input" [(ngModel)]="company" type="text" name="company" id="company" #companyField="ngModel" required />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && emailField.errors">
            <label class="_form-input__label" for="email">{{'FORM.EMAIL' | translate}}*</label>
            <input class="_form-input__input" [(ngModel)]="email" type="email" name="email" id="email" #emailField="ngModel" required pattern="^[a-zA-Z0-9._-]+@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$" />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && zipField.errors">
            <label class="_form-input__label" for="zip">{{'FORM.ZIP' | translate}}</label>
            <input class="_form-input__input" [(ngModel)]="zip" type="text" name="zip" id="zip" #zipField="ngModel" pattern="^[\w -]+$" />
          </div>
          <div class="_form-input" [class.--error]="registerForm.submitted && phoneField.errors">
            <label class="_form-input__label" for="phone">{{'FORM.PHONE' | translate}}</label>
            <input class="_form-input__input" [(ngModel)]="phone" type="text" name="phone" id="phone" #phoneField="ngModel" pattern="[\w -.]+$" />
          </div>

          <div class="form__container__rgpd">
            <p>{{ 'FORM.CGU.PART2' | translate }} <a (click)="gotoPrivacy()">{{ 'FORM.CGU.LINK' | translate }}</a>.</p>
          </div>

          <div class="_form-input checkbox">
            <input type="checkbox" id="optin" [(ngModel)]="optin" type="checkbox" name="optin" value="1">
            <label class="_form-input__label" for="optin">{{ 'FORM.OPTIN' | translate }}</label>
          </div>

          <div class="_button-container">
            <button class="_button-container__button _button-container__button--bluegreen" type="submit">
              <span translate>FORM.SELECTION.SEND</span>
            </button>
          </div>
          <div class="form__container__cgu">
            <p>{{ 'FORM.CGU.PART1' | translate }}</p>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="media.length == 0">
      <button _ngcontent-c7="" class="icon-close" type="button" (click)="closeModal()" style="
          position: fixed;
          top: 20vh;
          right: 10vh;
          z-index: 1000000;
          font-size: 4vh;
      "></button>
      <p class="cart__empty" translate>CART.EMPTY_CART</p>
    </ng-container>
  </div>

  <div class="submit-aftermath" *ngIf="submitted">
    <div class="submit-aftermath__message">
      <p class="_title-2" translate>FORM.SUBMISSION_MSG.TITLE</p>
      <p class="submit-aftermath__message__content" [innerHTML]="'FORM.SUBMISSION_MSG.CONTENT' | translate"></p>
      <p class="submit-aftermath__message__hint" translate>FORM.SUBMISSION_MSG.HINT</p>
      <p class="submit-aftermath__message__team"[innerHTML]="'FORM.SUBMISSION_MSG.TEAM' | translate"></p>
      <div class="_button-container">
        <button class="_button-container__button" type="button" (click)="backHome()" translate>FORM.SUBMISSION_MSG.BTN</button>
      </div>
    </div>
  </div>
</div>

<div class="_modal" *ngIf="confirmDelete">
  <div class="_modal__overlay" (click)="toggleModal()"></div>
  <div class="_modal__content">
    <p class="_modal__content__msg" translate>CART.MODAL.MSG</p>
    <div class="_button-container">
      <button class="_button-container__button _button-container__button--white-three" type="button" (click)="deleteSelected()" translate>GLOBAL.YES</button>
      <button class="_button-container__button _button-container__button--viridian" type="button" (click)="toggleModal()" translate>GLOBAL.NO</button>
    </div>
  </div>
</div>
