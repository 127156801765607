import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationEvent, transition, trigger } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';

declare let TimelineMax:any;
declare let Cubic:any;

export const resetTransition = trigger('resetTransition', [
  transition(':leave', [
  ])
]);

@Component({
  moduleId: module.id,
  selector: 'app-reset',
  templateUrl: 'reset.component.html',
  styleUrls: ['reset.component.scss'],
  animations: [resetTransition],
  host: {
    '[@resetTransition]': '',
    'class': 'screen'
  }
})
export class ResetComponent implements OnInit, OnDestroy {

  public sendingPassword:boolean = false;
	public password:string;
  public confirmPassword:string;
  public token:string;
  public origin:number;

  public message:string;

  private _showConfirmModal:boolean = false;

  private tokenSubscription:any;

  /**
	 * Constructor
	 */
  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private router:Router,
    private route:ActivatedRoute,
    private localize: LocalizeRouterService
  ) {

    this.tokenSubscription = this.route.params.subscribe(params => {
      this.origin = +params['id'];
      this.token = params['id2'];
    });

  }

  /**
	 * On init
	 */
  ngOnInit() {
  }

  /**
   * Send password
   */
	sendPassword(modal:boolean = false):boolean {
		this.sendingPassword = true;

    this.userService.resetPassword(
		  this.password, this.token, this.origin
    ).then((result: any) => {
        if (result.code == 200) {
          this.modalService.open('modal-reset-confirm', { title: 'AUTH.PASSWORD_UPDATED_TITLE', text: 'AUTH.PASSWORD_UPDATED_TEXT'});
        } else {
          this.modalService.open('modal-reset-confirm', { title: 'AUTH.PASSWORD_UPDATED_TITLE', text: 'AUTH.PASSWORD_UPDATED_TEXT_KO'});
        }
  			this.sendingPassword = false;
    }).catch((e: Error) => {
      console.log(e);
      this.sendingPassword = false;
    });

		return false;
 	}

  /**
   * On close
   */
  public onCloseConfirm() {
    return () => {
      if (this.origin == ContextTypes.WEB) {
        this.router.navigate(this.userService.redirectAfterLogin);
      } else {

      }
    }
  }

  /**
	 * On destroy
	 */
  ngOnDestroy() {
    this.tokenSubscription.unsubscribe();
  }
}
