import { Inject, Injectable } from '@angular/core';

import { Router, NavigationStart } from '@angular/router';

import {Observable} from 'rxjs/Observable';

@Injectable()
export class NavigationService {

  private routeSub:any;
  public params: Observable<Array<string>>;
  public currentState:Array<string>;

  /**
	 * Constructor
	 */
  constructor(private router: Router) {
  }

  /**
	 * Startup
	 */
  public startup() {
    this.params = new Observable(observer => {
      this.currentState = this.splitURL(this.router.url);
      this.routeSub = this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.currentState = this.splitURL(event.url);
          observer.next(this.currentState);
        }
      });
    });
  }

  /**
	 * split URL
	 */
  private splitURL(url:string):Array<string> {
    const u:Array<string> = url.replace(/[\(\)]/gi, "").split('/');
    
    const r:Array<string> = [];
    r['id'] = u[3];
    r['id2'] = u[5];
    r['id3'] = u[6];
    return r;
  }

  /**
	 * Cleanup
	 */
  public cleanup() {
    this.routeSub.unsubscribe();
  }
}
