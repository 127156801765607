import { HttpClient } from '@angular/common/http';
import { File } from '@ionic-native/file';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ConfigurationData } from '~/app/shared/data/configuration.data';
import { ApplicationState, ApplicationStates } from './application.state';
import { FakeData } from '~/app/shared/data/fake.data';
export var DocumentType;
(function (DocumentType) {
    DocumentType[DocumentType["PRODUCT"] = 1] = "PRODUCT";
    DocumentType[DocumentType["CATALOG"] = 2] = "CATALOG";
    DocumentType[DocumentType["SPECIFIC"] = 3] = "SPECIFIC";
    DocumentType[DocumentType["VIDEO"] = 4] = "VIDEO";
})(DocumentType || (DocumentType = {}));
var ApplicationData = (function () {
    /**
       * Constructor
       */
    function ApplicationData(applicationState, http, configuration, file, translate) {
        this.applicationState = applicationState;
        this.http = http;
        this.configuration = configuration;
        this.file = file;
        this.translate = translate;
        this.dataState = null;
        this._config = null;
        this._cart = [];
        this.dataState = ApplicationStates.LOADING;
        this.cache = JSON.parse(window.localStorage.getItem('cache')) || { requests: [] };
        if (localStorage.getItem('cart')) {
            this._cart = localStorage.getItem('cart').split(',').map(Number);
        }
        //this.emptyCart();
    }
    /**
     * Loads "datas.json"
     */
    ApplicationData.prototype.load = function () {
        var _this = this;
        if (environment.CONTEXT == ContextTypes.APP) {
            return new Promise(function (resolve, reject) {
                _this.file.readAsText(environment.BASE + '/assets/data/', 'datas.json').then(function (data) {
                    var responseData = JSON.parse(data);
                    if (_this.applicationState.userID == 'fr.info@sylvania-lighting.com') {
                        responseData.data = FakeData.defaults;
                    }
                    _this._config = responseData;
                    _this.version = responseData.data.version;
                    _this.dataState = ApplicationStates.READY;
                    _this.updateAssetsReference();
                    resolve(true);
                }).catch(function (err) {
                    _this.dataState = ApplicationStates.MISSING;
                    reject(false);
                });
            });
        }
        else {
            return new Promise(function (resolve, reject) {
                _this.http.get(environment.BASE + '/api/?uid=' + _this.applicationState.userID).subscribe(function (responseData) {
                    var data = responseData;
                    _this._config = data;
                    _this.version = data.data.version;
                    _this.dataState = ApplicationStates.READY;
                    _this.updateAssetsReference();
                    // this.cache.requests = [];
                    // for (var i = 0; i < 50; i++) {
                    //   this.cache.requests.unshift({
                    //     id: new Date().getTime(),
                    //     lang: this.translate.currentLang,
                    //     time: Date.now(),
                    //     type: "1",
                    //     typeDetail: "",
                    //     name: "LOREM",
                    //     surname: "Ipsum",
                    //     company: "ACME",
                    //     position: "",
                    //     email: "florent@passerelle.com",
                    //     products: [this.getProduct(1), this.getProduct(2)],
                    //     status: 'pending',
                    //   });
                    // }
                    // window.localStorage.setItem('cache', JSON.stringify(this.cache));
                    resolve(true);
                }, function (err) {
                    _this.dataState = ApplicationStates.ERROR;
                    reject(false);
                });
            });
        }
    };
    /**
     * Update assets references
     */
    ApplicationData.prototype.updateAssetsReference = function () {
        var _this = this;
        var _loop_1 = function (i) {
            if (this_1.data.products.filter(function (data) {
                return (+data.rangeId === +_this.data.ranges[i].id);
            }).length == 0) {
                this_1.data.ranges.splice(i, 1);
            }
        };
        var this_1 = this;
        for (var i = this.data.ranges.length - 1; i >= 0; i--) {
            _loop_1(i);
        }
        for (var i = 0; i < this.data.applications.length; i++) {
            this.data.applications[i].image = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.applications[i].image));
        }
        var _loop_2 = function (i) {
            if (this_2.getAsset(this_2.data.media[i].fk_library)) {
                this_2.data.media[i].thumbnail = this_2.convertFilePath(environment.BASE + '/api/uploads/' + this_2.getAsset(this_2.data.media[i].thumbnail));
            }
            else {
                this_2.data.media[i].thumbnail = null;
            }
            if (environment.CONTEXT == ContextTypes.APP) {
                this_2.file.resolveDirectoryUrl(environment.BASE + '/api/uploads/').then(function (dir) {
                    _this.file.getFile(dir, _this.getAsset(_this.data.media[i].fk_library), { create: false }).then(function (fileEntry) {
                        _this.data.media[i].file = fileEntry.nativeURL;
                    });
                });
            }
            else {
                this_2.data.media[i].file = this_2.convertFilePath(environment.BASE + '/api/uploads/' + this_2.getAsset(this_2.data.media[i].fk_library));
            }
        };
        var this_2 = this;
        for (var i = 0; i < this.data.media.length; i++) {
            _loop_2(i);
        }
        for (var i = 0; i < this.data.zones.length; i++) {
            this.data.zones[i].thumb = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].thumb));
            this.data.zones[i].img2D = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img2D));
            this.data.zones[i].img3DBack = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DBack));
            this.data.zones[i].img3DBottom = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DBottom));
            this.data.zones[i].img3DFront = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DFront));
            this.data.zones[i].img3DLeft = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DLeft));
            this.data.zones[i].img3DRight = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DRight));
            this.data.zones[i].img3DTop = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DTop));
        }
        var _loop_3 = function (i) {
            this_3.data.products[i].image = this_3.convertFilePath(environment.BASE + '/api/uploads/' + this_3.getAsset(this_3.data.products[i].image));
            var m = this_3.getMedia(this_3.data.products[i].fk_mediaFicheProduit);
            if (m) {
                if (environment.CONTEXT == ContextTypes.APP) {
                    this_3.file.resolveDirectoryUrl(environment.BASE + '/api/uploads/').then(function (dir) {
                        _this.file.getFile(dir, _this.getAsset(m.libraryId), { create: false }).then(function (fileEntry) {
                            _this.data.products[i].pdfSheet = fileEntry.nativeURL;
                        });
                    });
                }
                else {
                    this_3.data.products[i].pdfSheet = this_3.convertFilePath(environment.BASE + '/api/uploads/' + this_3.getAsset(m.libraryId));
                }
            }
            var videoAsset = this_3.getAsset(this_3.data.products[i].video);
            this_3.data.products[i].video = videoAsset && videoAsset.length > 0 ? this_3.data.products[i].video : null;
        };
        var this_3 = this;
        for (var i = 0; i < this.data.products.length; i++) {
            _loop_3(i);
        }
        for (var i = 0; i < this.data.galleryphotos.length; i++) {
            this.data.galleryphotos[i].image = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.galleryphotos[i].image));
        }
    };
    /**
     * Convert file path
     */
    ApplicationData.prototype.convertFilePath = function (path) {
        if (environment.CONTEXT == ContextTypes.APP && window.WkWebView) {
            return window.WkWebView.convertFilePath(path);
        }
        else {
            return path;
        }
    };
    /**
     * Get asset
     */
    ApplicationData.prototype.getAsset = function (assetId) {
        var d = this.data.library.filter(function (data) {
            return (+data.id === +assetId);
        });
        if (d.length > 0) {
            return d[0].src;
        }
        else {
            return null;
        }
    };
    Object.defineProperty(ApplicationData.prototype, "data", {
        /**
         * data
         */
        get: function () {
            return this._config ? this._config.data : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationData.prototype, "applications", {
        /**
         * applications
         */
        get: function () {
            return this.data ? this.data.applications.sort(function (a, b) { return a.order - b.order; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationData.prototype, "ranges", {
        /**
         * ranges
         */
        get: function () {
            return this.data ? this.data.ranges : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationData.prototype, "zones", {
        /**
         * All zones
         */
        get: function () {
            return this.data ? this.data.zones : null;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Application by applicationId
     */
    ApplicationData.prototype.getApplication = function (applicationId) {
        return this.data.applications.filter(function (data) {
            return (+data.id === +applicationId);
        })[0];
    };
    /**
     * Zones by applicationId
     */
    ApplicationData.prototype.getZones = function (applicationId) {
        return this.data.zones.filter(function (data) {
            return (+data.applicationId === +applicationId);
        }).sort(function (a, b) { return a.sort - b.sort; });
    };
    /**
     * Zone
     */
    ApplicationData.prototype.getZone = function (zoneId) {
        return this.data.zones.filter(function (data) {
            return (+data.id === +zoneId);
        })[0];
    };
    /**
     * Hotpoints
     */
    ApplicationData.prototype.getHotspots = function (zoneId) {
        return this.data.hotspots.filter(function (data) {
            return (+data.zoneId === +zoneId);
        });
    };
    /**
     * Hotspot
     */
    ApplicationData.prototype.getHotspot = function (hotspotId) {
        return this.data.hotspots.filter(function (data) {
            return (+data.id === +hotspotId);
        })[0];
    };
    /**
     * Product
     */
    ApplicationData.prototype.getProductFromHotspot = function (hotspotId) {
        var productId = this.data.hotspots.filter(function (data) {
            return (+data.id === +hotspotId);
        })[0].productId;
        return this.getProduct(productId);
    };
    /**
     * Product
     */
    ApplicationData.prototype.getProduct = function (productId) {
        return this.data.products.filter(function (data) {
            return (+data.id === +productId);
        })[0];
    };
    /**
     * Media
     */
    ApplicationData.prototype.getMediaByType = function (type) {
        return this.data.media.filter(function (data) {
            return (+data.fk_typeDocument == +type);
        }).map(function (data) {
            return {
                id: data.pid_media,
                image: data.thumbnail,
                zoneId: data.fk_zone,
                zoneIds: data.fk_zones.split('|'),
                titleRef: data.name,
                file: data.file,
                isCatalogueInteractif: data.catalogueInteractif == '1',
                isInMenu: data.inMenu == '1',
                catalogueTarif: data.catalogueTarif,
                typeBrochure: data.typeBrochure,
                ranges: data.fk_ranges.split(','),
                applications: data.fk_applications.split(','),
                isNew: data.new == '1',
                brandNew: data.brandNew == '1',
                visibility: data.visibility,
            };
        });
    };
    /**
     * Media
     */
    ApplicationData.prototype.getMedia = function (mediaId) {
        var media = (this.data.media.filter(function (data) {
            return (+data.pid_media == +mediaId);
        }).map(function (data) {
            return {
                id: data.pid_media,
                libraryId: data.fk_library,
                zoneId: data.fk_zone,
                zoneIds: data.fk_zones.split('|'),
                image: data.thumbnail,
                titleRef: data.name,
                file: data.file,
                isCatalogueInteractif: data.catalogueInteractif == '1',
                isInMenu: data.inMenu == '1',
                catalogueTarif: data.catalogueTarif,
                typeBrochure: data.typeBrochure,
                ranges: data.fk_ranges.split(','),
                applications: data.fk_applications.split(','),
                isNew: data.new == '1',
                brandNew: data.brandNew == '1',
            };
        })[0]);
        return media;
    };
    /**
     * Application has gallery?
     */
    ApplicationData.prototype.applicationHasGallery = function (applicationId) {
        return this.data.galleries.filter(function (data) {
            return (+data.applicationId === +applicationId);
        }).length > 0;
    };
    /**
     * Get gallery
     */
    ApplicationData.prototype.getGallery = function (applicationId) {
        return this.data.galleries.filter(function (data) {
            return (+data.applicationId === +applicationId);
        })[0];
    };
    /**
     * Get gallery photos
     */
    ApplicationData.prototype.getGalleryPhotos = function (galleryId) {
        return this.data.galleryphotos.filter(function (data) {
            return (+data.galleryId === +galleryId);
        });
    };
    Object.defineProperty(ApplicationData.prototype, "products", {
        /**
         * All products
         */
        get: function () {
            return this.data.products;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * All products
     */
    ApplicationData.prototype.getCrossSelling = function (hotspotId) {
        return this.data.crosseling.filter(function (data) {
            return (+data.fk_hotspots === +hotspotId);
        });
    };
    /**
     * Add a media to cart
     */
    ApplicationData.prototype.addToCart = function (mediaId) {
        if (!this.cartHasProduct(+mediaId)) {
            this._cart.push(+mediaId);
        }
        localStorage.setItem('cart', this._cart.join(','));
    };
    /**
     * Remove a media from cart
     */
    ApplicationData.prototype.removeFromCart = function (mediaId) {
        var idx = this._cart.indexOf(+mediaId);
        if (idx != -1) {
            var item = this._cart.splice(idx, 1);
            localStorage.setItem('cart', this._cart.join(','));
            return item;
        }
        return false;
    };
    /**
     * Is media in cart
     */
    ApplicationData.prototype.cartHasProduct = function (mediaId) {
        return this._cart.indexOf(+mediaId) !== -1;
    };
    /**
     * Empty cart
     */
    ApplicationData.prototype.emptyCart = function () {
        this._cart = [];
        localStorage.setItem('cart', this._cart.join(','));
    };
    Object.defineProperty(ApplicationData.prototype, "cartSize", {
        /**
         * Get cart size
         */
        get: function () {
            return this._cart.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationData.prototype, "cart", {
        /**
         * Get cart
         */
        get: function () {
            var media = [];
            for (var i = 0; i < this.cartSize; i++) {
                media.push(this.getMedia(this._cart[i]));
            }
            return media;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationData.prototype, "requests", {
        /**
           * Save request
           */
        get: function () {
            return this.cache.requests;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Save request
     */
    ApplicationData.prototype.saveRequest = function (type, typeDetail, name, surname, position, company, email, zip, phone, products, optin, userID, insert, id) {
        var _this = this;
        if (insert === void 0) { insert = true; }
        if (id === void 0) { id = null; }
        var source = environment.CONTEXT == ContextTypes.APP ? device.platform : 'web';
        var data = {
            lang: this.translate.currentLang,
            userID: userID,
            type: type,
            typeDetail: typeDetail,
            name: name,
            surname: surname,
            position: position,
            company: company,
            email: email,
            zip: zip,
            phone: phone,
            optin: optin,
            source: source,
            products: products.map(function (elem) {
                return elem.id;
            })
        };
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.REMOTE_SERVER + '/api/model/save-datas-mailing.php', data).subscribe(function (responseData) {
                if (environment.CONTEXT == ContextTypes.APP) {
                    if (insert) {
                        _this.cache.requests.unshift({
                            id: new Date().getTime(),
                            lang: _this.translate.currentLang,
                            userID: userID,
                            time: Date.now(),
                            type: type,
                            typeDetail: typeDetail,
                            name: name,
                            surname: surname,
                            company: company,
                            position: position,
                            email: email,
                            zip: zip,
                            phone: phone,
                            optin: optin,
                            source: source,
                            products: products,
                            status: 'completed',
                        });
                        window.localStorage.setItem('cache', JSON.stringify(_this.cache));
                    }
                    else {
                        for (var _i = 0, _a = _this.cache.requests; _i < _a.length; _i++) {
                            var request = _a[_i];
                            if (request.id == id) {
                                request.status = 'completed';
                            }
                        }
                        window.localStorage.setItem('cache', JSON.stringify(_this.cache));
                    }
                }
                resolve(true);
            }, function (err) {
                if (environment.CONTEXT == ContextTypes.APP && insert) {
                    _this.cache.requests.unshift({
                        id: new Date().getTime(),
                        lang: _this.translate.currentLang,
                        userID: userID,
                        time: Date.now(),
                        type: type,
                        typeDetail: typeDetail,
                        name: name,
                        surname: surname,
                        company: company,
                        position: position,
                        email: email,
                        zip: zip,
                        phone: phone,
                        optin: optin,
                        source: source,
                        products: products,
                        status: 'pending',
                    });
                    window.localStorage.setItem('cache', JSON.stringify(_this.cache));
                    resolve(false);
                }
                else {
                    reject(false);
                }
            });
        });
    };
    /**
     * SynchronizeRequests
     */
    ApplicationData.prototype.synchronizeRequests = function () {
        var _this = this;
        var data = this.cache.requests.filter(function (d) {
            return (d.status == 'pending');
        });
        //data = Object.assign([], data);
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var d = data_1[_i];
            d.products = d.products.map(function (elem) {
                return elem.id;
            });
        }
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.REMOTE_SERVER + '/api/model/save-datas-mailing.php', data).subscribe(function (responseData) {
                for (var _i = 0, _a = _this.cache.requests; _i < _a.length; _i++) {
                    var request = _a[_i];
                    request.status = 'completed';
                }
                window.localStorage.setItem('cache', JSON.stringify(_this.cache));
                resolve(true);
            }, function (err) {
                reject(false);
            });
        });
    };
    return ApplicationData;
}());
export { ApplicationData };
