import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { SplashScreen } from '@ionic-native/splash-screen';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { SwiperModule } from 'ngx-swiper-wrapper';

import { CookieLawModule } from 'angular2-cookie-law';

import { SharedModule } from '~/app/shared/shared.module';
import { StartupService } from '~/app/shared/services/startup.service';
import { HeaderComponent } from '~/app/shared/header/header.component';
import { MenuComponent } from '~/app/shared/menu/menu.component';
import { FooterComponent } from '~/app/shared/footer/footer.component';
import { AppComponent } from './app.component';
import { VideoComponent } from './video/video.component';
import { CartComponent } from './cart/cart.component';
import { MedialibraryComponent } from './medialibrary/medialibrary.component';
import { MediaListComponent } from './medialist/medialist.component';
import { AppRoutingModule } from './app-routing.module';
import { PreHomeModule } from './prehome/prehome.module';
import { AuthModule } from './auth/auth.module';
import { SetupModule } from './setup/setup.module';
import { CustomTranslateLoader } from './shared/services/translate.loader';
import { CatalogComponent } from './catalog/catalog.component';
import { AboutComponent } from './about/about.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LegalsComponent } from './legals/legals.component';
import { ResetComponent } from './reset/reset.component';

// AoT requires an exported function for factories
export function loadConfig(start:StartupService) {
  return () => start.startup().then().catch((e:Error) => {});
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    VideoComponent,
    CartComponent,
    MedialibraryComponent,
    MediaListComponent,
    CatalogComponent,
    AboutComponent,
    PrivacyComponent,
    LegalsComponent,
    ResetComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [ HttpClient ]
      }
    }),
    SwiperModule,
    CookieLawModule,
    // feature modules
    SharedModule.forRoot(),
    PreHomeModule,
    AuthModule,
    SetupModule,
    AppRoutingModule
  ],
  providers: [
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [StartupService],
      multi: true
    },
    SplashScreen,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
