import { Component, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, animate, style, transition } from '@angular/animations';

import { File } from '@ionic-native/file';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ApplicationData } from '~/app/shared/data/application.data';


export const videoTransition = trigger('videoTransition', [
  transition(':enter', [
    style({opacity: 0}),
    animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)')
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)', style({opacity: 0})),
  ])
]);

/**
 * This class represents the video component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-video',
  templateUrl: 'video.component.html',
  styleUrls: ['video.component.scss'],
  animations: [ videoTransition ],
  host: {
    '[@videoTransition]': '',
    'class': 'fullscreen'
  },
})

export class VideoComponent {

  @Output() modalClose : EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('videoPlayer') videoPlayer: any;
  @ViewChild('videoControls') videoControls: any;
  @ViewChild('progressBg') progressBg: any;
  @ViewChild('progressBar') progressBar: any;

  public videoId:number;
  public videoFile:string;
  public videoPlayed: boolean;
  private routeSub:any;

  /**
   * Constructor
   */
  constructor(private applicationData:ApplicationData, private localize: LocalizeRouterService, public location: Location, private router: Router, private route: ActivatedRoute, private file: File) {
    this.videoPlayed = true;
  }

  /**
   * On init
   */
  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.videoId = +params['id'];
      if (environment.CONTEXT == ContextTypes.APP) {
        this.file.resolveDirectoryUrl(environment.BASE + '/api/uploads/').then((dir:any) => {
          this.file.getFile(dir, this.applicationData.getAsset(this.applicationData.getMedia(this.videoId).libraryId), { create: false }).then((fileEntry:any) => {
            this.videoFile = this.applicationData.convertFilePath(fileEntry.nativeURL);
          });
        });
      } else {
        this.videoFile = environment.BASE + '/api/uploads/' + this.applicationData.getAsset(this.applicationData.getMedia(this.videoId).libraryId);
      }
    });

    this.videoPlayer.nativeElement.ontimeupdate = () => {
      let durationRatio = this.videoPlayer.nativeElement.currentTime / this.videoPlayer.nativeElement.duration;
      this.progressBar.nativeElement.style.width = durationRatio * 100  + '%';
    };
  }

  videoControlButton() {
    if(this.videoPlayer.nativeElement.paused) {
      this.videoPlayer.nativeElement.play().catch(error => {
      }).then(() => {
      });;
      this.videoPlayed = true
    } else {
      this.videoPlayer.nativeElement.pause();
      this.videoPlayed = false
    }
  }

  videoControlBar(event: any) {
    let positionRatio = event.offsetX / this.progressBg.nativeElement.offsetWidth;
    this.videoPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.duration * positionRatio;
  }

  /**
   * Close
   */
  closeModal($event = null) {
    if (history.length <= 1) {
      this.router.navigate([this.localize.translateRoute('/')]);
    } else {
      this.location.back();
    }
    this.modalClose.next($event);
  }

}
