<div class="privacy__container">
  <div class="screen__container">
    <div class="screen__container__header">
      <p class="_title-2" [innerHTML]="'LEGALS.TITLE' | translate"></p>
    </div>

    <div class="privacy__content text__content" [innerHTML]="'LEGALS.HTML' | translate">

    </div>
  </div>
</div>
