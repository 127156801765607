import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { UserService } from '~/app/shared/services/user.service';
import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationData } from '../data/application.data';
/**
 * This class represents the header component.
 */
var HeaderComponent = (function () {
    /**
       * Constructor
       */
    function HeaderComponent(userService, modalService, applicationData, router, localize) {
        var _this = this;
        this.userService = userService;
        this.modalService = modalService;
        this.applicationData = applicationData;
        this.router = router;
        this.localize = localize;
        this.count = 0;
        this.menuIsOpen = false;
        this.router.events.subscribe(function (e) {
            setTimeout(function () {
                _this.menuIsOpen = false;
                _this.modalService.cancel('modal-logout');
                _this.authMenuIsOpen = false;
            }, 100);
        });
    }
    Object.defineProperty(HeaderComponent.prototype, "fullFunctionnalities", {
        /**
           * Full functionnalities ?
           */
        get: function () {
            if (environment.CONTEXT == ContextTypes.WEB) {
                return (this.router.url + '/' != this.localize.translateRoute('/')
                    && this.router.url != this.localize.translateRoute('/auth')
                    && this.router.url != this.localize.translateRoute('/register')
                    && this.router.url.indexOf(this.localize.translateRoute('/reset')) < 0);
            }
            else {
                return (this.userService.loggedIn
                    && this.router.url + '/' != this.localize.translateRoute('/')
                    && this.router.url != this.localize.translateRoute('/auth')
                    && this.router.url != this.localize.translateRoute('/register')
                    && this.router.url.indexOf(this.localize.translateRoute('/reset')) < 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
       * Open account menu
       */
    HeaderComponent.prototype.toggleAuthMenu = function () {
        this.authMenuIsOpen = !this.authMenuIsOpen;
    };
    /**
      * Request logout
      */
    HeaderComponent.prototype.requestLogout = function () {
        this.modalService.open('modal-logout');
        this.authMenuIsOpen = false;
    };
    /**
       * Switch lang
       */
    HeaderComponent.prototype.switchLanguage = function (lang) {
        this.localize.changeLanguage(lang);
    };
    /**
     * Init setup
     */
    HeaderComponent.prototype.initSetup = function () {
        var _this = this;
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.count++ >= 5 && environment.CONTEXT == ContextTypes.APP) {
            this.router.navigate([this.localize.translateRoute('/setup')]);
        }
        this.timer = setTimeout(function () {
            _this.count = 0;
        }, 1000);
    };
    HeaderComponent.prototype.toggleMenu = function () {
        this.menuIsOpen = !this.menuIsOpen;
    };
    HeaderComponent.prototype.updateMenuState = function (event) {
        this.menuIsOpen = event.state;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
