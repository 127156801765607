import { Inject, Injectable } from '@angular/core';

import { FileOpener } from '@ionic-native/file-opener';
import { DocumentViewer } from '@ionic-native/document-viewer';
import { ScreenOrientation } from '@ionic-native/screen-orientation';

import { ApplicationState } from '~/app/shared/data/application.state';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

declare let cordova:any;
declare let device:any;

@Injectable()
export class ToolsService {

  public inAppRef:any;

  /**
	 * Constructor
	 */
  constructor(
    private applicationState: ApplicationState,
    private fileOpener: FileOpener,
	  private documentViewer: DocumentViewer,
    private screenOrientation: ScreenOrientation
  ) {

  }

  /**
   * Open file
   */
  public openFile(e:Event) {
    if (environment.CONTEXT == ContextTypes.APP) {
      e.preventDefault();
      if (device.platform == "Android") {
        this.fileOpener.open((<HTMLLinkElement>e.currentTarget).href, 'application/pdf').then((response: any) => {})
      } else if (device.platform == "windows") {
        this.applicationState.iFramedPDF = (<HTMLLinkElement>e.currentTarget).href;
        e.preventDefault();
      } else {
        // this.inAppRef = cordova.InAppBrowser.open((<HTMLLinkElement>e.currentTarget).href, '_blank', 'location=no,enableViewportScale=yes');
        // this.screenOrientation.unlock();
        //
        // this.inAppRef.addEventListener('exit', this.unlockOrientation.bind(this));
        //
        // this.screenOrientation.lock('portrait');
        // //ref.addEventListener('loaderror', this.unlockOrientation.bind(this));
        this.applicationState.iFramedPDF = (<HTMLLinkElement>e.currentTarget).href;
        e.preventDefault();
      }
    }
  }

  public unlockOrientation(event) {
    this.screenOrientation.lock('landscape-primary');
    if (this.inAppRef) {
      event.target.removeEventListener('exit', this.unlockOrientation);
    }
  }
}
