import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationEvent, transition, trigger } from '@angular/animations';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';

export const registerTransition = trigger('registerTransition', [
  transition(':leave', [
  ])
]);

@Component({
  moduleId: module.id,
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss'],
  animations: [registerTransition],
  host: {
    '[@registerTransition]': '',
    'class': 'screen'
  }
})
export class RegisterComponent implements OnInit, OnDestroy {

  public privacyLink:string;

  public type:number = -1;
	public company:string;
	public surname:string;
	public name:string;
	public phone:string;
	public email:string;
	public password:string;
  public confirmPassword:string;
	public optin:string;

	public submiting:boolean;

  public error_text:string = '';

  public environment = environment;
  public ContextTypes = ContextTypes;


  /**
	 * Constructor
	 */
  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private router:Router,
    private localize: LocalizeRouterService
  ) {

     this.type = this.userService.tmp_type;
     this.company = this.userService.tmp_company;
     this.surname = this.userService.tmp_surname;
     this.name = this.userService.tmp_name;
     this.phone = this.userService.tmp_phone;
     this.email = this.userService.tmp_email;
     this.password = this.userService.tmp_password;
     this.confirmPassword = this.userService.tmp_confirmPassword;
     this.optin = this.userService.tmp_optin;

    this.privacyLink = this.localize.translateRoute('/privacy') as string;
  }

  /**
	 * On init
	 */
  ngOnInit() {
  }

  /**
   * Register
   */
	register():boolean {
		this.submiting = true;

    this.userService.register(String(this.type), this.company, this.surname, this.name,
														this.phone, this.email, this.password, this.optin).then((result: any) => {
      if (result.code === 200) {
        if (environment.CONTEXT == ContextTypes.WEB) {
          this.modalService.open('modal-register-confirmation', { title: 'REGISTER.CONFIRM.TITLE', text: 'REGISTER.CONFIRM.TEXT_WEB' });
        } else {
          this.modalService.open('modal-register-confirmation', { title: 'REGISTER.CONFIRM.TITLE', text: 'REGISTER.CONFIRM.TEXT_APP' });
        }

			} else {
        this.modalService.open('modal-register-message', { title: 'GLOBAL.ERROR', text: result.data });
				this.submiting = false;
			}
    }).catch((e: Error) => {
      this.submiting = false;
      this.modalService.open('modal-register-message', { title: 'GLOBAL.ERROR', text: 'GLOBAL.ERROR_UNKNOWN' });
    });

		return false;
	}

  /**
   * On close
   */
  public onCloseConfirm() {
    return () => {
      this.type = -1;
      this.company = null;
      this.surname = null;
      this.name = null;
      this.phone = null;
      this.email = null;
      this.password = null;
      this.confirmPassword = null;
      this.optin = null;

      this.router.navigate(this.userService.redirectAfterLogin);
    }
  }

  /**
	 * On destroy
	 */
  ngOnDestroy() {
    this.userService.tmp_type = this.type;
    this.userService.tmp_company = this.company;
    this.userService.tmp_surname = this.surname;
    this.userService.tmp_name = this.name;
    this.userService.tmp_phone = this.phone;
    this.userService.tmp_email = this.email;
    this.userService.tmp_password = this.password;
    this.userService.tmp_confirmPassword = this.confirmPassword;
    this.userService.tmp_optin = this.optin;

    this.userService.resetAfterLogin();
  }
}
