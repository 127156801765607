import { ContextTypes } from './environment.types';

export const environment = {
  PRODUCTION: true,
  CONTEXT: ContextTypes.WEB,
  REMOTE_SERVER: 'https://www.solutions-sylvania.com',
  BASE: 'https://www.solutions-sylvania.com',
  GOOGLE_ANALYTICS_ID: 'G-S4QD3YYGHE',
  IDLE_TIME: 10 * 60, //seconds
  API_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
  VERSION: '1.2.21'
};
