import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { UserService } from '~/app/shared/services/user.service';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';

declare let cordova:any;

/**
 * This class represents the footer component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})

export class FooterComponent {

  public isApp:boolean;
  public isiOS:boolean;
  public isAndroid:boolean;

  environment = environment;
  
  /**
	 * Constructor
	 */
  constructor(
    public userService: UserService,
    public applicationData:ApplicationData,
    public applicationState:ApplicationState,
    public location: Location,
    public router:Router,
    private localize:LocalizeRouterService
  ) {

    this.isApp = environment.CONTEXT == ContextTypes.APP;
    this.isiOS = (/iPhone|iPad|iPod/i.test(navigator.userAgent));
    this.isAndroid = (/Android/i.test(navigator.userAgent));
  }

  /**
	 * Full functionnalities ?
	 */
  get fullFunctionnalities():boolean {
    if (environment.CONTEXT == ContextTypes.WEB) {
      return (this.router.url + '/' != this.localize.translateRoute('/')
          && this.router.url != this.localize.translateRoute('/auth')
          && this.router.url != this.localize.translateRoute('/register')
          && this.router.url.indexOf(this.localize.translateRoute('/reset') as string) < 0);
    } else {
      return (
          this.userService.loggedIn
          && this.router.url + '/' != this.localize.translateRoute('/')
          && this.router.url != this.localize.translateRoute('/auth')
          && this.router.url != this.localize.translateRoute('/register')
          && this.router.url.indexOf(this.localize.translateRoute('/reset') as string) < 0);
    }
  }

  /**
   * Toggle fullscreen
   */
  toggleFullscreen(e:Event) {
    e.preventDefault();
    document.body.classList.toggle('fullscreen');
  }

  /**
	 * Back
	 */
  back() {
    if (history.length <= 1) {
      this.router.navigate([this.localize.translateRoute('/')]);
    } else {
      this.location.back();
    }
  }

  /**
	 * Switch lang
	 */
  switchLanguage(lang:string) {
    this.localize.changeLanguage(lang);
  }

  openLink(e:Event) {
    if (environment.CONTEXT == ContextTypes.APP) {
      e.preventDefault();
      cordova.InAppBrowser.open((<HTMLLinkElement>e.currentTarget).href, '_blank', 'location=no');
    }
  }

}
