/**
 * This class represents the medialibrary component.
 */
var LegalsComponent = (function () {
    /**
       * Constructor
       */
    function LegalsComponent() {
    }
    /**
       * On init
       */
    LegalsComponent.prototype.ngOnInit = function () {
    };
    return LegalsComponent;
}());
export { LegalsComponent };
