<div class="about__container">
  <span class="_media-fullscreen">
    <a (click)="closeModal()" class="icon-close"></a>
  </span>
  <div class="_video-wrapper">
    <div class="_player-container">
      <video class="_player" #videoPlayer loop>
        <source src="assets/videos/about.mp4" type="video/mp4">
      </video>
      <div class="start-button" [hidden]="initialized">
        <button type="button" (click)="videoControlButton()" class="_controls__button _controls__button--big">
          <i class="icon-play"></i>
        </button>
      </div>
      <div class="_controls" #videoControls [hidden]="!initialized">
        <button type="button" (click)="videoControlButton()" class="_controls__button">
          <i [class.icon-pause]="videoPlayed" [class.icon-play]="!videoPlayed"></i>
        </button>
        <div class="_controls__progress-container">
          <div class="_controls__progress-container__background" (click)="videoControlBar($event)" #progressBg>
            <span class="_controls__progress-container__background__bar" #progressBar></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
