import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalizeRouterModule } from 'localize-router';

import { SetupComponent } from './setup/setup.component';
import { AuthGuard, DataGuard } from '~/app/app-routing.guards';

const setupRoutes: Routes = [
  { path: 'setup',  component: SetupComponent, data: { state: 'setup' }, canActivate: [ AuthGuard ] }
];

@NgModule({
  imports: [
    RouterModule.forChild(setupRoutes),
    LocalizeRouterModule.forChild(<any> setupRoutes)
  ],
  exports: [ RouterModule, LocalizeRouterModule ]
})
export class SetupRoutingModule { }
