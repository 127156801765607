import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

import { File } from '@ionic-native/file';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ConfigurationData } from '~/app/shared/data/configuration.data';
import { ApplicationState, ApplicationStates } from './application.state';

import { FakeData } from '~/app/shared/data/fake.data';

declare let device:any;

export enum DocumentType {
  PRODUCT = 1,
  CATALOG = 2,
  SPECIFIC = 3,
  VIDEO = 4
}

@Injectable()
export class ApplicationData {

  public cache: any;

  public version: string;
  public dataState: any = null;

  private _config: any = null;
  private _cart: Array<number> = [];

  /**
	 * Constructor
	 */
  constructor(
    private applicationState: ApplicationState,
    private http: HttpClient,
    private configuration: ConfigurationData,
    private file: File,
    private translate: TranslateService
  ) {
    this.dataState = ApplicationStates.LOADING;

    this.cache = JSON.parse(window.localStorage.getItem('cache')) || { requests: [] };

    if (localStorage.getItem('cart')) {
      this._cart = localStorage.getItem('cart').split(',').map(Number);
    }

    //this.emptyCart();
  }

  /**
   * Loads "datas.json"
   */
  public load(): Promise<boolean> {
    if (environment.CONTEXT == ContextTypes.APP) {
      return new Promise((resolve, reject) => {
        this.file.readAsText(environment.BASE + '/assets/data/', 'datas.json').then((data) => {
          const responseData: any = JSON.parse(data);
          if (this.applicationState.userID == 'fr.info@sylvania-lighting.com') {
            responseData.data = FakeData.defaults;
          }

          this._config = responseData;
          this.version = responseData.data.version;
          this.dataState = ApplicationStates.READY;
          this.updateAssetsReference();
          resolve(true);
        }).catch((err) => {
          this.dataState = ApplicationStates.MISSING;
          reject(false);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(environment.BASE + '/api/?uid='+this.applicationState.userID).subscribe((responseData) => {
          const data: any = responseData;
          this._config = data;
          this.version = data.data.version;
          this.dataState = ApplicationStates.READY;
          this.updateAssetsReference();

          // this.cache.requests = [];
          // for (var i = 0; i < 50; i++) {
          //   this.cache.requests.unshift({
          //     id: new Date().getTime(),
          //     lang: this.translate.currentLang,
          //     time: Date.now(),
          //     type: "1",
          //     typeDetail: "",
          //     name: "LOREM",
          //     surname: "Ipsum",
          //     company: "ACME",
          //     position: "",
          //     email: "florent@passerelle.com",
          //     products: [this.getProduct(1), this.getProduct(2)],
          //     status: 'pending',
          //   });
          // }
          // window.localStorage.setItem('cache', JSON.stringify(this.cache));

          resolve(true);
        }, (err) => {
          this.dataState = ApplicationStates.ERROR;
          reject(false);
        });
      });
    }
  }

  /**
   * Update assets references
   */
  updateAssetsReference(): void {
    for (let i = this.data.ranges.length - 1; i >= 0 ; i--) {
      if (this.data.products.filter(
        (data: any) => {
          return (+data.rangeId === +this.data.ranges[i].id);
        }
      ).length == 0) {
        this.data.ranges.splice(i, 1);
      }
    }

    for (let i = 0; i < this.data.applications.length; i++) {
      this.data.applications[i].image = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.applications[i].image));
    }

    for (let i = 0; i < this.data.media.length; i++) {
      if (this.getAsset(this.data.media[i].fk_library)) {
        this.data.media[i].thumbnail = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.media[i].thumbnail));
      } else {
        this.data.media[i].thumbnail = null;
      }
      if (environment.CONTEXT == ContextTypes.APP) {
        this.file.resolveDirectoryUrl(environment.BASE + '/api/uploads/').then((dir:any) => {
          this.file.getFile(dir, this.getAsset(this.data.media[i].fk_library), { create: false }).then((fileEntry:any) => {
            this.data.media[i].file = fileEntry.nativeURL;
          });
        });
      } else {
        this.data.media[i].file = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.media[i].fk_library));
      }

    }

    for (let i = 0; i < this.data.zones.length; i++) {
      this.data.zones[i].thumb = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].thumb));
      this.data.zones[i].img2D = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img2D));
      this.data.zones[i].img3DBack = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DBack));
      this.data.zones[i].img3DBottom = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DBottom));
      this.data.zones[i].img3DFront = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DFront));
      this.data.zones[i].img3DLeft = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DLeft));
      this.data.zones[i].img3DRight = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DRight));
      this.data.zones[i].img3DTop = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.zones[i].img3DTop));
    }

    for (let i = 0; i < this.data.products.length; i++) {
      this.data.products[i].image = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.products[i].image));

      let m = this.getMedia(this.data.products[i].fk_mediaFicheProduit);
      if (m) {
        if (environment.CONTEXT == ContextTypes.APP) {
          this.file.resolveDirectoryUrl(environment.BASE + '/api/uploads/').then((dir:any) => {
            this.file.getFile(dir, this.getAsset(m.libraryId), { create: false }).then((fileEntry:any) => {
              this.data.products[i].pdfSheet = fileEntry.nativeURL;
            });
          });
        } else {
          this.data.products[i].pdfSheet = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(m.libraryId));
        }
      }

      let videoAsset = this.getAsset(this.data.products[i].video);
      this.data.products[i].video = videoAsset && videoAsset.length > 0 ? this.data.products[i].video : null;
    }

    for (let i = 0; i < this.data.galleryphotos.length; i++) {
      this.data.galleryphotos[i].image = this.convertFilePath(environment.BASE + '/api/uploads/' + this.getAsset(this.data.galleryphotos[i].image));
    }
  }

  /**
   * Convert file path
   */
  convertFilePath(path: string): string {
    if (environment.CONTEXT == ContextTypes.APP && (window as any).WkWebView) {
      return (window as any).WkWebView.convertFilePath(path);
    } else {
      return path;
    }
  }

  /**
   * Get asset
   */
  getAsset(assetId: number): string {
    const d: Array<any> = this.data.library.filter(
      (data: any) => {
        return (+data.id === +assetId);
      }
    );

    if (d.length > 0) {
      return d[0].src;
    } else {
      return null;
    }
  }

  /**
   * data
   */
  get data(): any {
    return this._config ? this._config.data : null;
  }

  /**
   * applications
   */
  get applications(): Array<any> {
    return this.data ? this.data.applications.sort(function (a: any, b: any) { return a.order - b.order; }) : null;
  }

  /**
   * ranges
   */
  get ranges(): Array<any> {
    return this.data ? this.data.ranges : null;
  }

  /**
   * All zones
   */
  get zones(): Array<any> {
    return this.data ? this.data.zones : null;
  }

  /**
   * Application by applicationId
   */
  public getApplication(applicationId: number): any {
    return this.data.applications.filter(
      (data: any) => {
        return (+data.id === +applicationId);
      }
    )[0];
  }

  /**
   * Zones by applicationId
   */
  public getZones(applicationId: number): Array<any> {
    return this.data.zones.filter(
      (data: any) => {
        return (+data.applicationId === +applicationId);
      }
    ).sort(function (a: any, b: any) { return a.sort - b.sort; });
  }

  /**
   * Zone
   */
  public getZone(zoneId: number): any {
    return this.data.zones.filter(
      (data: any) => {
        return (+data.id === +zoneId);
      }
    )[0];
  }

  /**
   * Hotpoints
   */
  public getHotspots(zoneId: number): any {
    return this.data.hotspots.filter(
      (data: any) => {
        return (+data.zoneId === +zoneId);
      }
    );
  }

  /**
   * Hotspot
   */
  public getHotspot(hotspotId: number): any {
    return this.data.hotspots.filter(
      (data: any) => {
        return (+data.id === +hotspotId);
      }
    )[0];
  }

  /**
   * Product
   */
  public getProductFromHotspot(hotspotId: number): any {
    let productId = this.data.hotspots.filter(
      (data: any) => {
        return (+data.id === +hotspotId);
      }
    )[0].productId;

    return this.getProduct(productId);
  }

  /**
   * Product
   */
  public getProduct(productId: number): any {
    return this.data.products.filter(
      (data: any) => {
        return (+data.id === +productId);
      }
    )[0];
  }

  /**
   * Media
   */
  public getMediaByType(type: number): any {
    return this.data.media.filter(
      (data: any) => {
        return (+data.fk_typeDocument == +type);
      }
    ).map((data:any) => {
      return {
        id: data.pid_media,
        image: data.thumbnail,
        zoneId: data.fk_zone,
        zoneIds: data.fk_zones.split('|'),
        titleRef: data.name,
        file: data.file,
        isCatalogueInteractif: data.catalogueInteractif == '1',
        isInMenu: data.inMenu == '1',
        catalogueTarif: data.catalogueTarif,
        typeBrochure: data.typeBrochure,
        ranges: data.fk_ranges.split(','),
        applications: data.fk_applications.split(','),
        isNew: data.new == '1',
        brandNew: data.brandNew == '1',
        visibility: data.visibility,
      };
    });
  }

  /**
   * Media
   */
  public getMedia(mediaId: number): any {

    let media = (this.data.media.filter(
      (data: any) => {
        return (+data.pid_media == +mediaId);
      }
    ).map((data:any) => {
      return {
        id: data.pid_media,
        libraryId: data.fk_library,
        zoneId: data.fk_zone,
        zoneIds: data.fk_zones.split('|'),
        image: data.thumbnail,
        titleRef: data.name,
        file: data.file,
        isCatalogueInteractif: data.catalogueInteractif == '1',
        isInMenu: data.inMenu == '1',
        catalogueTarif: data.catalogueTarif,
        typeBrochure: data.typeBrochure,
        ranges: data.fk_ranges.split(','),
        applications: data.fk_applications.split(','),
        isNew: data.new == '1',
        brandNew: data.brandNew == '1',
      };
    })[0]);

    return media;
  }

  /**
   * Application has gallery?
   */
  public applicationHasGallery(applicationId: number): boolean {
    return this.data.galleries.filter(
      (data: any) => {
        return (+data.applicationId === +applicationId);
      }
    ).length > 0;
  }

  /**
   * Get gallery
   */
  public getGallery(applicationId: number): any {
    return this.data.galleries.filter(
      (data: any) => {
        return (+data.applicationId === +applicationId);
      }
    )[0];
  }

  /**
   * Get gallery photos
   */
  public getGalleryPhotos(galleryId: number): any {
    return this.data.galleryphotos.filter(
      (data: any) => {
        return (+data.galleryId === +galleryId);
      }
    );
  }

  /**
   * All products
   */
  get products(): Array<any> {
    return this.data.products;
  }

  /**
   * All products
   */
  getCrossSelling(hotspotId:number): Array<any> {
    return this.data.crosseling.filter(
      (data: any) => {
        return (+data.fk_hotspots === +hotspotId);
      }
    );
  }

  /**
   * Add a media to cart
   */
  public addToCart(mediaId: number) {
    if (!this.cartHasProduct(+mediaId)) {
      this._cart.push(+mediaId);
    }

    localStorage.setItem('cart', this._cart.join(','));
  }

  /**
   * Remove a media from cart
   */
  public removeFromCart(mediaId: number) {
    const idx = this._cart.indexOf(+mediaId);
    if (idx != -1) {
      let item = this._cart.splice(idx, 1);
      localStorage.setItem('cart', this._cart.join(','));
      return item;
    }

    return false;
  }

  /**
   * Is media in cart
   */
  public cartHasProduct(mediaId: number): boolean {
    return this._cart.indexOf(+mediaId) !== -1;
  }

  /**
   * Empty cart
   */
  public emptyCart(): void {
    this._cart = [];
    localStorage.setItem('cart', this._cart.join(','));
  }

  /**
   * Get cart size
   */
  get cartSize(): number {
    return this._cart.length;
  }

  /**
   * Get cart
   */
  get cart(): Array<any> {
    const media: Array<any> = [];
    for (let i = 0; i < this.cartSize; i++) {
      media.push(this.getMedia(this._cart[i]));
    }
    return media;
  }

  /**
	 * Save request
	 */
  public get requests(): Array<any> {
    return this.cache.requests;
  }

  /**
   * Save request
   */
  public saveRequest(
    type: string,
    typeDetail: string,
    name: string,
    surname: string,
    position: string,
    company: string,
    email: string,
    zip: string,
    phone: string,
    products: Array<any>,
    optin:string,
    userID:string,
    insert:boolean = true,
    id:number = null
  ): Promise<boolean> {

    let source = environment.CONTEXT == ContextTypes.APP ? device.platform : 'web';
    const data = {
      lang: this.translate.currentLang,
      userID: userID,
      type: type,
      typeDetail: typeDetail,
      name: name,
      surname: surname,
      position: position,
      company: company,
      email: email,
      zip: zip,
      phone: phone,
      optin: optin,
      source: source,
      products: products.map(function(elem){
        return elem.id;
      })
    }

    return new Promise((resolve, reject) => {
      this.http.post(environment.REMOTE_SERVER + '/api/model/save-datas-mailing.php', data).subscribe((responseData) => {
        if (environment.CONTEXT == ContextTypes.APP) {
          if (insert) {
            this.cache.requests.unshift({
              id: new Date().getTime(),
              lang: this.translate.currentLang,
              userID: userID,
              time: Date.now(),
              type: type,
              typeDetail: typeDetail,
              name: name,
              surname: surname,
              company: company,
              position: position,
              email: email,
              zip: zip,
              phone: phone,
              optin: optin,
              source: source,
              products: products,
              status: 'completed',
            });
            window.localStorage.setItem('cache', JSON.stringify(this.cache));
          } else {
            for (const request of this.cache.requests) {
              if (request.id == id) {
                request.status = 'completed';
              }
            }
            window.localStorage.setItem('cache', JSON.stringify(this.cache));
          }
        }
        resolve(true);
      }, (err) => {
        if (environment.CONTEXT == ContextTypes.APP && insert) {
          this.cache.requests.unshift({
            id: new Date().getTime(),
            lang: this.translate.currentLang,
            userID: userID,
            time: Date.now(),
            type: type,
            typeDetail: typeDetail,
            name: name,
            surname: surname,
            company: company,
            position: position,
            email: email,
            zip: zip,
            phone: phone,
            optin: optin,
            source: source,
            products: products,
            status: 'pending',
          });

          window.localStorage.setItem('cache', JSON.stringify(this.cache));

          resolve(false);
        } else {
          reject(false);
        }
      });
    });
  }

  /**
   * SynchronizeRequests
   */
  public synchronizeRequests(): Promise<boolean> {
    let data = this.cache.requests.filter(
      (d: any) => {
        return (d.status == 'pending');
      }
    );

    //data = Object.assign([], data);

    for (const d of data) {
      d.products = d.products.map(function(elem){
        return elem.id;
      })
    }

    return new Promise((resolve, reject) => {
      this.http.post(environment.REMOTE_SERVER + '/api/model/save-datas-mailing.php', data).subscribe((responseData) => {
        for (const request of this.cache.requests) {
          request.status = 'completed';
        }
        window.localStorage.setItem('cache', JSON.stringify(this.cache));

        resolve(true);
      }, (err) => {
        reject(false);
      });
    });
  }
}
