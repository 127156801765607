/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/shared.pipes";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./video.component";
import * as i6 from "../shared/data/application.data";
import * as i7 from "localize-router/src/localize-router.service";
import * as i8 from "@angular/router";
import * as i9 from "@ionic-native/file/index";
var styles_VideoComponent = [i0.styles];
var RenderType_VideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoComponent, data: { "animation": [{ type: 7, name: "videoTransition", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: "0.6s cubic-bezier(.75,-0.48,.26,1.52)" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.6s cubic-bezier(.75,-0.48,.26,1.52)" }], options: null }], options: {} }] } });
export { RenderType_VideoComponent as RenderType_VideoComponent };
function View_VideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.videoFile)); _ck(_v, 0, 0, currVal_0); }); }
export function View_VideoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeUrlPipe, [i3.DomSanitizer]), i1.ɵqud(402653184, 1, { videoPlayer: 0 }), i1.ɵqud(402653184, 2, { videoControls: 0 }), i1.ɵqud(402653184, 3, { progressBg: 0 }), i1.ɵqud(402653184, 4, { progressBar: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 34, "div", [["class", "video__container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "_media-fullscreen"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "a", [["class", "icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 26, "div", [["class", "_video-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 23, "div", [["class", "_player-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(16, 0, [[1, 0], ["videoPlayer", 1]], null, 4, "video", [["autoplay", ""], ["class", "_player"], ["loop", ""], ["muted", ""], ["playsinline", ""], ["webkit-playsinline", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoComponent_1)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(22, 0, [[2, 0], ["videoControls", 1]], null, 14, "div", [["class", "_controls"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "button", [["class", "_controls__button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.videoControlButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [], [[2, "icon-pause", null], [2, "icon-play", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(29, 0, null, null, 6, "div", [["class", "_controls__progress-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(31, 0, [[3, 0], ["progressBg", 1]], null, 3, "div", [["class", "_controls__progress-container__background"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.videoControlBar($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(33, 0, [[4, 0], ["progressBar", 1]], null, 0, "span", [["class", "_controls__progress-container__background__bar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoFile; _ck(_v, 19, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.videoPlayed; var currVal_2 = !_co.videoPlayed; _ck(_v, 26, 0, currVal_1, currVal_2); }); }
export function View_VideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video", [["class", "fullscreen"]], [[40, "@videoTransition", 0]], null, null, View_VideoComponent_0, RenderType_VideoComponent)), i1.ɵdid(1, 114688, null, 0, i5.VideoComponent, [i6.ApplicationData, i7.LocalizeRouterService, i4.Location, i8.Router, i8.ActivatedRoute, i9.File], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var VideoComponentNgFactory = i1.ɵccf("app-video", i5.VideoComponent, View_VideoComponent_Host_0, {}, { modalClose: "modalClose" }, []);
export { VideoComponentNgFactory as VideoComponentNgFactory };
