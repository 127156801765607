import { StartupService } from '~/app/shared/services/startup.service';
// AoT requires an exported function for factories
export function loadConfig(start) {
    return function () { return start.startup().then().catch(function (e) { }); };
}
var AppModule = (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
