import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { environment } from '~/environments/environment';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
export var mediaTransition = trigger('mediaTransition', [
    transition(':enter', [
        query('._nav-item', style({ opacity: 0 }), { optional: true }),
        query('._nav-item', stagger(100, [
            style({ transform: 'translateY(100px)' }),
            animate('0.4s cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateY(0px)', opacity: 1 })),
        ]), { optional: true }),
    ]),
    transition(':leave', [
        query('._nav-item', stagger(100, [
            style({ transform: 'translateY(0px)', opacity: 1 }),
            animate('0.4s cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateY(100px)', opacity: 0 })),
        ]), { optional: true }),
    ])
]);
/**
 * This class represents the medialibrary component.
 */
var MedialibraryComponent = (function () {
    /**
       * Constructor
       */
    function MedialibraryComponent(applicationData, applicationState) {
        this.applicationData = applicationData;
        this.applicationState = applicationState;
        this.environment = environment;
        //this.applicationState.backURL = '/applications';
    }
    /**
       * On init
       */
    MedialibraryComponent.prototype.ngOnInit = function () {
        this.applicationState.selectedFilter = '-1';
        this.applicationState.selectedFilter2 = '-1';
        this.applicationState.newsFilter = false;
    };
    return MedialibraryComponent;
}());
export { MedialibraryComponent };
