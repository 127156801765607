import { Router } from '@angular/router';
import { transition, trigger } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';
export var registerTransition = trigger('registerTransition', [
    transition(':leave', [])
]);
var RegisterComponent = (function () {
    /**
       * Constructor
       */
    function RegisterComponent(userService, modalService, router, localize) {
        this.userService = userService;
        this.modalService = modalService;
        this.router = router;
        this.localize = localize;
        this.type = -1;
        this.error_text = '';
        this.environment = environment;
        this.ContextTypes = ContextTypes;
        this.type = this.userService.tmp_type;
        this.company = this.userService.tmp_company;
        this.surname = this.userService.tmp_surname;
        this.name = this.userService.tmp_name;
        this.phone = this.userService.tmp_phone;
        this.email = this.userService.tmp_email;
        this.password = this.userService.tmp_password;
        this.confirmPassword = this.userService.tmp_confirmPassword;
        this.optin = this.userService.tmp_optin;
        this.privacyLink = this.localize.translateRoute('/privacy');
    }
    /**
       * On init
       */
    RegisterComponent.prototype.ngOnInit = function () {
    };
    /**
     * Register
     */
    RegisterComponent.prototype.register = function () {
        var _this = this;
        this.submiting = true;
        this.userService.register(String(this.type), this.company, this.surname, this.name, this.phone, this.email, this.password, this.optin).then(function (result) {
            if (result.code === 200) {
                if (environment.CONTEXT == ContextTypes.WEB) {
                    _this.modalService.open('modal-register-confirmation', { title: 'REGISTER.CONFIRM.TITLE', text: 'REGISTER.CONFIRM.TEXT_WEB' });
                }
                else {
                    _this.modalService.open('modal-register-confirmation', { title: 'REGISTER.CONFIRM.TITLE', text: 'REGISTER.CONFIRM.TEXT_APP' });
                }
            }
            else {
                _this.modalService.open('modal-register-message', { title: 'GLOBAL.ERROR', text: result.data });
                _this.submiting = false;
            }
        }).catch(function (e) {
            _this.submiting = false;
            _this.modalService.open('modal-register-message', { title: 'GLOBAL.ERROR', text: 'GLOBAL.ERROR_UNKNOWN' });
        });
        return false;
    };
    /**
     * On close
     */
    RegisterComponent.prototype.onCloseConfirm = function () {
        var _this = this;
        return function () {
            _this.type = -1;
            _this.company = null;
            _this.surname = null;
            _this.name = null;
            _this.phone = null;
            _this.email = null;
            _this.password = null;
            _this.confirmPassword = null;
            _this.optin = null;
            _this.router.navigate(_this.userService.redirectAfterLogin);
        };
    };
    /**
       * On destroy
       */
    RegisterComponent.prototype.ngOnDestroy = function () {
        this.userService.tmp_type = this.type;
        this.userService.tmp_company = this.company;
        this.userService.tmp_surname = this.surname;
        this.userService.tmp_name = this.name;
        this.userService.tmp_phone = this.phone;
        this.userService.tmp_email = this.email;
        this.userService.tmp_password = this.password;
        this.userService.tmp_confirmPassword = this.confirmPassword;
        this.userService.tmp_optin = this.optin;
        this.userService.resetAfterLogin();
    };
    return RegisterComponent;
}());
export { RegisterComponent };
