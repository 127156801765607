import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';

import { ApplicationState } from '~/app/shared/data/application.state';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

export enum UserCategory {
  SYLVANIA = 1,
  APP = 2,
  WEB = 3,
  UNKNONW = 0
}

@Injectable()
export class UserService  {

	public redirectAfterLogin:Array<string>;

  private _loggedIn:boolean;

	private _headers:HttpHeaders;

  public tmp_type:number;
	public tmp_company:string;
	public tmp_surname:string;
	public tmp_name:string;
	public tmp_phone:string;
	public tmp_email:string;
	public tmp_password:string;
  public tmp_confirmPassword:string;
	public tmp_optin:string;

  public id:string;
	public type:number;
	public category:number;
	public position:string;
	public company:string;
	public firstname:string;
	public lastname:string;
	public countryCode:string;
  public phone:string;
	public email:string;
	public optin:string;
	public needUpdate:boolean;
	public token:string;

	/**
	 * Creates a new UserService with the injected Http.
	 */
  constructor(
    private applicationState: ApplicationState,
    private http: HttpClient,
    private router:Router,
    private localize: LocalizeRouterService,
    private translate: TranslateService
  ) {
		this._headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});

    this.resetAfterLogin();
  }

	/**
	 * Init
	 */
  public init() {
    if (localStorage.getItem('auth_date')) {
      let cookieDate = new Date(localStorage.getItem('auth_date'));

	  let limitDate = new Date(cookieDate);
      limitDate.setDate(limitDate.getDate() + 7);
      if (new Date().getTime() < limitDate.getTime()) {
        this.loggedIn = !!localStorage.getItem('auth_token');
    		if (this.loggedIn) {
    			this.id = localStorage.getItem('auth_token');
          this.type = +localStorage.getItem('auth_type');
          this.category = +localStorage.getItem('auth_category');
          this.position = localStorage.getItem('auth_position');
        	this.company = localStorage.getItem('auth_company');
        	this.firstname = localStorage.getItem('auth_firstname');
        	this.lastname = localStorage.getItem('auth_lastname');
        	this.countryCode = localStorage.getItem('auth_countryCode');
          this.phone = localStorage.getItem('auth_phone');
        	this.optin = localStorage.getItem('auth_optin');
    		}
      } else {
        this.logout();
      }
    }

    this.email = localStorage.getItem('auth_email');
    this.applicationState.userID = this.email;
  }
	/**
   * loggedIn
   */
  get loggedIn():boolean {
    return this._loggedIn;
  }
  set loggedIn(value:boolean) {
    this._loggedIn = value;
  }

	/**
	 * Login
	 */
  login(email:string, password:string) {
		let data = {
			login: email,
			password: password,
			access_token: environment.API_TOKEN,
			lang: this.translate.currentLang,
		};
		return new Promise((resolve, reject) => {
			this.http.get(environment.REMOTE_SERVER+'/api/rest/login', {params: data, observe: 'response'}).subscribe((responseData) => {
				const res: any = responseData.body;
				if (res.code === 200) {
					if (res.data.needUpdate == '1') {
						this.token = res.data.token;
				    this.needUpdate = true;
					} else {
						this.token = null;
				    this.needUpdate = false;
						this.updateWithData(res.data);
	          localStorage.setItem('auth_password', password);
					}

        } else {
					this.logout(false);
				}
	      resolve(res);
	    }, (err) => {
				reject(false);
	    });
		});
  }

	/**
	 * Check login
	 */
  check(email:string, password:string) {
		let data = {
			login: email,
			password: password,
			access_token: environment.API_TOKEN,
			lang: this.translate.currentLang,
		};
		return new Promise((resolve, reject) => {
			this.http.get(environment.REMOTE_SERVER+'/api/rest/login', {params: data, observe: 'response'}).subscribe((responseData) => {
				const res: any = responseData.body;
				resolve(res);
	    }, (err) => {
				reject(false);
	    });
		});
  }

	/**
	 * Logout
	 */
  logout(redirect:boolean = true) {
    this.loggedIn = false;
    this.id = null;
    this.position = null;
    this.company = null;
    this.firstname = null;
    this.lastname = null;
    this.countryCode = null;
    this.phone = null;
    this.email = null;
    this.applicationState.userID = this.email;
    this.optin = null;

    localStorage.removeItem('auth_login');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_password');
    localStorage.removeItem('auth_type');
    localStorage.removeItem('auth_category');
    localStorage.removeItem('auth_position');
    localStorage.removeItem('auth_company');
    localStorage.removeItem('auth_firstname');
    localStorage.removeItem('auth_lastname');
    localStorage.removeItem('auth_countryCode');
    localStorage.removeItem('auth_phone');
    // localStorage.removeItem('auth_email');
    localStorage.removeItem('auth_optin');
    localStorage.removeItem('auth_date');

		if (redirect) {
    	this.router.navigate([this.localize.translateRoute('/') as string]);
		}
	}

  /**
	 * Send Password
	 */
  sendPassword(email:string) {
		let data = {
			login: email,
			lang: this.translate.currentLang,
      origin: ''+environment.CONTEXT,
		};
		return new Promise((resolve, reject) => {
			this.http.get(environment.REMOTE_SERVER+'/api/rest/password?access_token='+environment.API_TOKEN, {params: data, observe: 'response'}).subscribe((responseData) => {
				const res: any = responseData.body;
	      resolve(res);
	    }, (err) => {
	      reject(false);
	    });
		});
  }

  /**
	 * reset password
	 */
  resetPassword(password:string, token:string, origin:number) {
		let body = new URLSearchParams();
		body.set('lang', this.translate.currentLang);
		body.set('password', password);
		body.set('token', token);

		return new Promise((resolve, reject) => {
			this.http.post(environment.REMOTE_SERVER+'/api/rest/users/reset?access_token='+environment.API_TOKEN, body.toString(), {headers:this._headers, observe: 'response'}).subscribe((responseData) => {
				const res: any = responseData.body;
				if (res.code == 200 && origin == ContextTypes.WEB) {
		       this.updateWithData(res.data);
		    }
				resolve(res);
			}, (err) => {
				reject(false);
			});
		});
  }

  /**
   * Update with data
   */
  updateWithData(data:any) {
    this.loggedIn = true;
    this.id = data.id;
    this.type = data.type;
    this.category = data.category;
    this.position = data.position;
    this.company = data.company;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.countryCode = data.countryCode;
    this.phone = data.phone;
    this.email = data.email;
    this.applicationState.userID = this.email;
    this.optin = data.optin;

    localStorage.setItem('auth_login',this.email);
    localStorage.setItem('auth_token', this.id);
    localStorage.setItem('auth_type', ''+this.type);
    localStorage.setItem('auth_category', ''+this.category);
    localStorage.setItem('auth_position', this.position);
    localStorage.setItem('auth_company', this.company);
    localStorage.setItem('auth_firstname', this.firstname);
    localStorage.setItem('auth_lastname', this.lastname);
    localStorage.setItem('auth_countryCode', this.countryCode);
    localStorage.setItem('auth_phone', this.phone);
    localStorage.setItem('auth_email', this.email);
    localStorage.setItem('auth_optin', this.optin);
    localStorage.setItem('auth_date', new Date().toString());
  }

	/**
	 * Register
	 */
  register(type:string, company:string, surname:string, name:string,
						phone:string, email:string, password:string, optin:string) {
		let body = new URLSearchParams();
		body.append('lang', this.translate.currentLang);
		body.append('type', type);
		body.append('category', environment.CONTEXT == ContextTypes.WEB ? '3' : '2');
    body.append('company', company ? company : '');
		body.append('surname', surname);
		body.append('name', name);
		body.append('phone', phone);
		body.append('email', email);
		if (password) {
    	body.append('password', password);
		}
		body.append('optin', optin ? optin : '0');

		return new Promise((resolve, reject) => {
			this.http.put(environment.REMOTE_SERVER+'/api/rest/users?access_token='+environment.API_TOKEN, body.toString(), {headers:this._headers, observe: 'response'}).subscribe((responseData) => {
				const res: any = responseData.body;
				if (res.code == 200) {
					if (environment.CONTEXT == ContextTypes.WEB) {
	        	this.updateWithData(res.data);
					}
				}
				resolve(res);
			}, (err) => {
				reject(false);
			});
		});
  }

	/**
	 * reset redirect after login
	 */
  resetAfterLogin() {
		this.redirectAfterLogin = [this.localize.translateRoute('/applications') as string];
	}
}
