export var ApplicationStates;
(function (ApplicationStates) {
    ApplicationStates[ApplicationStates["LOADING"] = 1] = "LOADING";
    ApplicationStates[ApplicationStates["READY"] = 2] = "READY";
    ApplicationStates[ApplicationStates["PAUSED"] = 3] = "PAUSED";
    ApplicationStates[ApplicationStates["MISSING"] = 4] = "MISSING";
    ApplicationStates[ApplicationStates["ERROR"] = 5] = "ERROR";
})(ApplicationStates || (ApplicationStates = {}));
var ApplicationState = (function () {
    function ApplicationState() {
        this.state = null;
        this.userID = null;
        this.dataState = null;
        this.loadingLabel = '';
        this.preventScreensaver = false;
        this._fullscreenEnabled = false;
        this._loading = 0;
        this.selectedFilter = '-1';
        this.selectedFilter2 = '-1';
        this.newsFilter = false;
    }
    Object.defineProperty(ApplicationState.prototype, "fullscreenEnabled", {
        get: function () {
            return this._fullscreenEnabled;
        },
        set: function (value) {
            this._fullscreenEnabled = value;
            if (!this.fullscreenEnabled) {
                document.body.classList.remove('fullscreen');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationState.prototype, "loading", {
        get: function () {
            return this._loading > 0;
        },
        /**
         * loading
         */
        set: function (value) {
            this._loading = Math.max(0, value ? this._loading + 1 : this._loading - 1);
            if (!this.loading) {
                this.loadingLabel = '';
            }
        },
        enumerable: true,
        configurable: true
    });
    return ApplicationState;
}());
export { ApplicationState };
