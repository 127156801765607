<div class="video__container">
  <span class="_media-fullscreen">
    <a (click)="closeModal()" class="icon-close"></a>
  </span>
  <div class="_video-wrapper">
    <div class="_player-container">
      <video class="_player" #videoPlayer muted autoplay webkit-playsinline playsinline loop>
        <source type="video/mp4"  *ngIf="videoFile" [src]="videoFile | safeUrl">
      </video>
      <div class="_controls" #videoControls>
        <button type="button" (click)="videoControlButton()" class="_controls__button">
          <i [class.icon-pause]="videoPlayed" [class.icon-play]="!videoPlayed"></i>
        </button>
        <div class="_controls__progress-container">
          <div class="_controls__progress-container__background" (click)="videoControlBar($event)" #progressBg>
            <span class="_controls__progress-container__background__bar" #progressBar></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
