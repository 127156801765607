import { DomSanitizer } from '@angular/platform-browser';
var SafeUrlPipe = (function () {
    function SafeUrlPipe(domSanitizer) {
        this.domSanitizer = domSanitizer;
    }
    SafeUrlPipe.prototype.transform = function (url) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return SafeUrlPipe;
}());
export { SafeUrlPipe };
var FilterPipe = (function () {
    function FilterPipe() {
    }
    FilterPipe.prototype.transform = function (list, keyValues) {
        return list.filter(function (item) {
            var _loop_1 = function (key) {
                if (typeof keyValues[key] === 'object') {
                    if (keyValues[key].length > 0) {
                        if (item[key]) {
                            if (typeof item[key] === 'object') {
                                return { value: item[key].every(function (elem) { return keyValues[key].indexOf(elem) > -1; }) };
                            }
                            else {
                                if (keyValues[key].indexOf(item[key]) === -1) {
                                    return { value: false };
                                }
                            }
                        }
                    }
                }
                else {
                    return { value: !(item[key] && item[key] !== keyValues[key] && keyValues[key] && keyValues[key] !== -1 && keyValues[key] !== '-1') };
                }
            };
            for (var key in keyValues) {
                var state_1 = _loop_1(key);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            return true;
        });
    };
    return FilterPipe;
}());
export { FilterPipe };
var Nl2BrPipe = (function () {
    function Nl2BrPipe() {
    }
    Nl2BrPipe.prototype.transform = function (value) {
        return value.replace(/\n/g, '<br>');
    };
    return Nl2BrPipe;
}());
export { Nl2BrPipe };
