import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

import { environment } from '~/environments/environment';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {

  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const url = environment.BASE + '/assets/locales/' + lang + '.json';
    return Observable.create(observer => {
      this.http.get(url, { headers: this.contentHeader }).subscribe((res: HttpResponse<any>) => {
        observer.next(res);
        observer.complete();
      },
      error => {
        //  failed to retrieve from base, switch to local
        this.http.get('./assets/locales/' + lang + '.json').subscribe((res: HttpResponse<any>) => {
          observer.next(res);
          observer.complete();
        });
      });
    });
  }
}
