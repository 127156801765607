import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, AnimationEvent, query, stagger, style, transition, trigger } from '@angular/animations';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationData, DocumentType } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
import { LocalizeRouterService } from 'localize-router';

import { UserService, UserCategory } from '~/app/shared/services/user.service';
import { ToolsService } from '~/app/shared/services/tools.service';

declare let cordova:any;
declare let device:any;

/**
 * This class represents the medialibrary component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-medialist',
  templateUrl: 'medialist.component.html',
  styleUrls: ['medialist.component.scss'],
  host: {
    'class': 'screen'
  }
})

export class MediaListComponent {

  public isApp:boolean;

  public mediaType:number;

  public environment = environment;
  public applications: Array<any>;
  public allMedia:Array<any>;
  public media:Array<any>;
  public ranges:Array<any>;
  public filter:string = "-1";
  public filter2:string = "-1";

  public DocumentType = DocumentType;

  private routeSub: any;

  private _selectedFilter:string = '-1';
  get selectedFilter():string {
    return this._selectedFilter;
  }
  set selectedFilter(value:string) {
    this._selectedFilter = value;
    this.filter = this.selectedFilter;

    this.applicationState.selectedFilter = this.selectedFilter;

    this.updateMedia();
  }

  private _selectedFilter2:string = '-1';
  get selectedFilter2():string {
    return this._selectedFilter2;
  }
  set selectedFilter2(value:string) {
    this._selectedFilter2 = value;
    this.filter2 = this.selectedFilter2;

    this.applicationState.selectedFilter2 = this.selectedFilter2;

    this.updateMedia();
  }

  private _newsFilter:boolean = false;
  get newsFilter():boolean {
    return this._newsFilter;
  }
  set newsFilter(value:boolean) {
    this._newsFilter = value;
    this.filter2 = this.newsFilter ? '1' : '-1';

    this.applicationState.newsFilter = this.newsFilter;

    this.updateMedia();
  }

  /**
	 * Constructor
	 */
  constructor(
    public applicationData:ApplicationData,
    public applicationState:ApplicationState,
    public toolsService: ToolsService,
    private userService: UserService,
    private modalService: ModalService,
    public router:Router,
    private route: ActivatedRoute,
    private localize:LocalizeRouterService
  ) {
    this.isApp = environment.CONTEXT == ContextTypes.APP;
  }

  /**
	 * On init
	 */
  ngOnInit() {
    this.applicationState.backURL2 = '/medialibrary';
    this.applications = this.applicationData.applications;

    this.routeSub = this.route.params.subscribe(params => {
      this.mediaType = +params['id'];

      this._selectedFilter = this.applicationState.selectedFilter;
      this._selectedFilter2 = this.applicationState.selectedFilter2;
      this._newsFilter = this.applicationState.newsFilter;
      
      this.filter = this._selectedFilter;
      this.filter2 = this.newsFilter ? '1' : this._selectedFilter2;

      this.allMedia = this.applicationData.getMediaByType(this.mediaType);
      this.media = this.allMedia;
      this.ranges = this.applicationData.ranges;
      this.updateMedia();
    });

  }

  /**
   * Update media
   */
  updateMedia() {
    switch(this.mediaType) {
      case DocumentType.PRODUCT:
        this.media = this.allMedia.filter(
          (data:any) => {
            if (data.visibility != 0 && this.userService.category != UserCategory.SYLVANIA) {
              return false;
            }

            if (this.filter2 != '-1' && this.filter != '-1') {
              return (data.ranges.includes(this.filter) && (data.isNew || data.brandNew));
            } else if (this.filter != '-1') {
              return (data.ranges.includes(this.filter));
            } else if (this.filter2 != '-1') {
              return (data.isNew || data.brandNew);
            } else {
              return true;
            }
          }
        );
      break;
      case DocumentType.CATALOG:
        this.media = this.allMedia.filter(
          (data:any) => {
            if (data.visibility != 0 && this.userService.category != UserCategory.SYLVANIA) {
              return false;
            }

            return (this.filter == '-1' || (data.catalogueTarif == this.filter));
          }
        );
      break;
      case DocumentType.SPECIFIC:
        this.media = this.allMedia.filter(
          (data:any) => {
            if (data.visibility != 0 && this.userService.category != UserCategory.SYLVANIA) {
              return false;
            }

            if (this.filter2 != '-1' && this.filter != '-1') {
              return (data.applications.includes(this.filter) && data.typeBrochure == this.filter2);
            } else if (this.filter != '-1') {
              return (data.applications.includes(this.filter));
            } else if (this.filter2 != '-1') {
              return (data.typeBrochure == this.filter2);
            } else {
              return true;
            }
          }
        );
      break
      default:
        this.media = this.allMedia;
    }
  }

  /**
	 * Add to cart
	 */
  addToCart(e:Event, pid:number) {
    e.preventDefault();
    this.applicationData.addToCart(pid);
  }

  /**
   * Remove from cart
   */
  removeFromCart(e:Event, pid:number) {
    e.preventDefault();
    this.applicationData.removeFromCart(pid);
  }

  /**
	 * Goto
	 */
  goto(url:Array<string>) {
    this.applicationState.fromURL = '/medialist/'+this.mediaType;
    this.router.navigate([this.localize.translateRoute(url.join('/'))]);
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
