/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core/src/translate.pipe";
import * as i3 from "@ngx-translate/core/src/translate.service";
import * as i4 from "./legals.component";
var styles_LegalsComponent = [i0.styles];
var RenderType_LegalsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LegalsComponent, data: {} });
export { RenderType_LegalsComponent as RenderType_LegalsComponent };
export function View_LegalsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "privacy__container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "screen__container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "screen__container__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "_title-2"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "privacy__content text__content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("LEGALS.TITLE")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("LEGALS.HTML")); _ck(_v, 10, 0, currVal_1); }); }
export function View_LegalsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-legals", [["class", "screen"]], null, null, null, View_LegalsComponent_0, RenderType_LegalsComponent)), i1.ɵdid(1, 114688, null, 0, i4.LegalsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegalsComponentNgFactory = i1.ɵccf("app-legals", i4.LegalsComponent, View_LegalsComponent_Host_0, {}, {}, []);
export { LegalsComponentNgFactory as LegalsComponentNgFactory };
