import { ApplicationData } from '../data/application.data';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
var StartupService = (function () {
    /**
       * Constructor
       */
    function StartupService(appData) {
        this.appData = appData;
    }
    /**
       * Startup
       */
    StartupService.prototype.startup = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (environment.CONTEXT == ContextTypes.APP) {
                resolve(false);
            }
            else {
                _this.appData.load().then(function (response) {
                    resolve(true);
                }).catch(function (e) {
                    reject(false);
                });
            }
        });
    };
    return StartupService;
}());
export { StartupService };
