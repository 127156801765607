/**
 * This class represents the medialibrary component.
 */
var PrivacyComponent = (function () {
    /**
       * Constructor
       */
    function PrivacyComponent() {
    }
    /**
       * On init
       */
    PrivacyComponent.prototype.ngOnInit = function () {
    };
    return PrivacyComponent;
}());
export { PrivacyComponent };
