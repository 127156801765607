<div class="medialist__container">
  <div class="screen__container">
    <div class="screen__container__header">
      <div class="">
        <p class="_title-2" translate *ngIf="mediaType == 1">MEDIALIBRARY.TYPE1</p>
        <p class="_title-2" translate *ngIf="mediaType == 2">MEDIALIBRARY.TYPE2</p>
        <p class="_title-2" translate *ngIf="mediaType == 3">MEDIALIBRARY.TYPE3</p>
        <p class="_title-2" translate *ngIf="mediaType == 4">MEDIALIBRARY.TYPE4</p>
      </div>
      <div class="" *ngIf="mediaType != 4">

        <div class="_select1">
          <div class="_select" *ngIf="mediaType == 1">
            <select name="rangeList" id="rangeList" [(ngModel)]="selectedFilter">
              <option value="-1" translate>MEDIALIBRARY.SELECT_LABEL_BY_FAMILY</option>
              <option *ngFor="let range of ranges" value="{{ range.id }}" translate>{{ range.titleRef }}</option>
            </select>
            <div class="_select__arrow"></div>
          </div>

          <div class="_select" *ngIf="mediaType == 2">
            <select name="rangeList" id="rangeList" [(ngModel)]="selectedFilter">
              <option value="-1" translate>MEDIALIBRARY.SELECT_LABEL</option>
              <option value="1" translate>MEDIALIBRARY.CATALOGUES</option>
              <option value="2" translate>MEDIALIBRARY.PRICES</option>
            </select>
            <div class="_select__arrow"></div>
          </div>

          <div class="_select" *ngIf="mediaType == 3">
            <select name="rangeList" id="rangeList" [(ngModel)]="selectedFilter">
              <option value="-1" translate>MEDIALIBRARY.SELECT_LABEL_BY_APPLICATION</option>
              <option *ngFor="let application of applications" value="{{ application.id }}" translate>{{ application.titleRef }}</option>
            </select>
            <div class="_select__arrow"></div>
          </div>
        </div><!--

         --><div class="_select2">
          <div class="_checkbox" *ngIf="mediaType == 1">
            <label><input type="checkbox" name="newsFilter" id="newsFilter" value="1" [(ngModel)]="newsFilter"> {{ 'MEDIALIBRARY.NEWS' | translate }} </label>
          </div>
          <div class="_select" *ngIf="mediaType == 3">
            <select name="rangeList2" id="rangeList2" [(ngModel)]="selectedFilter2">
              <option value="-1" translate>MEDIALIBRARY.SELECT_LABEL_BY_TYPE</option>
              <option value="brochure" translate>MEDIALIBRARY.BROCHURES</option>
              <option value="sylsmart" translate>MEDIALIBRARY.SYLSMART</option>
              <option value="services" translate>MEDIALIBRARY.SERVICES</option>
              <option value="produits" translate>MEDIALIBRARY.PRODUCTS</option>
            </select>
            <div class="_select__arrow"></div>
          </div>
        </div>

      </div>
    </div>
    <ul class="_products">
      <li class="_products__item" *ngFor="let item of media">
        <div class="ribbon" *ngIf="item.brandNew"><span>{{ 'MEDIALIBRARY.BRANDNEW' | translate }}</span></div>
        <div class="ribbon2" *ngIf="item.visibility == '1'"><span>{{ 'MEDIALIBRARY.PRIVATE' | translate }}</span></div>
        <div class="_products__item__image-container">
          <img class="_products__item__image-container__image" [src]="item.image | safeUrl" *ngIf="item.image"/>
        </div>
        <p class="_products__item__information">
          <span class="_products__item__information__label" translate>{{ item.titleRef }}</span>
          <span class="_products__item__information__desc" translate>{{ item.descRef }}</span>
        </p>
        <ul class="_products__item__button-container">
          <ng-container *ngIf="item.zoneIds.length > 1 ;else notmultizone">
          <div class="_products__item__multi-button-container">
            <div><i class="_products__item__multi-button-container__button__content__icon icon-eye"></i> {{ 'MEDIALIBRARY.ZONES-LIST' | translate }}</div>
            <div>
              <div class="_select">
                <select name="zonesList" id="zonesList" (change)="goto(['/', 'applications', applicationData.getZone($event.target.value).applicationId, 'zones', $event.target.value])">
                  <option value="-1" translate>MEDIALIBRARY.ZONES-PROMPT</option>
                  <ng-container *ngFor="let zone of item.zoneIds">
                    <option *ngIf="applicationData.getZone(zone)" value="{{ zone }}" translate>{{applicationData.getZone(zone).titleRef}}</option>
                  </ng-container>
                </select>
                <div class="_select__arrow"></div>
              </div>
            </div>
            <!-- <ng-container  *ngFor="let zone of item.zoneIds">
              <a class="_products__item__button-container__button _products__item__button-container__button--small" (click)="goto(['/', 'applications', applicationData.getZone(zone).applicationId, 'zones', zone])" *ngIf="zone && zone != '0'">
                <li class="_products__item__button-container__button__content">
                  <i class="_products__item__button-container__button__content__icon icon-eye"></i>
                </li>
              </a>
            </ng-container> -->
          </div>
          </ng-container>
          <ng-template #notmultizone>
            <a class="_products__item__button-container__button" (click)="goto(['/', 'applications', applicationData.getZone(item.zoneIds[0]).applicationId, 'zones', item.zoneIds[0]])" *ngIf="item.zoneIds && item.zoneIds[0] && item.zoneIds[0] != ''">
              <li class="_products__item__button-container__button__content">
                <i class="_products__item__button-container__button__content__icon icon-eye"></i>
                <span class="_products__item__button-container__button__content__label" translate>GLOBAL.REALISATION</span>
              </li>
            </a>
          </ng-template>
          <a class="_products__item__button-container__button" [href]="applicationData.convertFilePath(item.file) | safeUrl" (click)="toolsService.openFile($event)" [attr.data-title]="item.titleRef | translate" target="_blank" *ngIf="!item.isCatalogueInteractif && mediaType != DocumentType.VIDEO">
            <li class="_products__item__button-container__button__content">
              <i class="_products__item__button-container__button__content__icon icon-doc-copy"></i>
              <span class="_products__item__button-container__button__content__label" translate>GLOBAL.SEE</span>
            </li>
          </a>
          <a class="_products__item__button-container__button" [routerLink]="[('/' | localize),{outlets: { pop: ['cat'] } }]" [attr.data-title]="item.titleRef | translate" *ngIf="item.isCatalogueInteractif && isApp">
            <li class="_products__item__button-container__button__content">
              <i class="_products__item__button-container__button__content__icon icon-doc-copy"></i>
              <span class="_products__item__button-container__button__content__label" translate>GLOBAL.SEE</span>
            </li>
          </a>
          <a class="_products__item__button-container__button" href="/ecatalogues/current/" target="_blank" [attr.data-title]="item.titleRef | translate" *ngIf="item.isCatalogueInteractif && !isApp">
            <li class="_products__item__button-container__button__content">
              <i class="_products__item__button-container__button__content__icon icon-doc-copy"></i>
              <span class="_products__item__button-container__button__content__label" translate>GLOBAL.SEE</span>
            </li>
          </a>
          <a class="_products__item__button-container__button" [routerLink]="[('/' | localize),{outlets: { video: [item.id] } }]" [routerLinkActive]="['router-link-active']" *ngIf="mediaType == DocumentType.VIDEO">
            <li class="_products__item__button-container__button__content" >
              <i class="_products__item__button-container__button__content__icon icon-circle-play"></i>
              <span class="_products__item__button-container__button__content__label" translate>GLOBAL.SEE</span>
            </li>
          </a>
          <li class="_products__item__button-container__button"  [class.--viridian]="!applicationData.cartHasProduct(item.id)" [class.--gray]="applicationData.cartHasProduct(item.id)" *ngIf="item.visibility != '1'">
            <ng-container *ngIf="!applicationData.cartHasProduct(item.id); else elseBlock">
              <button class="_products__item__button-container__button__content" (click)="addToCart($event, item.id)">
                <i class="_products__item__button-container__button__content__icon _products__item__button-container__button__content__icon icon-fill-trolley"></i>
                <span class="_products__item__button-container__button__content__label" translate>GLOBAL.ADD</span>
              </button>
            </ng-container>
            <ng-template  #elseBlock>
              <button class="_products__item__button-container__button__content" (click)="removeFromCart($event, item.id)">
                <i class="_products__item__button-container__button__content__icon _products__item__button-container__button__content__icon icon-empty-trolley"></i>
                <span class="_products__item__button-container__button__content__label" translate>GLOBAL.REMOVE</span>
              </button>
            </ng-template>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
