/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./catalog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/shared.pipes";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./catalog.component";
import * as i5 from "../shared/data/application.data";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "localize-router/src/localize-router.service";
var styles_CatalogComponent = [i0.styles];
var RenderType_CatalogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CatalogComponent, data: {} });
export { RenderType_CatalogComponent as RenderType_CatalogComponent };
export function View_CatalogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeUrlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "_media-fullscreen"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [["class", "icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "iframe__container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "iframe", [["height", "100%"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(10, 1), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.applicationData.convertFilePath((_co.environment.BASE + "/api/uploads/e-catalog/index.html")))); _ck(_v, 9, 0, currVal_0); }); }
export function View_CatalogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-catalog", [["class", "popscreen"]], null, null, null, View_CatalogComponent_0, RenderType_CatalogComponent)), i1.ɵdid(1, 114688, null, 0, i4.CatalogComponent, [i5.ApplicationData, i6.Router, i7.Location, i8.LocalizeRouterService, i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CatalogComponentNgFactory = i1.ɵccf("app-catalog", i4.CatalogComponent, View_CatalogComponent_Host_0, {}, {}, []);
export { CatalogComponentNgFactory as CatalogComponentNgFactory };
