import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationStates } from '~/app/shared/data/application.state';
import { UserService } from '~/app/shared/services/user.service';
var DataGuard = (function () {
    function DataGuard(applicationData, router, localize) {
        this.applicationData = applicationData;
        this.router = router;
        this.localize = localize;
    }
    DataGuard.prototype.canActivate = function (route, state) {
        if (this.applicationData.dataState == ApplicationStates.READY) {
            return true;
        }
        else {
            this.router.navigate([this.localize.translateRoute('/setup')]);
            return false;
        }
    };
    return DataGuard;
}());
export { DataGuard };
var AuthGuard = (function () {
    function AuthGuard(userService, router, localize) {
        this.userService = userService;
        this.router = router;
        this.localize = localize;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        if (environment.CONTEXT == ContextTypes.APP && !this.userService.loggedIn) {
            this.userService.redirectAfterLogin = [state.url];
            this.router.navigate([this.localize.translateRoute('/auth')]);
            return false;
        }
        else {
            return true;
        }
    };
    return AuthGuard;
}());
export { AuthGuard };
