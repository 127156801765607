import { NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
import { FileTransfer } from '@ionic-native/file-transfer';
import { File } from '@ionic-native/file';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { UserService } from '~/app/shared/services/user.service';
import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
import { FakeData } from '~/app/shared/data/fake.data';
export var SetupStates;
(function (SetupStates) {
    SetupStates[SetupStates["LOADING"] = 1] = "LOADING";
    SetupStates[SetupStates["READY"] = 2] = "READY";
    SetupStates[SetupStates["ERROR"] = 3] = "ERROR";
    SetupStates[SetupStates["NEED_UPDATE"] = 4] = "NEED_UPDATE";
})(SetupStates || (SetupStates = {}));
var SetupComponent = (function () {
    /**
     * Constructor
     */
    function SetupComponent(applicationData, userService, http, applicationState, transfer, file, modalService, translate, ref, zone) {
        this.applicationData = applicationData;
        this.userService = userService;
        this.http = http;
        this.applicationState = applicationState;
        this.transfer = transfer;
        this.file = file;
        this.modalService = modalService;
        this.translate = translate;
        this.ref = ref;
        this.zone = zone;
        this.environment = environment;
        this.state = SetupStates.LOADING;
        this.SetupStates = SetupStates;
        this.loging = false;
        this.currentFile = "";
    }
    /**
     * On init
     */
    SetupComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (window['plugins'] && window['plugins'].insomnia) {
            window['plugins'].insomnia.keepAwake();
        }
        this.applicationState.preventScreensaver = true;
        console.log("Get online data...");
        this.http.get(environment.REMOTE_SERVER + '/api/?c=' + (new Date().getTime()) + '&uid=' + this.applicationState.userID).subscribe(function (responseData) {
            var data = responseData;
            if (_this.applicationState.userID == 'fr.info@sylvania-lighting.com') {
                data.data = FakeData.defaults;
            }
            console.log("  Online version:", data.data['version']);
            console.log("  Local version:", _this.applicationData.version);
            if (data.data['version'] == _this.applicationData.version) {
                console.log("    -> ok");
                _this.state = SetupStates.READY;
            }
            else {
                console.log("    -> update");
                _this.newData = data;
                _this.state = SetupStates.NEED_UPDATE;
            }
        }, function (err) {
            console.log("    Are we offline?");
            _this.state = SetupStates.ERROR;
        });
        this.state = SetupStates.NEED_UPDATE;
        // console.log("Get dir content");
        // this.file.listDir(environment.BASE + '/api/uploads', 'e-catalog').then(console.log.bind(console))
        //   .catch(console.error.bind(console));
    };
    SetupComponent.prototype.createParentDirectoriesIfNoExist = function (dataDirectoryRelativeFilePath) {
        var _this = this;
        // console.log("createParentDirectoriesIfNoExist("+dataDirectoryRelativeFilePath+")");
        return new Promise(function (resolve, reject) {
            var _pathLvls = dataDirectoryRelativeFilePath.split("/");
            _pathLvls.pop();
            var _levelCount = _pathLvls.length;
            // console.log("\t_pathLvls", _pathLvls);
            var _current = 0;
            var _currentPath = _this.file.dataDirectory;
            var _createNextLevel = function () {
                if (_current >= _levelCount) {
                    // console.log("\tALL CHECKED");
                    resolve();
                }
                else if (_pathLvls[_current] == "") {
                    _current++;
                    _createNextLevel();
                }
                else {
                    _this.file.checkDir(_currentPath, _pathLvls[_current]).then(function () {
                        // console.log("\tcheckDir("+_pathLvls[_current]+") FROM "+_currentPath+" => EXIST");
                        _currentPath += _pathLvls[_current] + "/";
                        _current++;
                        _createNextLevel();
                    }).catch(function (err) {
                        // console.log("\tcheckDir("+_pathLvls[_current]+") FROM "+_currentPath+" => DON'T EXIST");
                        _this.file.createDir(_currentPath, _pathLvls[_current], false).then(function () {
                            // console.log("\t\tDIR "+_pathLvls[_current]+" CREATED.");
                            _currentPath += _pathLvls[_current] + "/";
                            _current++;
                            _createNextLevel();
                        }).catch(function (err) {
                            // console.log("\t\tDIR "+_pathLvls[_current]+" CREATION FAIL !");
                            reject(err);
                        });
                    });
                }
            };
            _createNextLevel();
        });
    };
    /**
     * Request access
     */
    SetupComponent.prototype.requestAccess = function (callback) {
        this.modalService.open('modal-request-password', { callback: callback });
    };
    /**
     * Request access
     */
    SetupComponent.prototype.processAccess = function (callback) {
        var _this = this;
        this.loging = true;
        this.userService.check(this.userService.email, this.passwordSetup).then(function (result) {
            _this.loging = false;
            _this.modalService.cancel('modal-request-password');
            if (result.code === 200) {
                if (callback) {
                    callback.bind(_this)();
                }
            }
            else {
                _this.modalService.open('modal-error', { title: 'GLOBAL.ERROR', text: result.data });
            }
        }).catch(function (e) {
            console.log(e);
            _this.loging = false;
            _this.modalService.cancel('modal-request-password');
            _this.modalService.open('modal-error', { title: 'GLOBAL.ERROR', text: 'Veuillez vérifier que vous êtes connecté à internet avant de recommencer' });
        });
    };
    /**
     * Synchronize
     */
    SetupComponent.prototype.synchronize = function () {
        var _this = this;
        console.log("Synchronize...");
        if (environment.CONTEXT == ContextTypes.APP) {
            this.applicationState.loading = true;
            this.state = SetupStates.LOADING;
            // Changed Files
            var addFiles = [];
            var _loop_1 = function (i) {
                if (this_1.newData.data.library[i].src) {
                    if (this_1.applicationData.data && this_1.applicationData.data.library) {
                        var search = this_1.applicationData.data.library.find(function (data) {
                            return data.src == _this.newData.data.library[i].src && data.hash == _this.newData.data.library[i].hash;
                        });
                        if (!search) {
                            console.log("  Add:", this_1.newData.data.library[i].src);
                            addFiles.push('/api/uploads/' + this_1.newData.data.library[i].src);
                        }
                    }
                    else {
                        console.log("  Add:", this_1.newData.data.library[i].src);
                        addFiles.push('/api/uploads/' + this_1.newData.data.library[i].src);
                    }
                }
            };
            var this_1 = this;
            for (var i = 0; i < this.newData.data.library.length; i++) {
                _loop_1(i);
            }
            // Removed Files
            var removePromises_1 = [];
            if (this.applicationData.data && this.applicationData.data.library) {
                var _loop_2 = function (i) {
                    if (this_2.applicationData.data.library[i].src) {
                        var search = this_2.newData.data.library.find(function (data) {
                            return data.src == _this.applicationData.data.library[i].src;
                        });
                        if (!search) {
                            removePromises_1.push(this_2.file.removeFile(environment.BASE + '/api/uploads', this_2.applicationData.data.library[i].src));
                        }
                    }
                };
                var this_2 = this;
                for (var i = 0; i < this.applicationData.data.library.length; i++) {
                    _loop_2(i);
                }
            }
            // Locales
            console.log("  Update: locales.json");
            addFiles.push('/assets/locales.json');
            console.log("  Update: locales/xx.json");
            addFiles.push('/assets/locales/fr.json');
            //addFiles.push('/assets/locales/en.json');
            var options_1 = {
                headers: {
                    Connection: 'close'
                }
            };
            addFiles.reduce(function (p, theFile) {
                return p.then(function () {
                    _this.currentFile = theFile;
                    _this.applicationState.loadingLabel = "Download " + theFile;
                    return new Promise(function (resolve, reject) {
                        _this.createParentDirectoriesIfNoExist(theFile).then(function () {
                            var t = _this.transfer.create();
                            t.onProgress(function (progressEvent) {
                                _this.zone.run(function () {
                                    if (progressEvent.lengthComputable) {
                                        var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
                                        _this.applicationState.loadingLabel = "Download " + theFile + " (" + perc + "%)";
                                        _this.ref.detectChanges();
                                    }
                                });
                            });
                            //t.download('http://sylvania.passerelle.com' + theFile, environment.BASE + theFile, true, options).then(()=>{
                            t.download(environment.REMOTE_SERVER + theFile, environment.BASE + theFile, true, options_1).then(function () {
                                var re = /(?:\.([^.]+))?$/;
                                if ((re.exec(theFile)[1]).toLowerCase() == 'zip') {
                                    zip.unzip(environment.BASE + theFile, environment.BASE + '/api/uploads', function () {
                                        _this.file.removeFile(environment.BASE + '/api/uploads', theFile.replace(/^.*[\\\/]/, '')).then(function () { });
                                        resolve();
                                    }, function (progress) {
                                    });
                                }
                                else {
                                    resolve();
                                }
                            }, function (error) {
                                reject(error);
                            });
                        }).catch(function (error) {
                            reject(error);
                        });
                    });
                });
            }, Promise.resolve()).then(function () {
                console.log("  -> All files ready");
                console.log("  Delete orphan files");
                Promise.all(removePromises_1).then(function (values) {
                    console.log("  --> Removed");
                }).catch(function (reasons) {
                    console.log("  --> Failed");
                    console.log(reasons);
                });
                _this.translate.reloadLang('fr').subscribe();
                //this.translate.reloadLang('en').subscribe();
                console.log("  Update: data/data.json");
                _this.applicationState.loadingLabel = "Download " + "data/data.json";
                // Data
                _this.transfer.create().download(environment.REMOTE_SERVER + '/api/', environment.BASE + 'assets/data/datas.json').then(function (entry) {
                    _this.applicationData.load().then(function (response) {
                        _this.state = SetupStates.READY;
                        _this.applicationState.loading = false;
                    }).catch(function (e) {
                        _this.modalService.open('modal-error', { title: 'GLOBAL.FATAL_ERROR', text: 'SETUP.FATAL_ERROR' });
                        _this.state = SetupStates.ERROR;
                        _this.applicationState.loading = false;
                    });
                }, function (error) {
                    _this.modalService.open('modal-error', { title: 'GLOBAL.FATAL_ERROR', text: 'SETUP.FATAL_ERROR' });
                    _this.state = SetupStates.ERROR;
                    _this.applicationState.loading = false;
                });
            }).catch(function (reasons) {
                console.log("  -> Failed");
                console.log(reasons);
                _this.modalService.open('modal-error', { title: 'GLOBAL.FATAL_ERROR', text: 'SETUP.FATAL_ERROR' });
                _this.state = SetupStates.NEED_UPDATE;
                _this.applicationState.loading = false;
            });
        }
    };
    /**
     * Synchronize requets
     */
    SetupComponent.prototype.synchronizeRequests = function () {
        var _this = this;
        this.applicationState.loading = true;
        this.applicationData.synchronizeRequests().then(function (response) {
            _this.applicationState.loading = false;
        }).catch(function (e) {
            _this.modalService.open('modal-error', { title: 'GLOBAL.FATAL_ERROR', text: 'SETUP.FATAL_ERROR_SYNC' });
            _this.applicationState.loading = false;
        });
    };
    /**
     * On destroy
     */
    SetupComponent.prototype.ngOnDestroy = function () {
        this.applicationState.preventScreensaver = false;
        if (window['plugins'] && window['plugins'].insomnia) {
            window['plugins'].insomnia.allowSleepAgain();
        }
    };
    return SetupComponent;
}());
export { SetupComponent };
