export const FakeData = {
  defaults: {
    "applications": [
      {
        "id": "1",
        "sort": "1",
        "image": "1",
        "titleRef": "Bureaux",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      },
      {
        "id": "2",
        "sort": "2",
        "image": "2",
        "titleRef": "Education",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      },
      {
        "id": "3",
        "sort": "3",
        "image": "3",
        "titleRef": "Logistique & Industrie",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      },
      {
        "id": "4",
        "sort": "4",
        "image": "4",
        "titleRef": "Commerce",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      },
      {
        "id": "5",
        "sort": "5",
        "image": "5",
        "titleRef": "Cafés, Hôtels, Restaurants",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      },
      {
        "id": "6",
        "sort": "6",
        "image": "6",
        "titleRef": "Musées & Galeries",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      },
      {
        "id": "7",
        "sort": "7",
        "image": "7",
        "titleRef": "Eclairage architectural extérieur",
        "country": "1",
        "fk_mediaBrochure": "0",
        "fk_mediaVideo": "0"
      }
    ],
    "galleries": [
      {
        "id": "9",
        "applicationId": "4",
        "titleRef": "Commerces"
      },
      {
        "id": "6",
        "applicationId": "7",
        "titleRef": "Eclairage architectural extérieur"
      },
      {
        "id": "5",
        "applicationId": "5",
        "titleRef": "Cafés, Hôtels, Restaurants"
      },
      {
        "id": "4",
        "applicationId": "3",
        "titleRef": "Logistique & Industrie"
      },
      {
        "id": "3",
        "applicationId": "1",
        "titleRef": "Bureaux"
      },
      {
        "id": "2",
        "applicationId": "2",
        "titleRef": "Education"
      },
      {
        "id": "1",
        "applicationId": "6",
        "titleRef": "Musées & Galeries"
      }
    ],
    "galleryphotos": [
      {
        "id": "1",
        "galleryId": "1",
        "image": "208"
      },
      {
        "id": "2",
        "galleryId": "1",
        "image": "209"
      },
      {
        "id": "3",
        "galleryId": "1",
        "image": "210"
      },
      {
        "id": "4",
        "galleryId": "1",
        "image": "211"
      },
      {
        "id": "7",
        "galleryId": "2",
        "image": "214"
      },
      {
        "id": "8",
        "galleryId": "2",
        "image": "215"
      },
      {
        "id": "9",
        "galleryId": "2",
        "image": "216"
      },
      {
        "id": "10",
        "galleryId": "2",
        "image": "217"
      },
      {
        "id": "23",
        "galleryId": "3",
        "image": "230"
      },
      {
        "id": "24",
        "galleryId": "3",
        "image": "231"
      },
      {
        "id": "25",
        "galleryId": "3",
        "image": "232"
      },
      {
        "id": "26",
        "galleryId": "3",
        "image": "233"
      },
      {
        "id": "30",
        "galleryId": "4",
        "image": "237"
      },
      {
        "id": "31",
        "galleryId": "4",
        "image": "238"
      },
      {
        "id": "32",
        "galleryId": "4",
        "image": "239"
      },
      {
        "id": "33",
        "galleryId": "4",
        "image": "240"
      },
      {
        "id": "34",
        "galleryId": "5",
        "image": "241"
      },
      {
        "id": "35",
        "galleryId": "5",
        "image": "242"
      },
      {
        "id": "36",
        "galleryId": "5",
        "image": "243"
      },
      {
        "id": "37",
        "galleryId": "5",
        "image": "244"
      },
      {
        "id": "45",
        "galleryId": "6",
        "image": "252"
      },
      {
        "id": "46",
        "galleryId": "6",
        "image": "253"
      },
      {
        "id": "47",
        "galleryId": "6",
        "image": "254"
      },
      {
        "id": "48",
        "galleryId": "6",
        "image": "255"
      },
      {
        "id": "64",
        "galleryId": "7",
        "image": "794"
      },
      {
        "id": "65",
        "galleryId": "7",
        "image": "795"
      },
      {
        "id": "66",
        "galleryId": "7",
        "image": "796"
      },
      {
        "id": "67",
        "galleryId": "7",
        "image": "797"
      },
      {
        "id": "68",
        "galleryId": "7",
        "image": "798"
      },
      {
        "id": "69",
        "galleryId": "7",
        "image": "799"
      },
      {
        "id": "70",
        "galleryId": "7",
        "image": "800"
      },
      {
        "id": "71",
        "galleryId": "7",
        "image": "801"
      },
      {
        "id": "72",
        "galleryId": "7",
        "image": "802"
      },
      {
        "id": "73",
        "galleryId": "9",
        "image": "803"
      },
      {
        "id": "74",
        "galleryId": "9",
        "image": "804"
      },
      {
        "id": "75",
        "galleryId": "9",
        "image": "805"
      },
      {
        "id": "76",
        "galleryId": "9",
        "image": "806"
      },
      {
        "id": "84",
        "galleryId": "10",
        "image": "828"
      },
      {
        "id": "85",
        "galleryId": "10",
        "image": "818"
      },
      {
        "id": "88",
        "galleryId": "10",
        "image": "818"
      },
      {
        "id": "89",
        "galleryId": "10",
        "image": "817"
      }
    ],
    "hotspots": [
      {
        "id": "1",
        "face": "BACK",
        "zoneId": "1",
        "productId": "1",
        "x": "43.82",
        "y": "8.99"
      },
      {
        "id": "9",
        "face": "LEFT",
        "zoneId": "3",
        "productId": "3",
        "x": "74.53",
        "y": "13.86"
      },
      {
        "id": "10",
        "face": "top",
        "zoneId": "3",
        "productId": "3",
        "x": "40.66",
        "y": "47.03"
      },
      {
        "id": "38",
        "face": "BACK",
        "zoneId": "11",
        "productId": "9",
        "x": "50.94",
        "y": "19.85"
      },
      {
        "id": "39",
        "face": "FRONT",
        "zoneId": "11",
        "productId": "73",
        "x": "15.36",
        "y": "20.6"
      },
      {
        "id": "40",
        "face": "LEFT",
        "zoneId": "11",
        "productId": "73",
        "x": "44.57",
        "y": "9.36"
      },
      {
        "id": "41",
        "face": "LEFT",
        "zoneId": "11",
        "productId": "9",
        "x": "73.41",
        "y": "27.34"
      },
      {
        "id": "42",
        "face": "RIGHT",
        "zoneId": "11",
        "productId": "9",
        "x": "46.44",
        "y": "27.34"
      },
      {
        "id": "43",
        "face": "RIGHT",
        "zoneId": "11",
        "productId": "73",
        "x": "61.42",
        "y": "6.37"
      },
      {
        "id": "44",
        "face": "TOP",
        "zoneId": "11",
        "productId": "73",
        "x": "29.96",
        "y": "23.22"
      },
      {
        "id": "45",
        "face": "BACK",
        "zoneId": "12",
        "productId": "53",
        "x": "30.34",
        "y": "5.24"
      },
      {
        "id": "46",
        "face": "FRONT",
        "zoneId": "12",
        "productId": "53",
        "x": "36.33",
        "y": "7.87"
      },
      {
        "id": "47",
        "face": "LEFT",
        "zoneId": "12",
        "productId": "53",
        "x": "62.92",
        "y": "23.22"
      },
      {
        "id": "48",
        "face": "RIGHT",
        "zoneId": "12",
        "productId": "53",
        "x": "55.06",
        "y": "4.12"
      },
      {
        "id": "49",
        "face": "TOP",
        "zoneId": "12",
        "productId": "53",
        "x": "34.83",
        "y": "16.1"
      },
      {
        "id": "63",
        "face": "BACK",
        "zoneId": "16",
        "productId": "13",
        "x": "25.47",
        "y": "37.45"
      },
      {
        "id": "64",
        "face": "FRONT",
        "zoneId": "16",
        "productId": "13",
        "x": "59.55",
        "y": "24.34"
      },
      {
        "id": "65",
        "face": "LEFT",
        "zoneId": "16",
        "productId": "13",
        "x": "73.78",
        "y": "12.36"
      },
      {
        "id": "66",
        "face": "RIGHT",
        "zoneId": "16",
        "productId": "13",
        "x": "7.49",
        "y": "19.48"
      },
      {
        "id": "67",
        "face": "BACK",
        "zoneId": "17",
        "productId": "73",
        "x": "44.94",
        "y": "17.6"
      },
      {
        "id": "68",
        "face": "FRONT",
        "zoneId": "17",
        "productId": "73",
        "x": "79.03",
        "y": "31.84"
      },
      {
        "id": "69",
        "face": "LEFT",
        "zoneId": "17",
        "productId": "73",
        "x": "7.12",
        "y": "6.74"
      },
      {
        "id": "85",
        "face": "BACK",
        "zoneId": "21",
        "productId": "13",
        "x": "16.1",
        "y": "34.08"
      },
      {
        "id": "86",
        "face": "FRONT",
        "zoneId": "21",
        "productId": "13",
        "x": "16.85",
        "y": "19.1"
      },
      {
        "id": "87",
        "face": "LEFT",
        "zoneId": "21",
        "productId": "13",
        "x": "23.6",
        "y": "8.24"
      },
      {
        "id": "88",
        "face": "RIGHT",
        "zoneId": "21",
        "productId": "13",
        "x": "31.84",
        "y": "27.34"
      },
      {
        "id": "89",
        "face": "BACK",
        "zoneId": "22",
        "productId": "13",
        "x": "46.32",
        "y": "38.95"
      },
      {
        "id": "90",
        "face": "RIGHT",
        "zoneId": "22",
        "productId": "13",
        "x": "57.19",
        "y": "27.37"
      },
      {
        "id": "91",
        "face": "FRONT",
        "zoneId": "22",
        "productId": "13",
        "x": "39.3",
        "y": "0"
      },
      {
        "id": "92",
        "face": "LEFT",
        "zoneId": "22",
        "productId": "14",
        "x": "6.67",
        "y": "32.63"
      },
      {
        "id": "93",
        "face": "LEFT",
        "zoneId": "22",
        "productId": "13",
        "x": "54.04",
        "y": "24.21"
      },
      {
        "id": "99",
        "face": "BACK",
        "zoneId": "24",
        "productId": "43",
        "x": "75.09",
        "y": "39.3"
      },
      {
        "id": "100",
        "face": "FRONT",
        "zoneId": "24",
        "productId": "43",
        "x": "22.46",
        "y": "31.58"
      },
      {
        "id": "101",
        "face": "LEFT",
        "zoneId": "24",
        "productId": "43",
        "x": "33.33",
        "y": "14.39"
      },
      {
        "id": "102",
        "face": "TOP",
        "zoneId": "24",
        "productId": "43",
        "x": "90.53",
        "y": "90.53"
      },
      {
        "id": "103",
        "face": "BACK",
        "zoneId": "25",
        "productId": "40",
        "x": "29.47",
        "y": "27.02"
      },
      {
        "id": "104",
        "face": "RIGHT",
        "zoneId": "25",
        "productId": "40",
        "x": "42.11",
        "y": "25.61"
      },
      {
        "id": "105",
        "face": "LEFT",
        "zoneId": "25",
        "productId": "40",
        "x": "75.09",
        "y": "34.39"
      },
      {
        "id": "106",
        "face": "TOP",
        "zoneId": "25",
        "productId": "40",
        "x": "88.77",
        "y": "16.14"
      },
      {
        "id": "107",
        "face": "BACK",
        "zoneId": "26",
        "productId": "40",
        "x": "45.61",
        "y": "35.44"
      },
      {
        "id": "108",
        "face": "LEFT",
        "zoneId": "26",
        "productId": "40",
        "x": "52.98",
        "y": "22.11"
      },
      {
        "id": "109",
        "face": "TOP",
        "zoneId": "26",
        "productId": "40",
        "x": "56.49",
        "y": "17.89"
      },
      {
        "id": "113",
        "face": "front",
        "zoneId": "27",
        "productId": "1",
        "x": "15.16",
        "y": "28.79"
      },
      {
        "id": "112",
        "face": "front",
        "zoneId": "27",
        "productId": "1",
        "x": "61.76",
        "y": "70.33"
      },
      {
        "id": "115",
        "face": "bottom",
        "zoneId": "27",
        "productId": "41",
        "x": "48.79",
        "y": "45.71"
      },
      {
        "id": "153",
        "face": "left",
        "zoneId": "37",
        "productId": "54",
        "x": "7.25",
        "y": "16.48"
      },
      {
        "id": "152",
        "face": "back",
        "zoneId": "37",
        "productId": "54",
        "x": "54.29",
        "y": "29.89"
      },
      {
        "id": "151",
        "face": "front",
        "zoneId": "37",
        "productId": "54",
        "x": "36.26",
        "y": "36.7"
      },
      {
        "id": "148",
        "face": "left",
        "zoneId": "36",
        "productId": "54",
        "x": "76.04",
        "y": "30.99"
      },
      {
        "id": "149",
        "face": "front",
        "zoneId": "36",
        "productId": "54",
        "x": "15.6",
        "y": "7.91"
      },
      {
        "id": "154",
        "face": "right",
        "zoneId": "37",
        "productId": "54",
        "x": "15.38",
        "y": "34.73"
      },
      {
        "id": "155",
        "face": "top",
        "zoneId": "37",
        "productId": "54",
        "x": "87.69",
        "y": "49.67"
      },
      {
        "id": "162",
        "face": "front",
        "zoneId": "37",
        "productId": "64",
        "x": "49.45",
        "y": "32.09"
      },
      {
        "id": "163",
        "face": "left",
        "zoneId": "37",
        "productId": "64",
        "x": "63.96",
        "y": "20.22"
      },
      {
        "id": "164",
        "face": "front",
        "zoneId": "36",
        "productId": "64",
        "x": "93.41",
        "y": "30.99"
      },
      {
        "id": "165",
        "face": "left",
        "zoneId": "36",
        "productId": "54",
        "x": "16.04",
        "y": "40.22"
      },
      {
        "id": "166",
        "face": "back",
        "zoneId": "36",
        "productId": "64",
        "x": "21.98",
        "y": "37.14"
      },
      {
        "id": "167",
        "face": "right",
        "zoneId": "36",
        "productId": "64",
        "x": "66.81",
        "y": "3.52"
      },
      {
        "id": "182",
        "face": "left",
        "zoneId": "1",
        "productId": "1",
        "x": "61.98",
        "y": "32.53"
      }
    ],
    "library": [
      {
        "id": "1",
        "src": "3bureaux.jpg",
        "hash": "123"
      },
      {
        "id": "2",
        "src": "3education.jpg",
        "hash": "123"
      },
      {
        "id": "3",
        "src": "3logistique_industrie.jpg",
        "hash": "123"
      },
      {
        "id": "4",
        "src": "3commerce.jpg",
        "hash": "123"
      },
      {
        "id": "5",
        "src": "3chr.jpg",
        "hash": "123"
      },
      {
        "id": "6",
        "src": "3musee.jpg",
        "hash": "123"
      },
      {
        "id": "7",
        "src": "3exterieur.jpg",
        "hash": "123"
      },
      {
        "id": "8",
        "src": "source1_vignette.png",
        "hash": "123"
      },
      {
        "id": "10",
        "src": "source1_image_2.jpg",
        "hash": "123"
      },
      {
        "id": "11",
        "src": "source1_image_0.jpg",
        "hash": "123"
      },
      {
        "id": "12",
        "src": "source1_image_3.jpg",
        "hash": "123"
      },
      {
        "id": "13",
        "src": "source1_image_1.jpg",
        "hash": "123"
      },
      {
        "id": "14",
        "src": "source1_image_4.jpg",
        "hash": "123"
      },
      {
        "id": "15",
        "src": "source1_image_5.jpg",
        "hash": "123"
      },
      {
        "id": "24",
        "src": "amica_reunion_2_vignette.png",
        "hash": "123"
      },
      {
        "id": "26",
        "src": "amica_salle_reunion_2_image_2.jpg",
        "hash": "123"
      },
      {
        "id": "27",
        "src": "amica_salle_reunion_2_image_0.jpg",
        "hash": "123"
      },
      {
        "id": "28",
        "src": "amica_salle_reunion_2_image_3.jpg",
        "hash": "123"
      },
      {
        "id": "29",
        "src": "amica_salle_reunion_2_image_1.jpg",
        "hash": "123"
      },
      {
        "id": "30",
        "src": "amica_salle_reunion_2_image_4.jpg",
        "hash": "123"
      },
      {
        "id": "31",
        "src": "amica_salle_reunion_2_image_5.jpg",
        "hash": "123"
      },
      {
        "id": "81",
        "src": "",
        "hash": "123"
      },
      {
        "id": "88",
        "src": "devred_nantes_vignette2.png",
        "hash": "123"
      },
      {
        "id": "90",
        "src": "devred_espace_vente_image_2.jpg",
        "hash": "123"
      },
      {
        "id": "91",
        "src": "devred_espace_vente_image_0.jpg",
        "hash": "123"
      },
      {
        "id": "92",
        "src": "devred_espace_vente_image_3.jpg",
        "hash": "123"
      },
      {
        "id": "93",
        "src": "devred_espace_vente_image_1.jpg",
        "hash": "123"
      },
      {
        "id": "94",
        "src": "devred_espace_vente_image_4.jpg",
        "hash": "123"
      },
      {
        "id": "95",
        "src": "devred_espace_vente_image_5.jpg",
        "hash": "123"
      },
      {
        "id": "97",
        "src": "",
        "hash": "123"
      },
      {
        "id": "98",
        "src": "rabaud2_image_2.jpg",
        "hash": "123"
      },
      {
        "id": "99",
        "src": "rabaud2_image_0.jpg",
        "hash": "123"
      },
      {
        "id": "100",
        "src": "rabaud2_image_3.jpg",
        "hash": "123"
      },
      {
        "id": "101",
        "src": "rabaud2_image_1.jpg",
        "hash": "123"
      },
      {
        "id": "102",
        "src": "rabaud2_image_4.jpg",
        "hash": "123"
      },
      {
        "id": "103",
        "src": "rabaud2_image_5.jpg",
        "hash": "123"
      },
      {
        "id": "113",
        "src": "",
        "hash": "123"
      },
      {
        "id": "121",
        "src": "",
        "hash": "123"
      },
      {
        "id": "128",
        "src": "quai_ouest_circulations.png",
        "hash": "123"
      },
      {
        "id": "130",
        "src": "l_equipe_bureaux_2.jpg",
        "hash": "123"
      },
      {
        "id": "131",
        "src": "l_equipe_bureaux_0.jpg",
        "hash": "123"
      },
      {
        "id": "132",
        "src": "l_equipe_bureaux_3.jpg",
        "hash": "123"
      },
      {
        "id": "133",
        "src": "l_equipe_bureaux_1.jpg",
        "hash": "123"
      },
      {
        "id": "134",
        "src": "l_equipe_bureaux_4.jpg",
        "hash": "123"
      },
      {
        "id": "135",
        "src": "l_equipe_bureaux_5.jpg",
        "hash": "123"
      },
      {
        "id": "136",
        "src": "quai_ouest_ascenseurs.png",
        "hash": "123"
      },
      {
        "id": "138",
        "src": "l_equipe_ascenseurs_2.jpg",
        "hash": "123"
      },
      {
        "id": "139",
        "src": "l_equipe_ascenseurs_0.jpg",
        "hash": "123"
      },
      {
        "id": "140",
        "src": "l_equipe_ascenseurs_3.jpg",
        "hash": "123"
      },
      {
        "id": "141",
        "src": "l_equipe_ascenseurs_1.jpg",
        "hash": "123"
      },
      {
        "id": "142",
        "src": "l_equipe_ascenseurs_4.jpg",
        "hash": "123"
      },
      {
        "id": "143",
        "src": "l_equipe_ascenseurs_5.jpg",
        "hash": "123"
      },
      {
        "id": "168",
        "src": "pompignac.png",
        "hash": "123"
      },
      {
        "id": "170",
        "src": "pompignac_01_2.jpg",
        "hash": "123"
      },
      {
        "id": "171",
        "src": "pompignac_01_0.jpg",
        "hash": "123"
      },
      {
        "id": "172",
        "src": "pompignac_01_3.jpg",
        "hash": "123"
      },
      {
        "id": "973",
        "src": "pompignac_cour_couverte.png",
        "hash": "1552643772458"
      },
      {
        "id": "186",
        "src": "pompignac_cour_couverte_2.jpg",
        "hash": "123"
      },
      {
        "id": "187",
        "src": "pompignac_cour_couverte_0.jpg",
        "hash": "123"
      },
      {
        "id": "188",
        "src": "pompignac_cour_couverte_3.jpg",
        "hash": "123"
      },
      {
        "id": "189",
        "src": "pompignac_cour_couverte_1.jpg",
        "hash": "123"
      },
      {
        "id": "190",
        "src": "pompignac_cour_couverte_4.jpg",
        "hash": "123"
      },
      {
        "id": "191",
        "src": "pompignac_cour_couverte_5.jpg",
        "hash": "123"
      },
      {
        "id": "192",
        "src": "transgourmet_froid_negatif.png",
        "hash": "123"
      },
      {
        "id": "194",
        "src": "transgourmet_froid_negatif_2.jpg",
        "hash": "123"
      },
      {
        "id": "195",
        "src": "transgourmet_froid_negatif_0.jpg",
        "hash": "123"
      },
      {
        "id": "196",
        "src": "transgourmet_froid_negatif_3.jpg",
        "hash": "123"
      },
      {
        "id": "197",
        "src": "transgourmet_froid_negatif_1.jpg",
        "hash": "123"
      },
      {
        "id": "198",
        "src": "transgourmet_froid_negatif_4.jpg",
        "hash": "123"
      },
      {
        "id": "199",
        "src": "transgourmet_froid_negatif_5.jpg",
        "hash": "123"
      },
      {
        "id": "200",
        "src": "transgourmet_stockage_froid.png",
        "hash": "123"
      },
      {
        "id": "202",
        "src": "transgourmet_3_2.jpg",
        "hash": "123"
      },
      {
        "id": "203",
        "src": "transgourmet_3_0.jpg",
        "hash": "123"
      },
      {
        "id": "204",
        "src": "transgourmet_3_3.jpg",
        "hash": "123"
      },
      {
        "id": "205",
        "src": "transgourmet_3_1.jpg",
        "hash": "123"
      },
      {
        "id": "206",
        "src": "transgourmet_3_4.jpg",
        "hash": "123"
      },
      {
        "id": "207",
        "src": "transgourmet_3_5.jpg",
        "hash": "123"
      },
      {
        "id": "208",
        "src": "galerie_musee_ste_croix_1.jpg",
        "hash": "123"
      },
      {
        "id": "209",
        "src": "galerie_musee_ste_croix_2.jpg",
        "hash": "123"
      },
      {
        "id": "210",
        "src": "galerie_musee_ste_croix_3.jpg",
        "hash": "123"
      },
      {
        "id": "211",
        "src": "galerie_musee_ste_croix_4.jpg",
        "hash": "123"
      },
      {
        "id": "214",
        "src": "galerie_education_cafeteria1.jpg",
        "hash": "123"
      },
      {
        "id": "215",
        "src": "galerie_education_ecole_guerigny1.jpg",
        "hash": "123"
      },
      {
        "id": "216",
        "src": "galerie_education_lycee_decomble1.jpg",
        "hash": "123"
      },
      {
        "id": "217",
        "src": "galerie_education_lycee_decomble2.jpg",
        "hash": "123"
      },
      {
        "id": "230",
        "src": "galerie_bureaux_amica1.jpg",
        "hash": "123"
      },
      {
        "id": "231",
        "src": "galerie_bureaux_gecina1.jpg",
        "hash": "123"
      },
      {
        "id": "232",
        "src": "galerie_bureaux_gecina2.jpg",
        "hash": "123"
      },
      {
        "id": "233",
        "src": "galerie_bureaux_gecina3.jpg",
        "hash": "123"
      },
      {
        "id": "237",
        "src": "galerie_logistique_aeroport1.jpg",
        "hash": "123"
      },
      {
        "id": "238",
        "src": "galerie_logistique_bmw1.jpg",
        "hash": "123"
      },
      {
        "id": "239",
        "src": "galerie_logistique_bmw2.jpg",
        "hash": "123"
      },
      {
        "id": "240",
        "src": "galerie_logistique_rabaud1.jpg",
        "hash": "123"
      },
      {
        "id": "241",
        "src": "galerie_chr_cafe_belgium.jpg",
        "hash": "123"
      },
      {
        "id": "242",
        "src": "galerie_chr_brasserie_stadspark.jpg",
        "hash": "123"
      },
      {
        "id": "243",
        "src": "galerie_chr_hotel_ibis_nivelles_1.jpg",
        "hash": "123"
      },
      {
        "id": "244",
        "src": "galerie_chr_dehavenvanrenesse1.jpg",
        "hash": "123"
      },
      {
        "id": "252",
        "src": "galerie_exterieur_adatto.jpg",
        "hash": "123"
      },
      {
        "id": "253",
        "src": "galerie_exterieur_aluna1.jpg",
        "hash": "123"
      },
      {
        "id": "254",
        "src": "galerie_exterieur_applique_cube.jpg",
        "hash": "123"
      },
      {
        "id": "255",
        "src": "galerie_exterieur_borne_fin.jpg",
        "hash": "123"
      },
      {
        "id": "272",
        "src": "rana_linear_led_e.jpg",
        "hash": "123"
      },
      {
        "id": "278",
        "src": "rubico_led.jpg",
        "hash": "123"
      },
      {
        "id": "281",
        "src": "rana_linear_suspendu.jpg",
        "hash": "123"
      },
      {
        "id": "290",
        "src": "mini_continuum_led.jpg",
        "hash": "123"
      },
      {
        "id": "293",
        "src": "rana_surface_led.jpg",
        "hash": "123"
      },
      {
        "id": "296",
        "src": "beacon_xxl_led.jpg",
        "hash": "123"
      },
      {
        "id": "305",
        "src": "insaver_led.jpg",
        "hash": "123"
      },
      {
        "id": "308",
        "src": "syl_lighter_led.jpg",
        "hash": "123"
      },
      {
        "id": "311",
        "src": "rana_led_encastre.jpg",
        "hash": "123"
      },
      {
        "id": "314",
        "src": "syl_line_led.jpg",
        "hash": "123"
      },
      {
        "id": "320",
        "src": "sylbatten_led.jpg",
        "hash": "123"
      },
      {
        "id": "323",
        "src": "ascent_150_II_led.jpg",
        "hash": "123"
      },
      {
        "id": "326",
        "src": "beacon_xl_led.jpg",
        "hash": "123"
      },
      {
        "id": "329",
        "src": "lumina_led.jpg",
        "hash": "123"
      },
      {
        "id": "332",
        "src": "rana_led_encastre.jpg",
        "hash": "123"
      },
      {
        "id": "335",
        "src": "start_flat_panel_led.jpg",
        "hash": "123"
      },
      {
        "id": "338",
        "src": "officelyte_concave_lp_led.jpg",
        "hash": "123"
      },
      {
        "id": "341",
        "src": "clio_led.jpg",
        "hash": "123"
      },
      {
        "id": "344",
        "src": "giotto_led_microwave.jpg",
        "hash": "123"
      },
      {
        "id": "347",
        "src": "sylcircle_led.jpg",
        "hash": "123"
      },
      {
        "id": "350",
        "src": "deltawing_led.jpg",
        "hash": "123"
      },
      {
        "id": "356",
        "src": "myriad_v_suspension_led1.jpg",
        "hash": "1536242567222"
      },
      {
        "id": "362",
        "src": "nephos_led_v2_noire.jpg",
        "hash": "123"
      },
      {
        "id": "365",
        "src": "inverto_direct_indirect_led.jpg",
        "hash": "123"
      },
      {
        "id": "371",
        "src": "beacon_led_shelf.jpg",
        "hash": "123"
      },
      {
        "id": "374",
        "src": "beacon_led_accent.jpg",
        "hash": "123"
      },
      {
        "id": "938",
        "src": "fc_expospot.pdf",
        "hash": "1542984035350"
      },
      {
        "id": "380",
        "src": "myriad_v_rond_led.jpg",
        "hash": "123"
      },
      {
        "id": "383",
        "src": "toledo_vintage_g120.jpg",
        "hash": "123"
      },
      {
        "id": "389",
        "src": "sylbay_led.jpg",
        "hash": "123"
      },
      {
        "id": "398",
        "src": "inverto_montage_en_saillie.jpg",
        "hash": "123"
      },
      {
        "id": "401",
        "src": "cube_9_led_plafonnier.jpg",
        "hash": "123"
      },
      {
        "id": "404",
        "src": "scope_c2_plafonnier.jpg",
        "hash": "123"
      },
      {
        "id": "456",
        "src": "rana_linear_led_e.jpg",
        "hash": "1532528372118"
      },
      {
        "id": "462",
        "src": "rana_linear_suspendu.jpg",
        "hash": "1532528683486"
      },
      {
        "id": "936",
        "src": "ascent_100_arch_II.jpg",
        "hash": "1542981491660"
      },
      {
        "id": "935",
        "src": "fc_ascent_100_II.pdf",
        "hash": "1542981514810"
      },
      {
        "id": "945",
        "src": "fc_rubico_led_gen3.pdf",
        "hash": "1542989414430"
      },
      {
        "id": "471",
        "src": "fc_beacon_XXL.pdf",
        "hash": "1532529274429"
      },
      {
        "id": "472",
        "src": "beacon_xxl_led.jpg",
        "hash": "1532529259493"
      },
      {
        "id": "942",
        "src": "fc_rana_linear_2018.pdf",
        "hash": "1542986386664"
      },
      {
        "id": "941",
        "src": "fc_rana_linear_2018.pdf",
        "hash": "1542986358621"
      },
      {
        "id": "477",
        "src": "fc_syl_lighter_led_ii.pdf",
        "hash": "1532529445729"
      },
      {
        "id": "478",
        "src": "syl_lighter_led.jpg",
        "hash": "1532529432804"
      },
      {
        "id": "876",
        "src": "start_waterproof_led_g3.jpg",
        "hash": "1539251746087"
      },
      {
        "id": "946",
        "src": "rubico_led.jpg",
        "hash": "1542989379523"
      },
      {
        "id": "951",
        "src": "fc_mini_continuum_2018.pdf",
        "hash": "1542991122245"
      },
      {
        "id": "508",
        "src": "fc_start_batten_led_ip65.pdf",
        "hash": "1532530216596"
      },
      {
        "id": "509",
        "src": "start_batten_led_link_mono_ip65.jpg",
        "hash": "1532530200171"
      },
      {
        "id": "940",
        "src": "expospot.jpg",
        "hash": "1542984115930"
      },
      {
        "id": "939",
        "src": "expospot.jpg",
        "hash": "1542984007354"
      },
      {
        "id": "937",
        "src": "ascent_100_arch_II.jpg",
        "hash": "1542981574573"
      },
      {
        "id": "534",
        "src": "fc_sylbay_led.pdf",
        "hash": "1532530718750"
      },
      {
        "id": "535",
        "src": "sylbay_led.jpg",
        "hash": "1532530704417"
      },
      {
        "id": "536",
        "src": "fc_inverto_montage_en_saillie.pdf",
        "hash": "1532530886342"
      },
      {
        "id": "537",
        "src": "inverto_montage_en_saillie.jpg",
        "hash": "1532530872778"
      },
      {
        "id": "555",
        "src": "fc_start_highbay_eco.pdf",
        "hash": "1532531332742"
      },
      {
        "id": "556",
        "src": "start_highbay_eco.jpg",
        "hash": "1532531320127"
      },
      {
        "id": "557",
        "src": "fc_start_highbay_he.pdf",
        "hash": "1532531373060"
      },
      {
        "id": "558",
        "src": "start_highbay_he.jpg",
        "hash": "1532531350463"
      },
      {
        "id": "559",
        "src": "fc_start_track_spot.pdf",
        "hash": "1532531408418"
      },
      {
        "id": "560",
        "src": "start_track_spot.jpg",
        "hash": "1532531395513"
      },
      {
        "id": "594",
        "src": "start_highbay_eco.jpg",
        "hash": "1532591659559"
      },
      {
        "id": "595",
        "src": "start_highbay_he.jpg",
        "hash": "1532591693218"
      },
      {
        "id": "596",
        "src": "start_track_spot.jpg",
        "hash": "1532591748212"
      },
      {
        "id": "599",
        "src": "start_waterproof_housing.jpg",
        "hash": "1532591876333"
      },
      {
        "id": "806",
        "src": "boutique_om2.jpg",
        "hash": "1536479627057"
      },
      {
        "id": "805",
        "src": "devred1.jpg",
        "hash": "1536479619284"
      },
      {
        "id": "804",
        "src": "marmon_sports1.jpg",
        "hash": "1536479355915"
      },
      {
        "id": "803",
        "src": "bmw_moto.jpg",
        "hash": "1536479139717"
      },
      {
        "id": "802",
        "src": "marmon_sports1.jpg",
        "hash": "1536478758424"
      },
      {
        "id": "801",
        "src": "marmon_sports3.jpg",
        "hash": "1536478745999"
      },
      {
        "id": "800",
        "src": "devred2.jpg",
        "hash": "1536478729427"
      },
      {
        "id": "799",
        "src": "boutique_om2.jpg",
        "hash": "1536478717022"
      },
      {
        "id": "798",
        "src": "bmw_moto.jpg",
        "hash": "1536478702433"
      },
      {
        "id": "797",
        "src": "boutique_om1.jpg",
        "hash": "1536478688102"
      },
      {
        "id": "725",
        "src": "marmon_2_vignette.png",
        "hash": "1535990251082"
      },
      {
        "id": "796",
        "src": "marmon_sports4.jpg",
        "hash": "1536478670319"
      },
      {
        "id": "795",
        "src": "bmw_moto2.jpg",
        "hash": "1536478659948"
      },
      {
        "id": "794",
        "src": "devred1.jpg",
        "hash": "1536478647718"
      },
      {
        "id": "732",
        "src": "marmon_2_2.jpg",
        "hash": "1535990538504"
      },
      {
        "id": "733",
        "src": "marmon_2_0.jpg",
        "hash": "1535990565031"
      },
      {
        "id": "734",
        "src": "marmon_2_3.jpg",
        "hash": "1535990571751"
      },
      {
        "id": "735",
        "src": "marmon_2_1.jpg",
        "hash": "1535990575435"
      },
      {
        "id": "736",
        "src": "marmon_2_4.jpg",
        "hash": "1535990580574"
      },
      {
        "id": "737",
        "src": "marmon_2_5.jpg",
        "hash": "1535990585874"
      },
      {
        "id": "738",
        "src": "marmon_1_vignette.png",
        "hash": "1535991196520"
      },
      {
        "id": "739",
        "src": "marmon_1_2.jpg",
        "hash": "1535991240127"
      },
      {
        "id": "740",
        "src": "marmon_1_0.jpg",
        "hash": "1535991243340"
      },
      {
        "id": "741",
        "src": "marmon_1_3.jpg",
        "hash": "1535991246406"
      },
      {
        "id": "742",
        "src": "marmon_1_1.jpg",
        "hash": "1535991249016"
      },
      {
        "id": "743",
        "src": "marmon_1_4.jpg",
        "hash": "1535991252199"
      },
      {
        "id": "744",
        "src": "marmon_1_5.jpg",
        "hash": "1535991256216"
      },
      {
        "id": "784",
        "src": "global_trac_pro_3m.jpg",
        "hash": "1536235049945"
      },
      {
        "id": "783",
        "src": "fc_global_trac.pdf",
        "hash": "1536235172725"
      },
      {
        "id": "870",
        "src": "fc_pixer.pdf",
        "hash": "1538126203773"
      },
      {
        "id": "773",
        "src": "optix_r_600_alu.jpg",
        "hash": "1536141407116"
      },
      {
        "id": "775",
        "src": "pixer_r_600_HE.jpg",
        "hash": "1536141826174"
      },
      {
        "id": "782",
        "src": "global_trac_pro_3m.jpg",
        "hash": "1536235031075"
      },
      {
        "id": "779",
        "src": "kometa_300.jpg",
        "hash": "1536146928579"
      },
      {
        "id": "974",
        "src": "rabaud_atelier_vignette.png",
        "hash": "1552643837364"
      },
      {
        "id": "952",
        "src": "mini_continuum_led.jpg",
        "hash": "1542991072900"
      }
    ],
    "products": [
      {
        "id": "1",
        "rangeId": "2",
        "zoneId": "0",
        "titleRef": "Rana LINEAR LED encastré",
        "descRef": "Ligne continue LED à encastrer. Flux lumineux uniforme. UGR<19 pour un faible éblouissement (version prismatique + alu. satiné)",
        "pdfSheet": "941",
        "image": "272",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "70",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "3",
        "rangeId": "2",
        "zoneId": "0",
        "titleRef": "Rubico LED Gen3",
        "descRef": "La qualité qui a fait la réputation de la famille Rubico, avec encore plus d'efficacité : voici Rubico LED G3, un luminaire\narchitectural simple à installer",
        "pdfSheet": "945",
        "image": "278",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "179",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "4",
        "rangeId": "4",
        "zoneId": "0",
        "titleRef": "Rana LINEAR LED suspendu",
        "descRef": "RANA Linear LED est une gamme de luminaires LED ultra fins, épurés et discrets. Les modules peuvent être montés individuellement ou en ligne continue.",
        "pdfSheet": "941",
        "image": "281",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "70",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "72",
        "rangeId": "5",
        "zoneId": "0",
        "titleRef": "Ascent 100 II",
        "descRef": "Downlight à LED de forte puissance - alliant confort visuel et performances élevées",
        "pdfSheet": "935",
        "image": "937",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "176",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "73",
        "rangeId": "5",
        "zoneId": "0",
        "titleRef": "Expospot",
        "descRef": "Expospot est un spot modulaire qui peut être orienté ou réglé selon différents angles afin d'apporter un éclairage de grande qualité.",
        "pdfSheet": "938",
        "image": "940",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "177",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "7",
        "rangeId": "17",
        "zoneId": "0",
        "titleRef": "Mini Continuum LED",
        "descRef": "Solution d'éclairage offrant des lignes lumineuses continues uniformes sur les murs et les plafonds. Disponible en version directe/indirecte.",
        "pdfSheet": "951",
        "image": "290",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "182",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "8",
        "rangeId": "4",
        "zoneId": "0",
        "titleRef": "Rana LED Gen 2 suspendu",
        "descRef": "Solution éco-performante pour l’éclairage général de multiples applications. Il assure un éclairage de grande qualité, avec un grand confort visuel.",
        "pdfSheet": "943",
        "image": "293",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "178",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "9",
        "rangeId": "11",
        "zoneId": "0",
        "titleRef": "Beacon XXL LED",
        "descRef": "Solution LED 48W en remplacement des projecteurs traditionnels 70W CMI. Montage sur rail 3 allumages (Global Trac Pro 3 circuits). ",
        "pdfSheet": "471",
        "image": "296",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "78",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "12",
        "rangeId": "5",
        "zoneId": "0",
        "titleRef": "Insaver LED II",
        "descRef": "Downlight LED design et innovant. Nouveau système d'installation : montage simple et rapide. Reflecteur segmenté pour une meilleure efficacité. UGR<19",
        "pdfSheet": "475",
        "image": "305",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "80",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "13",
        "rangeId": "5",
        "zoneId": "0",
        "titleRef": "Syl Lighter LED II",
        "descRef": "Nouvelle génération de downlights LED pour le remplacement des solutions fluo-compactes traditionnelles. Solution facile et rapide à installer.",
        "pdfSheet": "477",
        "image": "308",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "81",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "14",
        "rangeId": "2",
        "zoneId": "0",
        "titleRef": "Rana LED encastré Gen 2",
        "descRef": "Solution éco-performante en version encastrée pour l’éclairage général d'applications variées. Eclairage de grande qualité et grand confort visuel.",
        "pdfSheet": "943",
        "image": "311",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "178",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "15",
        "rangeId": "17",
        "zoneId": "0",
        "titleRef": "Syl-Line LED",
        "descRef": "Gamme de lignes continues LED modulables. Montage en saillie ou suspendu. Solution parfaite pour les rénovations et les nouvelles applications. ",
        "pdfSheet": "483",
        "image": "314",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "84",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "67",
        "rangeId": "12",
        "zoneId": "0",
        "titleRef": "Start Waterproof Led Gen 3",
        "descRef": "Gamme de luminaires étanches avec un diffuseur en polycarbonate anti-uv offrant une protection IP65 contre les projections d’eau et la poussière.",
        "pdfSheet": "874",
        "image": "876",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "170",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "17",
        "rangeId": "14",
        "zoneId": "0",
        "titleRef": "Sylbatten LED",
        "descRef": "Gamme de réglettes à LED en remplacement des réglettes T8 traditionnelles (600 mm, 1 200 mm et 1 500 mm). Idéale pour les grands espaces industriels.",
        "pdfSheet": "485",
        "image": "320",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "85",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "18",
        "rangeId": "5",
        "zoneId": "0",
        "titleRef": "Ascent 150 LED",
        "descRef": "Cette nouvelle génération incorpore un module LED à très haute efficacité ainsi qu’une version à faible éblouissement adaptée aux espaces de bureaux. ",
        "pdfSheet": "490",
        "image": "323",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "87",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "19",
        "rangeId": "11",
        "zoneId": "0",
        "titleRef": "Beacon XL LED",
        "descRef": "Une gamme qui sublime les produits sur présentoirs ou en vitrine. Montage sur rail 3 allumages (Global Trac Pro 3 circuits). ",
        "pdfSheet": "488",
        "image": "326",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "86",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "20",
        "rangeId": "9",
        "zoneId": "0",
        "titleRef": "Lumina LED",
        "descRef": "Gamme d’appliques murales décoratives, destinées à l’éclairage décoratif et d’ambiance dans les Cafés, Hôtels ou Restaurants, et Résidentiel.",
        "pdfSheet": "492",
        "image": "329",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "88",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "21",
        "rangeId": "2",
        "zoneId": "0",
        "titleRef": "Rana LED Gen 2 SSH",
        "descRef": "Equipés du système de gestion d'éclairage intelligent SylSmart SSH permettant d'optimiser les économies d'énergie et le confort des utilisateurs. ",
        "pdfSheet": "943",
        "image": "332",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "178",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "22",
        "rangeId": "3",
        "zoneId": "0",
        "titleRef": "Start Flat Panel LED",
        "descRef": "Idéale pour l’éclairage des bureaux, espaces de détente, zones de circulation ou salles de\nréunion, la nouvelle génération Start Flat Panel LED est une solution performante parfaite pour les rénovations ou les constructions neuves.",
        "pdfSheet": "949",
        "image": "335",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "181",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "23",
        "rangeId": "2",
        "zoneId": "0",
        "titleRef": "Officelyte Concave Low Profile LED",
        "descRef": "Concord OfficeLyte\nest une solution parfaite pour les bureaux, les écoles et les salles de réunion. Offrant jusqu'à 4 570 lm de flux et 148 lm/W d'efficacité, il est également très économe.",
        "pdfSheet": "947",
        "image": "338",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "180",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "24",
        "rangeId": "7",
        "zoneId": "0",
        "titleRef": "Clio LED",
        "descRef": "Gamme de luminaires LED d'ambiance pour montage en applique ou plafonnier. Convient aux couloirs, espaces de circulation, vestaires et zones humides. ",
        "pdfSheet": "500",
        "image": "341",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "92",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "25",
        "rangeId": "7",
        "zoneId": "0",
        "titleRef": "Giotto LED",
        "descRef": "Hublot robuste, rapide à installer. Muni d'une cellule de détection par hyperfréquences pour un contrôle et une programmation aisée de l'installation.",
        "pdfSheet": "502",
        "image": "344",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "93",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "26",
        "rangeId": "7",
        "zoneId": "0",
        "titleRef": "SylCircle LED",
        "descRef": "Luminaire LED destiné aux couloirs, cuisines, et zones humides. Comprend des modèles à capteur de présence pour encore plus d'économies d'énergie. ",
        "pdfSheet": "504",
        "image": "347",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "94",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "27",
        "rangeId": "4",
        "zoneId": "0",
        "titleRef": "Deltawing LED",
        "descRef": "Luminaire IP44 à installer en saillie ou suspendu. Disponible avec détecteur de mouvement hyperfréquences pour une gestion optimisée de l'éclairage. ",
        "pdfSheet": "506",
        "image": "350",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "95",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "29",
        "rangeId": "8",
        "zoneId": "0",
        "titleRef": "Myriad V suspension LED",
        "descRef": "Suspension décorative à LED haute performance avec driver encastré ou apparent. Existe en blanc, noir ou argent. Version dimmable et DALI en standard.",
        "pdfSheet": "510",
        "image": "356",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "97",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "31",
        "rangeId": "8",
        "zoneId": "0",
        "titleRef": "Nephos LED",
        "descRef": "Suspension LED au design contemporain destinée aux espaces de réception. Existe dans une finition brillante noire ou blanche de haute qualité.",
        "pdfSheet": "514",
        "image": "362",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "99",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "32",
        "rangeId": "9",
        "zoneId": "0",
        "titleRef": "Inverto direct/indirect LED",
        "descRef": "Luminaire LED direct/indirect pour application intérieure ou extérieure. 4 coloris standard : noir brillant, blanc mat, argent satiné, rouille mat. ",
        "pdfSheet": "516",
        "image": "365",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "100",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "34",
        "rangeId": "11",
        "zoneId": "0",
        "titleRef": "Beacon LED Shelf ",
        "descRef": "Conçu pour la mise en valeur de vitrines pour les commerces. Montage sur rail 3 allumages (Global Trac Pro 3 circuits).",
        "pdfSheet": "520",
        "image": "371",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "102",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "35",
        "rangeId": "11",
        "zoneId": "0",
        "titleRef": "Beacon LED Accent",
        "descRef": "Cette gamme de spots LED s'adresse particulièrement aux musées, galeries et commerces haut de gamme ou le rendu des couleurs joue un rôle essentiel.",
        "pdfSheet": "528",
        "image": "374",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "106",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "37",
        "rangeId": "6",
        "zoneId": "0",
        "titleRef": "Myriad V LED",
        "descRef": "Modules encastrés à LED offrant une haute efficacité et un haut rendement. Option à variateur. IP65 pour versions à collerette blanche ou chromée.",
        "pdfSheet": "524",
        "image": "380",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "104",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "38",
        "rangeId": "1",
        "zoneId": "0",
        "titleRef": "ToLEDo™ Vintage",
        "descRef": "La nouvelle gamme de lampes Sylvania ToLEDo™ Vintage est la première gamme qui permet de recréer l’effet lumineux des lampes incandescentes vintages.",
        "pdfSheet": "923",
        "image": "383",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "172",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "40",
        "rangeId": "13",
        "zoneId": "0",
        "titleRef": "Sylbay LED",
        "descRef": "Armature industrielle offrant le meilleur compromis entre design et fonctionnalité. Idéal pour entrepôts de grande hauteur, usines et hypermarchés. ",
        "pdfSheet": "534",
        "image": "389",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "109",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "43",
        "rangeId": "9",
        "zoneId": "0",
        "titleRef": "Inverto montage en saillie",
        "descRef": "4 coloris standards: noir, blanc, argent, rouille. Disponible en blanc chaud et blanc froid. Possibilité de montage en boucle.",
        "pdfSheet": "536",
        "image": "398",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "110",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "44",
        "rangeId": "16",
        "zoneId": "0",
        "titleRef": "Cube plafonnier",
        "descRef": "Choix entre 2 témpératures de couleur et 2 ou 3 types de faisceaux selon modèles. Résistant au test au brouillard salin. Durée de vie 50 000 heures",
        "pdfSheet": "539",
        "image": "401",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "111",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "45",
        "rangeId": "16",
        "zoneId": "26",
        "titleRef": "Scope plafonnier",
        "descRef": "Simplicité de de design et facilité d'installation. Choix entre plusieurs faisceux et températures de couleur. Corps en aluminium moulé anticorrosion",
        "pdfSheet": "541",
        "image": "404",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "113",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "52",
        "rangeId": "13",
        "zoneId": "0",
        "titleRef": "Start Highbay Eco",
        "descRef": "Armature industrielle haute efficacité, durable et éco-performante. ",
        "pdfSheet": "555",
        "image": "594",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "119",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "53",
        "rangeId": "13",
        "zoneId": "0",
        "titleRef": "Start Highbay HE",
        "descRef": "Start Highbay Haute Efficacité, une gamme conçue pour l'éclairage de grande hauteur. ",
        "pdfSheet": "557",
        "image": "595",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "120",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "54",
        "rangeId": "11",
        "zoneId": "0",
        "titleRef": "Start Track Spot",
        "descRef": "Spot LED au design original destiné à l'éclairage de commerces. La gamme se décline en 2 versions, petit et grand modèle et en 2 puissances.",
        "pdfSheet": "559",
        "image": "596",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "121",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "57",
        "rangeId": "12",
        "zoneId": "0",
        "titleRef": "Start Waterproof Housing",
        "descRef": "Etanche en polycarbonate destiné à être équipé de tubes LED. Gamme compatible avec les tubes LED ToLEDo™ V4 Sylvania 20W et 27W",
        "pdfSheet": "565",
        "image": "599",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "124",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "61",
        "rangeId": "2",
        "zoneId": "0",
        "titleRef": "Optix encastré 600",
        "descRef": "L’optique composé d’alvéoles\nrappelle l’esthétique naturelle des ruches d’abeille apportant une touche de douceur à l’environnement. Elle est équipée de réflecteurs haute performance anti–éblouissement avec diffuseurs et réflecteurs optimisés.",
        "pdfSheet": "871",
        "image": "773",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "147",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "63",
        "rangeId": "5",
        "zoneId": "0",
        "titleRef": "Kometa 300",
        "descRef": "Kometa 300 combine lumière d’ambiance et downlight en un seul luminaire à l’élégance unique. Son excellente polyvalence lui permet de répondre à de nombreux besoins en couloirs, espaces de bureaux ou salles d’étude.",
        "pdfSheet": "777",
        "image": "779",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "149",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      },
      {
        "id": "64",
        "rangeId": "11",
        "zoneId": "0",
        "titleRef": "Rail Global Trac Pro",
        "descRef": "Rail 3 allumages disponible de 1m à 4m. Existe en version suspendue ou encastrée, en noir ou blanc. Idéal pour les applications commerces. ",
        "pdfSheet": "0",
        "image": "782",
        "video": "0",
        "videoURL": "",
        "fk_mediaFicheProduit": "0",
        "fk_mediaVideo": "0",
        "published": "1",
        "new": "0"
      }
    ],
    "ranges": [
      {
        "id": "1",
        "titleRef": "Lampes LED"
      },
      {
        "id": "2",
        "titleRef": "Encastrés de bureaux"
      },
      {
        "id": "3",
        "titleRef": "Dalles lumineuses"
      },
      {
        "id": "4",
        "titleRef": "Suspensions et plafonniers"
      },
      {
        "id": "5",
        "titleRef": "Downlights"
      },
      {
        "id": "6",
        "titleRef": "Spots encastrés"
      },
      {
        "id": "7",
        "titleRef": "Hublots"
      },
      {
        "id": "8",
        "titleRef": "Suspensions décoratives"
      },
      {
        "id": "9",
        "titleRef": "Appliques"
      },
      {
        "id": "10",
        "titleRef": "Bandeaux lumineux"
      },
      {
        "id": "11",
        "titleRef": "Projecteurs et rails"
      },
      {
        "id": "12",
        "titleRef": "Etanches"
      },
      {
        "id": "13",
        "titleRef": "Armatures industrielles"
      },
      {
        "id": "14",
        "titleRef": "Réglettes"
      },
      {
        "id": "16",
        "titleRef": "Eclairage extérieur et architectural"
      },
      {
        "id": "17",
        "titleRef": "Lignes continues"
      }
    ],
    "zones": [
      {
        "id": "3",
        "sort": "6",
        "applicationId": "1",
        "thumb": "24",
        "projectRef": "Amica",
        "peopleRef": "Maître d’ouvrage : AMICA \r\nBureau d’Etude : INEX \r\nInstallateur : AMICA",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "26",
        "img3DBack": "27",
        "img3DLeft": "28",
        "img3DRight": "29",
        "img3DTop": "30",
        "img3DBottom": "31",
        "titleRef": "Salle de réunion"
      },
      {
        "id": "11",
        "sort": "2",
        "applicationId": "4",
        "thumb": "88",
        "projectRef": "Devred",
        "peopleRef": "a",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "90",
        "img3DBack": "91",
        "img3DLeft": "92",
        "img3DRight": "93",
        "img3DTop": "94",
        "img3DBottom": "95",
        "titleRef": "Espace de vente"
      },
      {
        "id": "12",
        "sort": "2",
        "applicationId": "3",
        "thumb": "974",
        "projectRef": "Société Rabaud",
        "peopleRef": "Bureau d'études : AREA \r\nArchitecte : Quadra Nantes \r\nInstallateur : BLI La Roche s/ Yon",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "98",
        "img3DBack": "99",
        "img3DLeft": "100",
        "img3DRight": "101",
        "img3DTop": "102",
        "img3DBottom": "103",
        "titleRef": "Atelier"
      },
      {
        "id": "22",
        "sort": "2",
        "applicationId": "2",
        "thumb": "168",
        "projectRef": "Ecole Maternelle de Pompignac",
        "peopleRef": "Maître d'ouvrage : Commune de Pompignac \r\nBureau d'études : SECOTRAP \r\nArchitecte : Architecture Marc Ballay \r\nInstallateur : CEME",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "170",
        "img3DBack": "171",
        "img3DLeft": "172",
        "img3DRight": "173",
        "img3DTop": "174",
        "img3DBottom": "175",
        "titleRef": "Salle de classe"
      },
      {
        "id": "1",
        "sort": "1",
        "applicationId": "2",
        "thumb": "8",
        "projectRef": "Médiathèque du Bouscat",
        "peopleRef": "Maître d'ouvrage : Commune du Bouscat\nAtelier d'architecture : King Kong\nBureau d'études : Artelia Installateur : Cimea",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "10",
        "img3DBack": "11",
        "img3DLeft": "12",
        "img3DRight": "13",
        "img3DTop": "14",
        "img3DBottom": "15",
        "titleRef": "Salle de lecture"
      },
      {
        "id": "16",
        "sort": "1",
        "applicationId": "1",
        "thumb": "128",
        "projectRef": "Groupe Amaury/L'Equipe",
        "peopleRef": "Bureau d'études : Artelia\r\nMaître d'oeuvre : BOUYGUES RENOVATION PRIVEE \r\nArchitecte : BRENAC & GONZALEZ \r\nInstallateur : BTB",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "130",
        "img3DBack": "131",
        "img3DLeft": "132",
        "img3DRight": "133",
        "img3DTop": "134",
        "img3DBottom": "135",
        "titleRef": "Circulations"
      },
      {
        "id": "25",
        "sort": "1",
        "applicationId": "3",
        "thumb": "192",
        "projectRef": "Transgourmet Velles",
        "peopleRef": "Maître d'ouvrage : TransGourmet \r\nBureau d'études : Sylvania \r\nInstallateur : Vinci-Cegelec",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "194",
        "img3DBack": "195",
        "img3DLeft": "196",
        "img3DRight": "197",
        "img3DTop": "198",
        "img3DBottom": "199",
        "titleRef": "Stockage froid négatif"
      },
      {
        "id": "36",
        "sort": "1",
        "applicationId": "4",
        "thumb": "725",
        "projectRef": "Marmon Sports",
        "peopleRef": "Maître d'oeuvre : BDS SPORT\n",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "732",
        "img3DBack": "733",
        "img3DLeft": "734",
        "img3DRight": "735",
        "img3DTop": "736",
        "img3DBottom": "737",
        "titleRef": "Espace de vente"
      },
      {
        "id": "17",
        "sort": "0",
        "applicationId": "1",
        "thumb": "136",
        "projectRef": "Groupe Amaury/L'Equipe",
        "peopleRef": "Bureau d'études : Artelia \nMaître d'oeuvre : BOUYGUES RENOVATION PRIVEE \nArchitecte : BRENAC & GONZALEZ \nInstallateur : BTB",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "138",
        "img3DBack": "139",
        "img3DLeft": "140",
        "img3DRight": "141",
        "img3DTop": "142",
        "img3DBottom": "143",
        "titleRef": "Ascenseurs"
      },
      {
        "id": "24",
        "sort": "0",
        "applicationId": "2",
        "thumb": "973",
        "projectRef": "Ecole Maternelle de Pompignac",
        "peopleRef": "Maître d'ouvrage : Commune de Pompignac \r\nBureau d'études : SECOTRAP \r\nArchitecte : Architecture Marc Ballay \r\nInstallateur : CEME",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "186",
        "img3DBack": "187",
        "img3DLeft": "188",
        "img3DRight": "189",
        "img3DTop": "190",
        "img3DBottom": "191",
        "titleRef": "Cour couverte"
      },
      {
        "id": "26",
        "sort": "0",
        "applicationId": "3",
        "thumb": "200",
        "projectRef": "Transgourmet Velles",
        "peopleRef": "Maître d'ouvrage : TransGourmet \r\nBureau d'études : Sylvania \r\nInstallateur : Vinci-Cegelec",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "202",
        "img3DBack": "203",
        "img3DLeft": "204",
        "img3DRight": "205",
        "img3DTop": "206",
        "img3DBottom": "207",
        "titleRef": "Stockage froid"
      },
      {
        "id": "37",
        "sort": "0",
        "applicationId": "4",
        "thumb": "738",
        "projectRef": "Marmon Sports",
        "peopleRef": "Maître d'oeuvre : BDS SPORTS\n",
        "has3d": "1",
        "img2D": "0",
        "img3DFront": "739",
        "img3DBack": "740",
        "img3DLeft": "741",
        "img3DRight": "742",
        "img3DTop": "743",
        "img3DBottom": "744",
        "titleRef": "Rayonnages"
      }
    ],
    "crosseling": [
      {
        "pid_crosselling": "1",
        "fk_produitCross": "72",
        "fk_hotspots": "67"
      },
      {
        "pid_crosselling": "2",
        "fk_produitCross": "72",
        "fk_hotspots": "68"
      },
      {
        "pid_crosselling": "3",
        "fk_produitCross": "72",
        "fk_hotspots": "69"
      },
      {
        "pid_crosselling": "4",
        "fk_produitCross": "18",
        "fk_hotspots": "67"
      },
      {
        "pid_crosselling": "5",
        "fk_produitCross": "19",
        "fk_hotspots": "63"
      },
      {
        "pid_crosselling": "6",
        "fk_produitCross": "34",
        "fk_hotspots": "64"
      },
      {
        "pid_crosselling": "7",
        "fk_produitCross": "9",
        "fk_hotspots": "63"
      },
      {
        "pid_crosselling": "8",
        "fk_produitCross": "44",
        "fk_hotspots": "63"
      },
      {
        "pid_crosselling": "9",
        "fk_produitCross": "9",
        "fk_hotspots": "64"
      },
      {
        "pid_crosselling": "10",
        "fk_produitCross": "34",
        "fk_hotspots": "64"
      },
      {
        "pid_crosselling": "11",
        "fk_produitCross": "35",
        "fk_hotspots": "65"
      },
      {
        "pid_crosselling": "12",
        "fk_produitCross": "18",
        "fk_hotspots": "65"
      },
      {
        "pid_crosselling": "13",
        "fk_produitCross": "72",
        "fk_hotspots": "65"
      },
      {
        "pid_crosselling": "14",
        "fk_produitCross": "72",
        "fk_hotspots": "66"
      },
      {
        "pid_crosselling": "15",
        "fk_produitCross": "18",
        "fk_hotspots": "66"
      },
      {
        "pid_crosselling": "16",
        "fk_produitCross": "35",
        "fk_hotspots": "66"
      },
      {
        "pid_crosselling": "17",
        "fk_produitCross": "72",
        "fk_hotspots": "9"
      },
      {
        "pid_crosselling": "18",
        "fk_produitCross": "18",
        "fk_hotspots": "9"
      },
      {
        "pid_crosselling": "19",
        "fk_produitCross": "35",
        "fk_hotspots": "9"
      },
      {
        "pid_crosselling": "20",
        "fk_produitCross": "72",
        "fk_hotspots": "10"
      },
      {
        "pid_crosselling": "21",
        "fk_produitCross": "18",
        "fk_hotspots": "10"
      },
      {
        "pid_crosselling": "22",
        "fk_produitCross": "35",
        "fk_hotspots": "10"
      },
      {
        "pid_crosselling": "23",
        "fk_produitCross": "72",
        "fk_hotspots": "99"
      },
      {
        "pid_crosselling": "24",
        "fk_produitCross": "18",
        "fk_hotspots": "99"
      },
      {
        "pid_crosselling": "25",
        "fk_produitCross": "35",
        "fk_hotspots": "99"
      },
      {
        "pid_crosselling": "26",
        "fk_produitCross": "72",
        "fk_hotspots": "100"
      },
      {
        "pid_crosselling": "27",
        "fk_produitCross": "18",
        "fk_hotspots": "100"
      },
      {
        "pid_crosselling": "28",
        "fk_produitCross": "35",
        "fk_hotspots": "100"
      },
      {
        "pid_crosselling": "29",
        "fk_produitCross": "72",
        "fk_hotspots": "101"
      },
      {
        "pid_crosselling": "30",
        "fk_produitCross": "18",
        "fk_hotspots": "101"
      },
      {
        "pid_crosselling": "31",
        "fk_produitCross": "35",
        "fk_hotspots": "101"
      },
      {
        "pid_crosselling": "32",
        "fk_produitCross": "15",
        "fk_hotspots": "102"
      },
      {
        "pid_crosselling": "33",
        "fk_produitCross": "20",
        "fk_hotspots": "102"
      },
      {
        "pid_crosselling": "34",
        "fk_produitCross": "72",
        "fk_hotspots": "102"
      },
      {
        "pid_crosselling": "35",
        "fk_produitCross": "63",
        "fk_hotspots": "1"
      },
      {
        "pid_crosselling": "36",
        "fk_produitCross": "23",
        "fk_hotspots": "1"
      },
      {
        "pid_crosselling": "37",
        "fk_produitCross": "73",
        "fk_hotspots": "1"
      },
      {
        "pid_crosselling": "38",
        "fk_produitCross": "27",
        "fk_hotspots": "182"
      },
      {
        "pid_crosselling": "39",
        "fk_produitCross": "18",
        "fk_hotspots": "182"
      },
      {
        "pid_crosselling": "40",
        "fk_produitCross": "52",
        "fk_hotspots": "182"
      },
      {
        "pid_crosselling": "41",
        "fk_produitCross": "25",
        "fk_hotspots": "89"
      },
      {
        "pid_crosselling": "42",
        "fk_produitCross": "29",
        "fk_hotspots": "89"
      },
      {
        "pid_crosselling": "43",
        "fk_produitCross": "1",
        "fk_hotspots": "89"
      },
      {
        "pid_crosselling": "44",
        "fk_produitCross": "24",
        "fk_hotspots": "90"
      },
      {
        "pid_crosselling": "45",
        "fk_produitCross": "29",
        "fk_hotspots": "90"
      },
      {
        "pid_crosselling": "46",
        "fk_produitCross": "53",
        "fk_hotspots": "90"
      },
      {
        "pid_crosselling": "47",
        "fk_produitCross": "12",
        "fk_hotspots": "91"
      },
      {
        "pid_crosselling": "48",
        "fk_produitCross": "4",
        "fk_hotspots": "91"
      },
      {
        "pid_crosselling": "49",
        "fk_produitCross": "13",
        "fk_hotspots": "91"
      },
      {
        "pid_crosselling": "50",
        "fk_produitCross": "73",
        "fk_hotspots": "92"
      },
      {
        "pid_crosselling": "51",
        "fk_produitCross": "44",
        "fk_hotspots": "92"
      },
      {
        "pid_crosselling": "52",
        "fk_produitCross": "63",
        "fk_hotspots": "92"
      },
      {
        "pid_crosselling": "53",
        "fk_produitCross": "63",
        "fk_hotspots": "93"
      },
      {
        "pid_crosselling": "54",
        "fk_produitCross": "44",
        "fk_hotspots": "93"
      },
      {
        "pid_crosselling": "55",
        "fk_produitCross": "72",
        "fk_hotspots": "93"
      },
      {
        "pid_crosselling": "56",
        "fk_produitCross": "35",
        "fk_hotspots": "107"
      },
      {
        "pid_crosselling": "57",
        "fk_produitCross": "27",
        "fk_hotspots": "107"
      },
      {
        "pid_crosselling": "58",
        "fk_produitCross": "32",
        "fk_hotspots": "107"
      },
      {
        "pid_crosselling": "59",
        "fk_produitCross": "34",
        "fk_hotspots": "108"
      },
      {
        "pid_crosselling": "60",
        "fk_produitCross": "9",
        "fk_hotspots": "108"
      },
      {
        "pid_crosselling": "61",
        "fk_produitCross": "44",
        "fk_hotspots": "108"
      },
      {
        "pid_crosselling": "62",
        "fk_produitCross": "35",
        "fk_hotspots": "109"
      },
      {
        "pid_crosselling": "63",
        "fk_produitCross": "9",
        "fk_hotspots": "109"
      },
      {
        "pid_crosselling": "64",
        "fk_produitCross": "29",
        "fk_hotspots": "109"
      },
      {
        "pid_crosselling": "65",
        "fk_produitCross": "32",
        "fk_hotspots": "103"
      },
      {
        "pid_crosselling": "66",
        "fk_produitCross": "29",
        "fk_hotspots": "103"
      },
      {
        "pid_crosselling": "67",
        "fk_produitCross": "20",
        "fk_hotspots": "103"
      },
      {
        "pid_crosselling": "68",
        "fk_produitCross": "73",
        "fk_hotspots": "104"
      },
      {
        "pid_crosselling": "69",
        "fk_produitCross": "64",
        "fk_hotspots": "104"
      },
      {
        "pid_crosselling": "70",
        "fk_produitCross": "4",
        "fk_hotspots": "104"
      },
      {
        "pid_crosselling": "71",
        "fk_produitCross": "9",
        "fk_hotspots": "105"
      },
      {
        "pid_crosselling": "72",
        "fk_produitCross": "23",
        "fk_hotspots": "105"
      },
      {
        "pid_crosselling": "73",
        "fk_produitCross": "64",
        "fk_hotspots": "105"
      },
      {
        "pid_crosselling": "74",
        "fk_produitCross": "73",
        "fk_hotspots": "106"
      },
      {
        "pid_crosselling": "75",
        "fk_produitCross": "43",
        "fk_hotspots": "106"
      },
      {
        "pid_crosselling": "76",
        "fk_produitCross": "37",
        "fk_hotspots": "106"
      },
      {
        "pid_crosselling": "77",
        "fk_produitCross": "35",
        "fk_hotspots": "45"
      },
      {
        "pid_crosselling": "78",
        "fk_produitCross": "32",
        "fk_hotspots": "45"
      },
      {
        "pid_crosselling": "79",
        "fk_produitCross": "14",
        "fk_hotspots": "45"
      },
      {
        "pid_crosselling": "80",
        "fk_produitCross": "12",
        "fk_hotspots": "46"
      },
      {
        "pid_crosselling": "81",
        "fk_produitCross": "7",
        "fk_hotspots": "46"
      },
      {
        "pid_crosselling": "82",
        "fk_produitCross": "45",
        "fk_hotspots": "46"
      },
      {
        "pid_crosselling": "83",
        "fk_produitCross": "32",
        "fk_hotspots": "47"
      },
      {
        "pid_crosselling": "84",
        "fk_produitCross": "37",
        "fk_hotspots": "47"
      },
      {
        "pid_crosselling": "85",
        "fk_produitCross": "27",
        "fk_hotspots": "47"
      },
      {
        "pid_crosselling": "86",
        "fk_produitCross": "43",
        "fk_hotspots": "48"
      },
      {
        "pid_crosselling": "87",
        "fk_produitCross": "4",
        "fk_hotspots": "48"
      },
      {
        "pid_crosselling": "88",
        "fk_produitCross": "29",
        "fk_hotspots": "48"
      },
      {
        "pid_crosselling": "89",
        "fk_produitCross": "21",
        "fk_hotspots": "49"
      },
      {
        "pid_crosselling": "90",
        "fk_produitCross": "20",
        "fk_hotspots": "49"
      },
      {
        "pid_crosselling": "91",
        "fk_produitCross": "44",
        "fk_hotspots": "49"
      },
      {
        "pid_crosselling": "92",
        "fk_produitCross": "44",
        "fk_hotspots": "153"
      },
      {
        "pid_crosselling": "93",
        "fk_produitCross": "20",
        "fk_hotspots": "153"
      },
      {
        "pid_crosselling": "94",
        "fk_produitCross": "43",
        "fk_hotspots": "153"
      },
      {
        "pid_crosselling": "95",
        "fk_produitCross": "34",
        "fk_hotspots": "152"
      },
      {
        "pid_crosselling": "96",
        "fk_produitCross": "73",
        "fk_hotspots": "152"
      },
      {
        "pid_crosselling": "97",
        "fk_produitCross": "44",
        "fk_hotspots": "152"
      },
      {
        "pid_crosselling": "98",
        "fk_produitCross": "44",
        "fk_hotspots": "151"
      },
      {
        "pid_crosselling": "99",
        "fk_produitCross": "31",
        "fk_hotspots": "151"
      },
      {
        "pid_crosselling": "100",
        "fk_produitCross": "54",
        "fk_hotspots": "151"
      },
      {
        "pid_crosselling": "101",
        "fk_produitCross": "9",
        "fk_hotspots": "154"
      },
      {
        "pid_crosselling": "102",
        "fk_produitCross": "12",
        "fk_hotspots": "154"
      },
      {
        "pid_crosselling": "103",
        "fk_produitCross": "25",
        "fk_hotspots": "154"
      },
      {
        "pid_crosselling": "104",
        "fk_produitCross": "27",
        "fk_hotspots": "155"
      },
      {
        "pid_crosselling": "105",
        "fk_produitCross": "23",
        "fk_hotspots": "155"
      },
      {
        "pid_crosselling": "106",
        "fk_produitCross": "12",
        "fk_hotspots": "155"
      },
      {
        "pid_crosselling": "107",
        "fk_produitCross": "12",
        "fk_hotspots": "162"
      },
      {
        "pid_crosselling": "108",
        "fk_produitCross": "14",
        "fk_hotspots": "162"
      },
      {
        "pid_crosselling": "109",
        "fk_produitCross": "45",
        "fk_hotspots": "162"
      },
      {
        "pid_crosselling": "110",
        "fk_produitCross": "14",
        "fk_hotspots": "163"
      },
      {
        "pid_crosselling": "111",
        "fk_produitCross": "8",
        "fk_hotspots": "163"
      },
      {
        "pid_crosselling": "112",
        "fk_produitCross": "7",
        "fk_hotspots": "163"
      },
      {
        "pid_crosselling": "113",
        "fk_produitCross": "19",
        "fk_hotspots": "148"
      },
      {
        "pid_crosselling": "114",
        "fk_produitCross": "32",
        "fk_hotspots": "148"
      },
      {
        "pid_crosselling": "115",
        "fk_produitCross": "25",
        "fk_hotspots": "148"
      },
      {
        "pid_crosselling": "116",
        "fk_produitCross": "25",
        "fk_hotspots": "149"
      },
      {
        "pid_crosselling": "117",
        "fk_produitCross": "12",
        "fk_hotspots": "149"
      },
      {
        "pid_crosselling": "118",
        "fk_produitCross": "7",
        "fk_hotspots": "149"
      },
      {
        "pid_crosselling": "119",
        "fk_produitCross": "9",
        "fk_hotspots": "164"
      },
      {
        "pid_crosselling": "120",
        "fk_produitCross": "73",
        "fk_hotspots": "164"
      },
      {
        "pid_crosselling": "121",
        "fk_produitCross": "64",
        "fk_hotspots": "164"
      },
      {
        "pid_crosselling": "122",
        "fk_produitCross": "24",
        "fk_hotspots": "165"
      },
      {
        "pid_crosselling": "123",
        "fk_produitCross": "20",
        "fk_hotspots": "165"
      },
      {
        "pid_crosselling": "124",
        "fk_produitCross": "3",
        "fk_hotspots": "165"
      },
      {
        "pid_crosselling": "125",
        "fk_produitCross": "9",
        "fk_hotspots": "166"
      },
      {
        "pid_crosselling": "126",
        "fk_produitCross": "52",
        "fk_hotspots": "166"
      },
      {
        "pid_crosselling": "127",
        "fk_produitCross": "14",
        "fk_hotspots": "166"
      },
      {
        "pid_crosselling": "128",
        "fk_produitCross": "24",
        "fk_hotspots": "167"
      },
      {
        "pid_crosselling": "129",
        "fk_produitCross": "63",
        "fk_hotspots": "167"
      },
      {
        "pid_crosselling": "130",
        "fk_produitCross": "53",
        "fk_hotspots": "167"
      },
      {
        "pid_crosselling": "131",
        "fk_produitCross": "24",
        "fk_hotspots": "38"
      },
      {
        "pid_crosselling": "132",
        "fk_produitCross": "73",
        "fk_hotspots": "38"
      },
      {
        "pid_crosselling": "133",
        "fk_produitCross": "64",
        "fk_hotspots": "38"
      },
      {
        "pid_crosselling": "134",
        "fk_produitCross": "44",
        "fk_hotspots": "39"
      },
      {
        "pid_crosselling": "135",
        "fk_produitCross": "29",
        "fk_hotspots": "39"
      },
      {
        "pid_crosselling": "136",
        "fk_produitCross": "45",
        "fk_hotspots": "39"
      },
      {
        "pid_crosselling": "137",
        "fk_produitCross": "27",
        "fk_hotspots": "40"
      },
      {
        "pid_crosselling": "138",
        "fk_produitCross": "34",
        "fk_hotspots": "40"
      },
      {
        "pid_crosselling": "139",
        "fk_produitCross": "7",
        "fk_hotspots": "40"
      },
      {
        "pid_crosselling": "140",
        "fk_produitCross": "3",
        "fk_hotspots": "41"
      },
      {
        "pid_crosselling": "141",
        "fk_produitCross": "14",
        "fk_hotspots": "41"
      },
      {
        "pid_crosselling": "142",
        "fk_produitCross": "53",
        "fk_hotspots": "41"
      },
      {
        "pid_crosselling": "143",
        "fk_produitCross": "9",
        "fk_hotspots": "42"
      },
      {
        "pid_crosselling": "144",
        "fk_produitCross": "32",
        "fk_hotspots": "42"
      },
      {
        "pid_crosselling": "145",
        "fk_produitCross": "21",
        "fk_hotspots": "42"
      },
      {
        "pid_crosselling": "146",
        "fk_produitCross": "27",
        "fk_hotspots": "43"
      },
      {
        "pid_crosselling": "147",
        "fk_produitCross": "1",
        "fk_hotspots": "43"
      },
      {
        "pid_crosselling": "148",
        "fk_produitCross": "13",
        "fk_hotspots": "43"
      },
      {
        "pid_crosselling": "149",
        "fk_produitCross": "19",
        "fk_hotspots": "44"
      },
      {
        "pid_crosselling": "150",
        "fk_produitCross": "35",
        "fk_hotspots": "44"
      },
      {
        "pid_crosselling": "151",
        "fk_produitCross": "52",
        "fk_hotspots": "44"
      }
    ],
    "mediaType": [
      {
        "pid_typeDocument": "1",
        "name": "fiches produits"
      },
      {
        "pid_typeDocument": "2",
        "name": "catalogues/tarif génériques"
      },
      {
        "pid_typeDocument": "3",
        "name": "brochures spécifiques"
      },
      {
        "pid_typeDocument": "4",
        "name": "vidéo"
      }
    ],
    "media": [
      {
        "pid_media": "148",
        "fk_ranges": "2",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Pixer",
        "fk_library": "870",
        "new": "0",
        "brandNew": "1",
        "catalogueTarif": "0",
        "thumbnail": "775",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "1",
        "fk_zones": "0",
        "visibility": "0"
      },
      {
        "pid_media": "70",
        "fk_ranges": "2,4",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Rana LINEAR LED",
        "fk_library": "941",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "456",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "3",
        "fk_zones": "1",
        "visibility": "0"
      },
      {
        "pid_media": "179",
        "fk_ranges": "2",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Rubico Led G3",
        "fk_library": "945",
        "new": "0",
        "brandNew": "1",
        "catalogueTarif": "0",
        "thumbnail": "946",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "6",
        "fk_zones": "3",
        "visibility": "0"
      },
      {
        "pid_media": "73",
        "fk_ranges": "4",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Rana LINEAR LED suspendu",
        "fk_library": "942",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "462",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "10",
        "fk_zones": "35",
        "visibility": "0"
      },
      {
        "pid_media": "78",
        "fk_ranges": "11",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Beacon XXL LED",
        "fk_library": "471",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "472",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "16",
        "fk_zones": "0",
        "visibility": "0"
      },
      {
        "pid_media": "81",
        "fk_ranges": "5",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Syl LIghter LED II",
        "fk_library": "477",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "478",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "21",
        "fk_zones": "16",
        "visibility": "0"
      },
      {
        "pid_media": "96",
        "fk_ranges": "12",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Start Batten LED",
        "fk_library": "508",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "509",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "33",
        "fk_zones": "0",
        "visibility": "0"
      },
      {
        "pid_media": "109",
        "fk_ranges": "13",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Sylbay LED",
        "fk_library": "534",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "535",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "45",
        "fk_zones": "26",
        "visibility": "0"
      },
      {
        "pid_media": "110",
        "fk_ranges": "9",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Inverto montage en saillie",
        "fk_library": "536",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "537",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "48",
        "fk_zones": "0",
        "visibility": "0"
      },
      {
        "pid_media": "119",
        "fk_ranges": "13",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Start Highbay Eco",
        "fk_library": "555",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "556",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "56",
        "fk_zones": "0",
        "visibility": "0"
      },
      {
        "pid_media": "120",
        "fk_ranges": "13",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Start Highbay HE",
        "fk_library": "557",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "558",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "57",
        "fk_zones": "12",
        "visibility": "0"
      },
      {
        "pid_media": "121",
        "fk_ranges": "11",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Start Track Spot",
        "fk_library": "559",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "560",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "58",
        "fk_zones": "37",
        "visibility": "0"
      },
      {
        "pid_media": "150",
        "fk_ranges": "11",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Rail Global Trac Pro",
        "fk_library": "783",
        "new": "0",
        "brandNew": "1",
        "catalogueTarif": "0",
        "thumbnail": "784",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "63",
        "fk_zones": "36",
        "visibility": "0"
      },
      {
        "pid_media": "176",
        "fk_ranges": "5",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Ascent 100 II",
        "fk_library": "935",
        "new": "0",
        "brandNew": "1",
        "catalogueTarif": "0",
        "thumbnail": "936",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "70",
        "fk_zones": "14",
        "visibility": "0"
      },
      {
        "pid_media": "177",
        "fk_ranges": "5",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Expospot",
        "fk_library": "938",
        "new": "0",
        "brandNew": "1",
        "catalogueTarif": "0",
        "thumbnail": "939",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "71",
        "fk_zones": "17",
        "visibility": "0"
      },
      {
        "pid_media": "182",
        "fk_ranges": "17",
        "fk_applications": "",
        "fk_typeDocument": "1",
        "name": "Mini Continuum LED",
        "fk_library": "951",
        "new": "0",
        "brandNew": "0",
        "catalogueTarif": "0",
        "thumbnail": "952",
        "catalogueInteractif": "0",
        "typeBrochure": "",
        "sort": "76",
        "fk_zones": "20",
        "visibility": "0"
      }
    ],
    "version": "alpha.2"
  }
}
