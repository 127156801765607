import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

import { environment } from '~/environments/environment';

import { LocalizeRouterService } from 'localize-router';
import { ApplicationData } from '../shared/data/application.data';

/**
 * This class represents the catalog component.
 */
@Component({
  selector: 'app-catalog',
  templateUrl: 'catalog.component.html',
  styleUrls: ['catalog.component.scss'],
  host: {'class': 'popscreen'}
})
export class CatalogComponent implements OnInit {

  public environment = environment;

  /**
	 * Constructor
	 */
  constructor(public applicationData:ApplicationData,private router: Router, public location: Location, private localize: LocalizeRouterService, private sanitizer:DomSanitizer) { }

  /**
	 * On init
	 */
  ngOnInit() {

  }

  catalogURL() {
    //return this.sanitizer.bypassSecurityTrustResourceUrl();
  }

  /**
	 * Close
	 */
  closeModal($event = null) {
    this.router.navigate([this.localize.translateRoute('/'), { outlets: { pop: null } }]);
  }

}
