import { EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
/**
 * This class represents the video component.
 */
var AboutComponent = (function () {
    /**
     * Constructor
     */
    function AboutComponent(_location) {
        this._location = _location;
        this.modalClose = new EventEmitter();
        this.initialized = false;
        this.videoPlayed = false;
    }
    /**
     * On init
     */
    AboutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.videoPlayer.nativeElement.ontimeupdate = function () {
            var durationRatio = _this.videoPlayer.nativeElement.currentTime / _this.videoPlayer.nativeElement.duration;
            _this.progressBar.nativeElement.style.width = durationRatio * 100 + '%';
        };
    };
    AboutComponent.prototype.videoControlButton = function () {
        this.initialized = true;
        if (this.videoPlayer.nativeElement.paused) {
            this.videoPlayer.nativeElement.play();
            this.videoPlayed = true;
        }
        else {
            this.videoPlayer.nativeElement.pause();
            this.videoPlayed = false;
        }
    };
    AboutComponent.prototype.videoControlBar = function (event) {
        var positionRatio = event.offsetX / this.progressBg.nativeElement.offsetWidth;
        this.videoPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.duration * positionRatio;
    };
    /**
     * Close
     */
    AboutComponent.prototype.closeModal = function ($event) {
        if ($event === void 0) { $event = null; }
        this._location.back();
    };
    return AboutComponent;
}());
export { AboutComponent };
