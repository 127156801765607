import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { RegisterComponent } from './register/register.component';
import { LocalizeRouterModule } from 'localize-router';

const authRoutes: Routes = [
  { path: 'auth',  component: AuthComponent, data: { state: 'auth' }, pathMatch: 'full'},
  { path: 'register',  component: RegisterComponent, data: { state: 'register' }, pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes),
    LocalizeRouterModule.forChild(<any> authRoutes)
  ],
  exports: [ RouterModule, LocalizeRouterModule ]
})
export class AuthRoutingModule { }
