import { FileTransfer } from '@ionic-native/file-transfer';
import { File } from '@ionic-native/file';
import { FileOpener } from '@ionic-native/file-opener';
import { DocumentViewer } from '@ionic-native/document-viewer';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { ModalService } from './modal/modal.service';
import { AnalyticsService } from './services/analytics.service';
import { NavigationService } from './services/navigation.service';
import { ToolsService } from './services/tools.service';
import { UserService } from './services/user.service';
import { ApplicationData } from './data/application.data';
import { ApplicationState } from './data/application.state';
import { ConfigurationData } from './data/configuration.data';
import { DataGuard, AuthGuard } from '../app-routing.guards';
var DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};
var SharedModule = (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [
                AnalyticsService,
                NavigationService,
                ToolsService,
                UserService,
                ApplicationData,
                ApplicationState,
                ConfigurationData,
                DataGuard,
                AuthGuard,
                FileTransfer,
                File,
                FileOpener,
                DocumentViewer,
                ModalService,
                ScreenOrientation,
                {
                    provide: SWIPER_CONFIG,
                    useValue: DEFAULT_SWIPER_CONFIG
                }
            ]
        };
    };
    return SharedModule;
}());
export { SharedModule };
