<footer class="footer">
  <ul class="footer__nav">
    <li class="footer__nav__item footer__nav__item--viridian _footer-fullscreen" *ngIf="applicationState.fullscreenEnabled">
      <a (click)="toggleFullscreen($event)" class="footer__nav__item__container">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--big icon-back"></i>
        </div>
      </a>
    </li>
    <li class="footer__nav__item footer__nav__item--viridian _footer-back"  *ngIf="router.url+'/' != (['/'] | localize)">
      <a class="footer__nav__item__container" (click)="back()">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--big icon-back"></i>
        </div>
      </a>
    </li>
    <!-- <li class="footer__nav__item footer__nav__item--viridian _footer-back" *ngIf="router.url != (['/applications'] | localize) && router.url+'/' != (['/'] | localize) && !router.url.includes(['/medialist'] | localize) && !applicationState.fromURL">
      <a class="footer__nav__item__container" [routerLink]="applicationState.backURL | localize">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--big icon-back"></i>
        </div>
      </a>
    </li>
    <li class="footer__nav__item footer__nav__item--viridian _footer-back" *ngIf="applicationState.fromURL">
      <a class="footer__nav__item__container" [routerLink]="applicationState.fromURL | localize">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--big icon-back"></i>
        </div>
      </a>
    </li> -->
    <!-- <li class="footer__nav__item footer__nav__item--viridian _footer-back" *ngIf="router.url.includes(['/medialist'] | localize)">
      <a class="footer__nav__item__container" [routerLink]="applicationState.backURL2 | localize">
        back
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--big icon-back"></i>
        </div>
      </a>
    </li> -->
    <li class="footer__nav__item footer__nav__item--black" *ngIf="applicationState.fullscreenEnabled">
      <a class="footer__nav__item__container" (click)="toggleFullscreen($event)">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--big icon-expand"></i>
        </div>
      </a>
    </li>
    <li class="footer__nav__item" *ngIf="fullFunctionnalities && isApp && applicationState.userID != 'fr.info@sylvania-lighting.com'" [routerLinkActive]="['hidden', 'router-link-active']">
      <a class="footer__nav__item__container" [routerLink]="[('/' | localize),{outlets: { pop: ['cat'] } }]">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--small icon-catalog"></i>
          <span class="footer__nav__item__container__icon footer__nav__item__container__label" translate>FOOTER.CATALOG</span>
        </div>
      </a>
    </li>
    <li class="footer__nav__item" *ngIf="fullFunctionnalities && !isApp && applicationState.userID != 'fr.info@sylvania-lighting.com'">
      <a class="footer__nav__item__container" href="/ecatalogues/current/" target="_blank">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--small icon-catalog"></i>
          <span class="footer__nav__item__container__icon footer__nav__item__container__label" translate>FOOTER.CATALOG</span>
        </div>
      </a>
    </li>
    <li class="footer__nav__item" *ngIf="fullFunctionnalities" [routerLinkActive]="['hidden', 'router-link-active']">
      <a class="footer__nav__item__container" [routerLink]="['/', 'medialibrary'] | localize">
        <div>
          <i class="footer__nav__item__container__icon footer__nav__item__container__icon--small icon-circle-play"></i>
          <span class="footer__nav__item__container__icon footer__nav__item__container__label" translate>FOOTER.MEDIALIBRARY</span>
        </div>
      </a>
    </li>
  </ul>
  <div class="footer__motto">
    <img class="footer__motto__image" src="assets/images/footer/motto.svg" />
  </div>
</footer>
