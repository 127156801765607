import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '~/environments/environment';
import { LocalizeRouterService } from 'localize-router';
import { ApplicationData } from '../shared/data/application.data';
/**
 * This class represents the catalog component.
 */
var CatalogComponent = (function () {
    /**
       * Constructor
       */
    function CatalogComponent(applicationData, router, location, localize, sanitizer) {
        this.applicationData = applicationData;
        this.router = router;
        this.location = location;
        this.localize = localize;
        this.sanitizer = sanitizer;
        this.environment = environment;
    }
    /**
       * On init
       */
    CatalogComponent.prototype.ngOnInit = function () {
    };
    CatalogComponent.prototype.catalogURL = function () {
        //return this.sanitizer.bypassSecurityTrustResourceUrl();
    };
    /**
       * Close
       */
    CatalogComponent.prototype.closeModal = function ($event) {
        if ($event === void 0) { $event = null; }
        this.router.navigate([this.localize.translateRoute('/'), { outlets: { pop: null } }]);
    };
    return CatalogComponent;
}());
export { CatalogComponent };
