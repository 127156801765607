import { NgModule } from '@angular/core';
import { SharedModule } from '~/app/shared/shared.module';
import { SetupComponent } from './setup/setup.component';
import { SetupRoutingModule } from './setup-routing.module';

@NgModule({
  imports: [
    SharedModule,
    SetupRoutingModule
  ],
  declarations: [SetupComponent]
})
export class SetupModule { }
