/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./medialibrary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "localize-router/src/localize-router.pipe";
import * as i4 from "localize-router/src/localize-router.service";
import * as i5 from "@ngx-translate/core/src/translate.directive";
import * as i6 from "@ngx-translate/core/src/translate.service";
import * as i7 from "@angular/common";
import * as i8 from "./medialibrary.component";
import * as i9 from "../shared/data/application.data";
import * as i10 from "../shared/data/application.state";
var styles_MedialibraryComponent = [i0.styles];
var RenderType_MedialibraryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MedialibraryComponent, data: { "animation": [{ type: 7, name: "mediaTransition", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 11, selector: "._nav-item", animation: { type: 6, styles: { opacity: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: "._nav-item", animation: { type: 12, timings: 100, animation: [{ type: 6, styles: { transform: "translateY(100px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(0px)", opacity: 1 }, offset: null }, timings: "0.4s cubic-bezier(.75,-0.48,.26,1.52)" }] }, options: { optional: true } }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 11, selector: "._nav-item", animation: { type: 12, timings: 100, animation: [{ type: 6, styles: { transform: "translateY(0px)", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(100px)", opacity: 0 }, offset: null }, timings: "0.4s cubic-bezier(.75,-0.48,.26,1.52)" }] }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_MedialibraryComponent as RenderType_MedialibraryComponent };
function View_MedialibraryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "li", [["class", "_nav-item noselect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 3), i1.ɵpid(131072, i3.LocalizeRouterPipe, [i4.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "_nav-item__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "_nav-item__content__icon icon-newspaper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["class", "_nav-item__content__label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i5.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["MEDIALIBRARY.TYPE1"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_ck(_v, 2, 0, "/", "medialist", 1))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, "router-link-active"); _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 14, 0, currVal_2); }, null); }
function View_MedialibraryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "li", [["class", "_nav-item noselect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 3), i1.ɵpid(131072, i3.LocalizeRouterPipe, [i4.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "_nav-item__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "_nav-item__content__icon icon-books"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["class", "_nav-item__content__label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i5.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["MEDIALIBRARY.TYPE2"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_ck(_v, 2, 0, "/", "medialist", 2))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, "router-link-active"); _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 14, 0, currVal_2); }, null); }
function View_MedialibraryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "li", [["class", "_nav-item noselect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[5, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 3), i1.ɵpid(131072, i3.LocalizeRouterPipe, [i4.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "_nav-item__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "_nav-item__content__icon icon-books2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["class", "_nav-item__content__label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i5.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["MEDIALIBRARY.TYPE3"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_ck(_v, 2, 0, "/", "medialist", 3))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, "router-link-active"); _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 14, 0, currVal_2); }, null); }
function View_MedialibraryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "li", [["class", "_nav-item noselect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[7, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 3), i1.ɵpid(131072, i3.LocalizeRouterPipe, [i4.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "_nav-item__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "_nav-item__content__icon icon-youtube"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["class", "_nav-item__content__label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i5.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["MEDIALIBRARY.TYPE4"])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_ck(_v, 2, 0, "/", "medialist", 4))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, "router-link-active"); _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 14, 0, currVal_2); }, null); }
export function View_MedialibraryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "nav", [["class", "media-types _nav"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "ul", [["class", "_nav__items"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedialibraryComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedialibraryComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedialibraryComponent_3)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedialibraryComponent_4)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "media-types__background"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.applicationData.getMediaByType(1).length > 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.applicationData.getMediaByType(2).length > 0); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.applicationData.getMediaByType(3).length > 0); _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.applicationData.getMediaByType(4).length > 0); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_MedialibraryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-medialibrary", [["class", "screen"]], [[40, "@mediaTransition", 0]], null, null, View_MedialibraryComponent_0, RenderType_MedialibraryComponent)), i1.ɵdid(1, 114688, null, 0, i8.MedialibraryComponent, [i9.ApplicationData, i10.ApplicationState], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var MedialibraryComponentNgFactory = i1.ɵccf("app-medialibrary", i8.MedialibraryComponent, View_MedialibraryComponent_Host_0, {}, {}, []);
export { MedialibraryComponentNgFactory as MedialibraryComponentNgFactory };
