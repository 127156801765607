<div id="login" class="reset">

  <div class="page-content">
    <div class="login-box-row valign-helper">
      <div class="modal lost-password-modal">
      	<form (submit)=" password == confirmPassword && sendPassword()" #passwordForm="ngForm">
      	  <h1 class="page-title">{{ 'AUTH.RESET.LOGIN' | translate }}</h1>

          <div class="_form-input" [class.--error]="passwordForm.submitted && passwordField.errors">
            <label class="_form-input__label" for="password">{{ 'AUTH.RESET.PASSWORD' | translate }}</label>
            <input class="_form-input__input" type="password" [(ngModel)]="password" name="password" id="password" #passwordField="ngModel" required/>
          </div>

          <div class="_form-input" [class.--error]="passwordForm.submitted && password != confirmPassword">
            <label class="_form-input__label" for="confirmPassword">{{ 'AUTH.RESET.PASSWORD_CONFIRM' | translate }}</label>
            <input class="_form-input__input" type="password" [(ngModel)]="confirmPassword" name="confirmPassword" id="confirmPassword" #confirmPasswordField="ngModel" required/>
          </div>

      	  <div class="button-container">
            <button [disabled]="!passwordForm.valid || sendingPassword || password != confirmPassword" class="_button-container__button _button-container__button--bluegreen" type="submit">
              <span>{{ 'AUTH.LOGIN.SUBMIT' | translate }}</span>
            </button>
      	  </div>
      	</form>
      </div>
    </div>
  </div>
</div>

<app-modal id="modal-reset-confirm" width="640" #mrc [onClose]="onCloseConfirm()" >
	<p class="_title-2" *ngIf="mrc.data?.title" translate>{{mrc.data?.title}}</p>
  <p *ngIf="mrc.data?.text" [innerHTML]="mrc.data?.text | translate"></p>
</app-modal>
