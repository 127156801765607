import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { UserService } from '~/app/shared/services/user.service';
import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationData } from '../data/application.data';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

/**
 * This class represents the header component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  animations: [
    trigger('menuState', [
      state('in', style({transform: 'translateY(0)'})),
      transition('void => *', [
        animate(100, keyframes([
          style({opacity: 0, transform: 'translateY(-100%)', offset: 0}),
          style({opacity: 1, transform: 'translateY(0)', offset: 1})
        ]))
      ]),
      transition('* => void', [
        animate(300, keyframes([
          style({opacity: 1, transform: 'translateY(0)', offset: 0}),
          style({opacity: 0, transform: 'translateY(-100%)', offset: 1})
        ]))
      ])
    ])
  ]
})

export class HeaderComponent {

  public authMenuIsOpen: boolean

  public menuIsOpen: boolean;
  private count: number;
  private timer: any;


  /**
	 * Full functionnalities ?
	 */
  get fullFunctionnalities():boolean {
    if (environment.CONTEXT == ContextTypes.WEB) {
      return (this.router.url + '/' != this.localize.translateRoute('/')
          && this.router.url != this.localize.translateRoute('/auth')
          && this.router.url != this.localize.translateRoute('/register')
          && this.router.url.indexOf(this.localize.translateRoute('/reset') as string) < 0);
    } else {
      return (
          this.userService.loggedIn
          && this.router.url + '/' != this.localize.translateRoute('/')
          && this.router.url != this.localize.translateRoute('/auth')
          && this.router.url != this.localize.translateRoute('/register')
          && this.router.url.indexOf(this.localize.translateRoute('/reset') as string) < 0);
    }
  }


  /**
	 * Constructor
	 */
  constructor(
    public userService: UserService,
    private modalService: ModalService,
    public applicationData: ApplicationData,
    public router: Router,
    private localize: LocalizeRouterService,
  ) {
    this.count = 0;
    this.menuIsOpen = false;

    this.router.events.subscribe(e => {
      setTimeout(() => {
        this.menuIsOpen = false;
        this.modalService.cancel('modal-logout');
        this.authMenuIsOpen = false;
      }, 100);

    });
  }

  /**
	 * Open account menu
	 */
   toggleAuthMenu() {
     this.authMenuIsOpen = !this.authMenuIsOpen;
   }

   /**
 	 * Request logout
 	 */
  requestLogout() {
    this.modalService.open('modal-logout');
    this.authMenuIsOpen = false;
  }


  /**
	 * Switch lang
	 */
  switchLanguage(lang: string) {
    this.localize.changeLanguage(lang);
  }

  /**
   * Init setup
   */
  initSetup() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.count++ >= 5 && environment.CONTEXT == ContextTypes.APP) {
      this.router.navigate([this.localize.translateRoute('/setup')]);
    }

    this.timer = setTimeout(() => {
      this.count = 0;
    }, 1000);
  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  updateMenuState(event: any) {
    this.menuIsOpen = event.state;
  }

}
