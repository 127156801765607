<div class="auth">

  <div class="auth__bloc card">
    <div class="card__content">
      <div class="">
        <div class="card__title _title-3">
          {{ 'AUTH.LOGIN.TITLE' | translate }}
        </div>
        <form class="form auth__form" (submit)="login()" #loginForm="ngForm"  method="post"  name="loginForm" id="loginForm">
          <div class="_form-input">
            <label class="_form-input__label" for="email">{{ 'AUTH.LOGIN.LOGIN' | translate }}</label>
            <input class="_form-input__input" type="email" [(ngModel)]="loginEmail" name="loginEmail" #loginEmailField="ngModel" required/>
          </div>

          <div class="_form-input">
            <label class="_form-input__label" for="password">{{ 'AUTH.LOGIN.PASSWORD' | translate }}</label>
            <input class="_form-input__input" type="password" [(ngModel)]="loginPassword" name="loginPassword" #loginPasswordField="ngModel" required/>
          </div>

          <p class="auth__forgot" (click)="openLostPasswordModal()">{{ 'AUTH.LOGIN.FORGOT_PASSWORD' | translate }}</p>

          <div class="_button-container">
            <button (click)="login($event)" [disabled]="!loginForm.valid || loging" class="_button-container__button _button-container__button--bluegreen" type="submit">
              <span>{{ 'AUTH.LOGIN.SUBMIT' | translate }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="auth__bloc card">
    <div class="card__content">
      <div>

        <div class="card__title _title-3">
          {{ 'AUTH.REGISTER.TITLE' | translate }}
        </div>

        <p class="auth__register-text">{{ 'AUTH.REGISTER.TEXT' | translate }}</p>

        <div class="_button-container">
          <a [routerLink]="registerLink" class="_button-container__button _button-container__button--bluegreen">
            <span>{{ 'AUTH.REGISTER.SUBMIT' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal id="modal-login-lost" width="640" #mll>
  <form (submit)="sendPassword(true)" #passwordForm2="ngForm">
    <h1 class="page-title">{{ 'AUTH.MODAL.PASSWORD_TITLE' | translate }}</h1>
    <div class="_form-input" [class.error]="passwordEmailField.dirty && passwordEmailField.errors">
      <label class="_form-input__label" for="passwordEmail">{{ 'AUTH.MODAL.EMAIL' | translate }}</label>
      <input class="_form-input__input" [(ngModel)]="passwordEmail" type="email" name="passwordEmail" id="passwordEmail" #passwordEmailField="ngModel" required/>
    </div>

    <div class="button-container">
      <button class="_button-container__button _button-container__button--bluegreen" type="submit" [disabled]="!passwordForm2.valid || sendingPassword">
        <span>{{ 'AUTH.MODAL.SUBMIT' | translate }}</span>
      </button>
    </div>
  </form>
</app-modal>

<app-modal id="modal-login-message" width="640" #mlm>
	<p class="_title-2" *ngIf="mlm.data?.title" translate>{{mlm.data?.title}}</p>
  <p *ngIf="mlm.data?.text" [innerHTML]="mlm.data?.text | translate"></p>
</app-modal>

<app-modal id="modal-login-confirm" width="640" [onClose]="onCloseConfirm()" #mlc>
	<p class="_title-2" *ngIf="mlc.data?.title" translate>{{mlc.data?.title}}</p>
  <p *ngIf="mlc.data?.text" [innerHTML]="mlc.data?.text | translate"></p>
</app-modal>
