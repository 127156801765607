import { Inject, Injectable } from '@angular/core';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

export enum ApplicationStates {
  LOADING = 1,
  READY = 2,
  PAUSED = 3,
  MISSING = 4,
  ERROR = 5,
}

@Injectable()
export class ApplicationState {

  public state:any = null;
  public userID:any = null;
  public dataState:any = null;
  public backURL:string | any[];
  public backURL2:string | any[];
  public fromURL:string | any[];
  public loadingLabel = '';
  public preventScreensaver = false;
  public iFramedPDF:string;
  private _fullscreenEnabled = false;
  private _loading = 0;
  get fullscreenEnabled():boolean {
    return this._fullscreenEnabled;
  }
  set fullscreenEnabled(value:boolean) {
    this._fullscreenEnabled = value;

    if (!this.fullscreenEnabled) {
      document.body.classList.remove('fullscreen');
    }
  }

  public selectedFilter:string = '-1';
  public selectedFilter2:string = '-1';
  public newsFilter:boolean = false;

  /**
   * loading
   */
  set loading(value:boolean) {
    this._loading = Math.max(0, value ? this._loading + 1 : this._loading - 1);

    if (!this.loading) {
      this.loadingLabel = '';
    }
  }

  get loading():boolean {
    return this._loading > 0;
  }
}
