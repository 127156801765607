<div class="register">
  <div class="register__title _title-1">
    {{ 'REGISTER.TITLE' | translate }}
  </div>
  <form class="register__form" #registerForm="ngForm" (ngSubmit)="registerForm.form.valid && password == confirmPassword && !submiting && register()">

    <div class="register__form-col">
      <div class="">

        <div class="_form-input" [class.--error]="registerForm.submitted && (typeField.errors || type == -1)">
          <label class="_form-input__label" for="company">{{'FORM.ROLE' | translate}}*</label>
          <div class="_select">
            <select name="type" id="type" #typeField="ngModel" [(ngModel)]="type" required>
              <option value="-1">{{'FORM.ROLE' | translate}}*</option>
              <option value="1">{{'FORM.P1' | translate}}</option>
              <option value="2">{{'FORM.P2' | translate}}</option>
              <option value="3">{{'FORM.P3' | translate}}</option>
              <option value="4">{{'FORM.P4' | translate}}</option>
              <option value="5">{{'FORM.P5' | translate}}</option>
              <option value="6">{{'FORM.P6' | translate}}</option>
              <option value="7">{{'FORM.P7' | translate}}</option>
              <option value="8">{{'FORM.CLIENT' | translate}}</option>
              <option value="9">{{'FORM.OTHER' | translate}}</option>
            </select>
            <div class="_select__arrow"></div>
          </div>
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && companyField.errors">
          <label class="_form-input__label" for="company">{{'FORM.COMPANY' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="company" type="text" name="company" id="company" #companyField="ngModel" required />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && nameField.errors">
          <label class="_form-input__label" for="name">{{'FORM.FIRSTNAME' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="name" type="text" name="name" id="name" #nameField="ngModel" required />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && surnameField.errors">
          <label class="_form-input__label" for="surname">{{'FORM.LASTNAME' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="surname" type="text" name="surname" id="surname" #surnameField="ngModel" required />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && phoneField.errors" *ngIf="environment.CONTEXT == ContextTypes.WEB">
          <label class="_form-input__label" for="phone">{{'FORM.PHONE' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="phone" type="text" name="phone" id="phone" #phoneField="ngModel" required />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && emailField.errors" *ngIf="environment.CONTEXT == ContextTypes.WEB">
          <label class="_form-input__label" for="email">{{'FORM.EMAIL' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="email" type="email" name="email" id="email" #emailField="ngModel" required pattern="^[a-zA-Z0-9._-]+@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$" />
        </div>
      </div>
    </div>

    <div class="register__form-col">
      <div class="">
        <div class="_form-input" [class.--error]="registerForm.submitted && phoneField.errors" *ngIf="environment.CONTEXT != ContextTypes.WEB">
          <label class="_form-input__label" for="phone">{{'FORM.PHONE' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="phone" type="text" name="phone" id="phone" #phoneField="ngModel" required />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && emailField.errors" *ngIf="environment.CONTEXT != ContextTypes.WEB">
          <label class="_form-input__label" for="email">{{'FORM.EMAIL' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="email" type="email" name="email" id="email" #emailField="ngModel" required pattern="^[a-zA-Z0-9._-]+@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$" />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && passwordField.errors" *ngIf="environment.CONTEXT == ContextTypes.WEB">
          <label class="_form-input__label" for="password">{{'FORM.PASSWORD' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="password" type="password" name="password" id="password" #passwordField="ngModel" required />
        </div>

        <div class="_form-input" [class.--error]="registerForm.submitted && password != confirmPassword" *ngIf="environment.CONTEXT == ContextTypes.WEB">
          <label class="_form-input__label" for="confirmPassword">{{'FORM.PASSWORD_CONFIRM' | translate}}*</label>
          <input class="_form-input__input" [(ngModel)]="confirmPassword" type="password" name="confirmPassword" id="confirmPassword" #confirmPasswordField="ngModel" required/>
        </div>

        <div class="checkbox" [class.--error]="registerForm.submitted && optin != '1'">
          <input type="checkbox" id="optin" [(ngModel)]="optin" type="checkbox" name="optin" value="1" [checked]="optin == '1'" required>
          <label for="optin">{{ 'REGISTER.OPTIN' | translate }}</label>
        </div>

        <div class="option-warning">
          <p>{{ 'REGISTER.RGPD.PART1' | translate }}</p>
          <p>{{ 'REGISTER.RGPD.PART2' | translate }} <a [routerLink]="privacyLink">{{ 'REGISTER.RGPD.LINK' | translate }}</a>.</p>
        </div>

        <div class="_button-container" [class.right]="environment.CONTEXT != ContextTypes.WEB">
          <button class="_button-container__button _button-container__button--bluegreen" type="submit">
            <span>{{ 'REGISTER.SUBMIT' | translate }}</span>
          </button>
        </div>

        <div class="required" [class.right]="environment.CONTEXT != ContextTypes.WEB">
          {{ 'REGISTER.MANDATORY' | translate }}
        </div>

      </div>
    </div>

  </form>
</div>

<app-modal id="modal-register-message" width="640" #mrm>
	<p class="_title-2" *ngIf="mrm.data?.title" translate>{{mrm.data?.title}}</p>
  <p *ngIf="mrm.data?.text" [innerHTML]="mrm.data?.text | translate"></p>
</app-modal>

<app-modal id="modal-register-confirmation" width="640" #mrc [onClose]="onCloseConfirm()" >
	<p class="_title-2" *ngIf="mrc.data?.title" translate>{{mrc.data?.title}}</p>
  <p *ngIf="mrc.data?.text" [innerHTML]="mrc.data?.text | translate"></p>
</app-modal>
