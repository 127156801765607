var ConfigurationData = (function () {
    /**
       * Constructor
       */
    function ConfigurationData() {
        this.dataDirectory = 'assets';
    }
    /**
       * Init
       */
    ConfigurationData.prototype.init = function () {
        return new Promise(function (resolve, reject) {
            resolve(true);
        });
    };
    return ConfigurationData;
}());
export { ConfigurationData };
