/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./about.component";
import * as i3 from "@angular/common";
var styles_AboutComponent = [i0.styles];
var RenderType_AboutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
export function View_AboutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { videoPlayer: 0 }), i1.ɵqud(402653184, 2, { videoControls: 0 }), i1.ɵqud(402653184, 3, { progressBg: 0 }), i1.ɵqud(402653184, 4, { progressBar: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 41, "div", [["class", "about__container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "_media-fullscreen"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "a", [["class", "icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 33, "div", [["class", "_video-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 30, "div", [["class", "_player-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(15, 0, [[1, 0], ["videoPlayer", 1]], null, 3, "video", [["class", "_player"], ["loop", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "source", [["src", "assets/videos/about.mp4"], ["type", "video/mp4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "start-button"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "button", [["class", "_controls__button _controls__button--big"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.videoControlButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["class", "icon-play"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(28, 0, [[2, 0], ["videoControls", 1]], null, 14, "div", [["class", "_controls"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(30, 0, null, null, 3, "button", [["class", "_controls__button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.videoControlButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(32, 0, null, null, 0, "i", [], [[2, "icon-pause", null], [2, "icon-play", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(35, 0, null, null, 6, "div", [["class", "_controls__progress-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(37, 0, [[3, 0], ["progressBg", 1]], null, 3, "div", [["class", "_controls__progress-container__background"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.videoControlBar($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(39, 0, [[4, 0], ["progressBar", 1]], null, 0, "span", [["class", "_controls__progress-container__background__bar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initialized; _ck(_v, 20, 0, currVal_0); var currVal_1 = !_co.initialized; _ck(_v, 28, 0, currVal_1); var currVal_2 = _co.videoPlayed; var currVal_3 = !_co.videoPlayed; _ck(_v, 32, 0, currVal_2, currVal_3); }); }
export function View_AboutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-about", [["class", "fullscreen"]], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i1.ɵdid(1, 114688, null, 0, i2.AboutComponent, [i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AboutComponentNgFactory = i1.ɵccf("app-about", i2.AboutComponent, View_AboutComponent_Host_0, {}, { modalClose: "modalClose" }, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
