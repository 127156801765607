import { Inject, Injectable } from '@angular/core';

import { ApplicationData } from '../data/application.data';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

@Injectable()
export class StartupService {

  /**
	 * Constructor
	 */
  constructor(private appData: ApplicationData) {
  }

  /**
	 * Startup
	 */
  public startup() {
    return new Promise((resolve, reject) => {
      if (environment.CONTEXT == ContextTypes.APP) {
        resolve(false);
      } else {
        this.appData.load().then(response => {
          resolve(true);
        }).catch((e:Error) => {
          reject(false);
        });
      }
    });
  }
}
