import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationEvent, transition, trigger } from '@angular/animations';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';

export const authTransition = trigger('authTransition', [
  transition(':leave', [
  ])
]);

@Component({
  moduleId: module.id,
  selector: 'app-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss'],
  animations: [authTransition],
  host: {
    '[@authTransition]': '',
    'class': 'screen'
  }
})
export class AuthComponent implements OnInit, OnDestroy {

  public registerLink:string;

  public loging: boolean = false;
  public loginEmail: string;
  public loginPassword: string;

  public sendingPassword: boolean = false;
  public passwordEmail: string;

  /**
	 * Constructor
	 */
  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private router:Router,
    private localize: LocalizeRouterService
  ) {
    this.loginEmail = this.userService.email;
  }

  /**
	 * On init
	 */
  ngOnInit() {
    this.registerLink = this.localize.translateRoute('/register') as string;
  }

  /**
   * Login
   */
  login(event = null): boolean {

    if (event) {
      event.stopImmediatePropagation();
    }

    this.loging = true;
    this.userService.login(
                            this.loginEmail,
                            this.loginPassword
                          ).then((result: any) => {
      this.loging = false;
      if (result.code === 200) {
        if (this.userService.needUpdate) {
          this.router.navigate([this.localize.translateRoute('/reset/1/'+this.userService.token)]);
        } else {
          this.router.navigate(this.userService.redirectAfterLogin);
        }
      } else {
        this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: result.data });
      }
    }).catch((e: Error) => {
      this.loging = false;
      this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: 'Veuillez vérifier que vous êtes connecté à internet avant de recommencer' });
    });


    return false;
  }

  /**
   * Open lost password modal
   */
  openLostPasswordModal():void {
    this.passwordEmail = this.loginEmail;
    this.modalService.open('modal-login-lost');
  }

  /**
   * Send password
   */
  sendPassword(modal: boolean = false): boolean {
    this.sendingPassword = true;
    this.userService.sendPassword(
                            this.passwordEmail
                          ).then((result: any) => {
      if (result.code == 200) {
        this.modalService.cancel('modal-login-lost');
        this.modalService.open('modal-login-confirm', { title: 'AUTH.PASSWORD_SENT', text: 'AUTH.PASSWORD_SENT_TEXT'});
      } else {
        //this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: result.data });
        this.modalService.cancel('modal-login-lost');
        this.modalService.open('modal-login-confirm', { title: 'AUTH.PASSWORD_SENT', text: 'AUTH.PASSWORD_SENT_TEXT'});
      }

      this.sendingPassword = false;
    }).catch((e: Error) => {
      this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: 'GLOBAL.ERROR_UNKNOWN' });
      this.sendingPassword = false;
    });

    return false;
  }

  /**
   * On close
   */
  public onCloseConfirm() {
    return () => {
      this.router.navigate([this.localize.translateRoute('/')]);
    }
  }

  /**
	 * On destroy
	 */
  ngOnDestroy() {
    this.userService.resetAfterLogin();
  }
}
