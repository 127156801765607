import { NgModule } from '@angular/core';
import { SharedModule } from '~/app/shared/shared.module';
import { PreHomeComponent } from './prehome/prehome.component';
import { PreHomeRoutingModule } from './prehome-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PreHomeRoutingModule
  ],
  declarations: [PreHomeComponent]
})
export class PreHomeModule { }
