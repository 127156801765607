import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

declare let gtag:any;

@Injectable()
export class AnalyticsService {

  private analyticsID:string;

  /**
	 * Constructor
	 */
  constructor(private router: Router) {
  }

  /**
	 * Init
	 */
  public init(id:string):void {
    this.analyticsID = id;

    
      // // tslint:disable
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*<any>new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.googletagmanager.com/gtag/js?id='+this.analyticsID,'ga');
      // // tslint:enable

      gtag('js', new Date());
      gtag('config', this.analyticsID);
      this.router.events.subscribe(event => {
        console.log(event, gtag);
        if (event instanceof NavigationEnd && gtag) {
          gtag('event', 'page_view', {
            page_title: event.urlAfterRedirects,
            page_path: event.urlAfterRedirects,
            page_location: event.urlAfterRedirects
          })
        }
      });
  }
}
