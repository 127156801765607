<header class="header">

  <div class="header__top-container _va-parent">
    <a class="header__top-container__menu-toggle _va-child" *ngIf="fullFunctionnalities" (click)="toggleMenu()">
      <span class="header__top-container__menu-toggle__burger-icon" [class.--is-active]="menuIsOpen"></span>
    </a>

    <a class="header__top-container__account _va-child" *ngIf="fullFunctionnalities && !userService.loggedIn" [routerLink]="[('/auth' | localize)]"  [routerLinkActive]="['router-link-active']">
      <i class="header__top-container__cart-elements__icon icon-account"></i>
      <i class="icon-lock"></i>
    </a>

    <a class="header__top-container__account _va-child" *ngIf="fullFunctionnalities && userService.loggedIn" (click)="toggleAuthMenu()" [routerLinkActive]="['router-link-active']">
      <i class="header__top-container__cart-elements__icon icon-account"></i>
      <i class="icon-logged"></i>
      <ul class="header__auth-menu" *ngIf="authMenuIsOpen">
        <!-- <li><a [routerLink]="[('/auth' | localize)]">{{ 'HEADER.ACCOUNT' | translate }}</a></li> -->
        <li><button (click)="requestLogout()">{{ 'HEADER.LOGOUT' | translate }}</button></li>
      </ul>
    </a>

    <a class="header__top-container__cart-elements _va-child" *ngIf="fullFunctionnalities" [routerLink]="[('/' | localize),{outlets: { pop: ['cart'] } }]" [routerLinkActive]="['router-link-active']">
      <i class="header__top-container__cart-elements__icon icon-trolley"></i>
      <span class="header__top-container__cart-elements__chip" translate>{{ applicationData.cartSize }}</span>
    </a>
  </div>

  <div class="header__background">
    <img src="assets/images/header/background.svg" alt="">
  </div>

  <a class="header__logo-container" [routerLink]="[('/applications' | localize)]" (click)="initSetup()">
    <img class="header__logo-container__logo-image" src="assets/images/common/logo.svg" />
  </a>

</header>

<app-menu [@menuState] *ngIf="menuIsOpen" (state)="updateMenuState($event)"></app-menu>
