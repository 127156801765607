import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { trigger, animate, style, transition } from '@angular/animations';
import { Location } from '@angular/common';
/**
 * This class represents the video component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-about',
  templateUrl: 'about.component.html',
  styleUrls: ['about.component.scss'],
  host: {
    'class': 'fullscreen'
  },
})

export class AboutComponent {

  @Output() modalClose : EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('videoPlayer') videoPlayer: any;
  @ViewChild('videoControls') videoControls: any;
  @ViewChild('progressBg') progressBg: any;
  @ViewChild('progressBar') progressBar: any;

  public initialized:boolean = false;
  public videoId:number;
  public videoPlayed: boolean;
  private previousUrl: string;
  private routeSub:any;

  /**
   * Constructor
   */
  constructor(private _location: Location) {
    this.videoPlayed = false;
  }

  /**
   * On init
   */
  ngOnInit() {
    this.videoPlayer.nativeElement.ontimeupdate = () => {
      let durationRatio = this.videoPlayer.nativeElement.currentTime / this.videoPlayer.nativeElement.duration;
      this.progressBar.nativeElement.style.width = durationRatio * 100  + '%';
    };
  }

  videoControlButton() {
    this.initialized = true;
    if(this.videoPlayer.nativeElement.paused) {
      this.videoPlayer.nativeElement.play();
      this.videoPlayed = true
    } else {
      this.videoPlayer.nativeElement.pause();
      this.videoPlayed = false
    }
  }

  videoControlBar(event: any) {
    let positionRatio = event.offsetX / this.progressBg.nativeElement.offsetWidth;
    this.videoPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.duration * positionRatio;
  }

  /**
   * Close
   */
  closeModal($event = null) {
    this._location.back();
  }

}
