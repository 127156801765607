<app-header></app-header>
<main [@appTransition]="getState(o)">
  <router-outlet #o="outlet"></router-outlet>
</main>
<app-footer></app-footer>

<cookie-law *ngIf="environment.CONTEXT == ContextTypes.WEB">
  <span>
    {{ 'COOKIE.TEXT' | translate }}
    <span>{{ 'COOKIE.LEARN_MORE' | translate }} <a [routerLink]="[('/privacy' | localize)]">{{ 'COOKIE.LINK' | translate }}</a>. </span>
  </span>
</cookie-law>

<app-modal id="modal-error" width="640" #m>
	<p class="_title-2" *ngIf="m.data?.title" translate>{{m.data?.title}}</p>
  <p *ngIf="m.data?.text" translate>{{m.data?.text}}</p>
</app-modal>

<app-modal id="modal-logout" width="640" #ml>
	<p class="" translate>AUTH.MODAL.LOGOUT_TITLE</p>
  <br>
  <div class="button-container">
    <button class="_button-container__button _button-container__button--bluegreen" (click)='logout()'>
      <span>{{ 'AUTH.MODAL.LOGOUT' | translate }}</span>
    </button>
  </div>
</app-modal>

<!-- <div class="pdf__container" *ngIf="applicationState.iFramedPDF">
  <iframe [src]="applicationState.iFramedPDF  | safeUrl" width="100%" height="100%"></iframe>
  <div class="_media-fullscreen">
    <a (click)="applicationState.iFramedPDF = null" class="pdf__close icon-close"></a>
  </div>
</div> -->
<div class="pdf__container" *ngIf="applicationState.iFramedPDF">
    <iframe [src]="'assets/pdf-viewer/viewer.html?file='+applicationState.iFramedPDF | safeUrl" width="100%" height="100%" style="border: none;">
    </iframe>
    <div class="_media-fullscreen">
      <a (click)="applicationState.iFramedPDF = null" class="pdf__close icon-close"></a>
    </div>
</div>

<router-outlet name="pop"></router-outlet>
<router-outlet name="video"></router-outlet>

<app-loader *ngIf="applicationState.loading" [label]="applicationState.loadingLabel"><app-loader>
