import { SetupComponent } from './setup/setup.component';
import { AuthGuard } from '~/app/app-routing.guards';
var ɵ0 = { state: 'setup' };
var setupRoutes = [
    { path: 'setup', component: SetupComponent, data: ɵ0, canActivate: [AuthGuard] }
];
var SetupRoutingModule = (function () {
    function SetupRoutingModule() {
    }
    return SetupRoutingModule;
}());
export { SetupRoutingModule };
export { ɵ0 };
