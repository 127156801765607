<nav class="media-types _nav">
  <ul class="_nav__items">
    <li class="_nav-item noselect" [routerLink]="['/', 'medialist', 1] | localize" [routerLinkActive]="['router-link-active']" *ngIf="applicationData.getMediaByType(1).length > 0">
      <div class="_nav-item__content">
        <i class="_nav-item__content__icon icon-newspaper"></i>
        <span class="_nav-item__content__label" translate>MEDIALIBRARY.TYPE1</span>
      </div>
    </li>
    <li class="_nav-item noselect" [routerLink]="['/', 'medialist', 2] | localize" [routerLinkActive]="['router-link-active']" *ngIf="applicationData.getMediaByType(2).length > 0">
      <div class="_nav-item__content">
        <i class="_nav-item__content__icon icon-books"></i>
        <span class="_nav-item__content__label" translate>MEDIALIBRARY.TYPE2</span>
      </div>
    </li>
    <li class="_nav-item noselect" [routerLink]="['/', 'medialist', 3] | localize" [routerLinkActive]="['router-link-active']" *ngIf="applicationData.getMediaByType(3).length > 0">
      <div class="_nav-item__content">
        <i class="_nav-item__content__icon icon-books2"></i>
        <span class="_nav-item__content__label" translate>MEDIALIBRARY.TYPE3</span>
      </div>
    </li>
    <li class="_nav-item noselect" [routerLink]="['/', 'medialist', 4] | localize" [routerLinkActive]="['router-link-active']" *ngIf="applicationData.getMediaByType(4).length > 0">
      <div class="_nav-item__content">
        <i class="_nav-item__content__icon icon-youtube"></i>
        <span class="_nav-item__content__label" translate>MEDIALIBRARY.TYPE4</span>
      </div>
    </li>
  </ul>
</nav>

<div class="media-types__background">
</div>
