import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '~/environments/environment';
var CustomTranslateLoader = (function () {
    function CustomTranslateLoader(http) {
        this.http = http;
        this.contentHeader = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
    }
    CustomTranslateLoader.prototype.getTranslation = function (lang) {
        var _this = this;
        var url = environment.BASE + '/assets/locales/' + lang + '.json';
        return Observable.create(function (observer) {
            _this.http.get(url, { headers: _this.contentHeader }).subscribe(function (res) {
                observer.next(res);
                observer.complete();
            }, function (error) {
                //  failed to retrieve from base, switch to local
                _this.http.get('./assets/locales/' + lang + '.json').subscribe(function (res) {
                    observer.next(res);
                    observer.complete();
                });
            });
        });
    };
    return CustomTranslateLoader;
}());
export { CustomTranslateLoader };
