import { TranslateService } from '@ngx-translate/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationStart, NavigationEnd } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ApplicationState, ApplicationStates } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ConfigurationData } from '~/app/shared/data/configuration.data';
import { UserService } from '~/app/shared/services/user.service';
import { ModalService } from '~/app/shared/modal/modal.service';
import { AnalyticsService } from '~/app/shared/services/analytics.service';
import { NavigationService } from '~/app/shared/services/navigation.service';
var AppComponent = (function () {
    /**
       * Constructor
       */
    function AppComponent(applicationState, applicationData, translate, localize, userService, modalService, analyticsService, router, navigation, splashScreen, configuration) {
        var _this = this;
        this.applicationState = applicationState;
        this.applicationData = applicationData;
        this.translate = translate;
        this.localize = localize;
        this.userService = userService;
        this.modalService = modalService;
        this.analyticsService = analyticsService;
        this.router = router;
        this.navigation = navigation;
        this.splashScreen = splashScreen;
        this.configuration = configuration;
        this.environment = environment;
        this.ContextTypes = ContextTypes;
        this.translate.setDefaultLang('fr');
        if (environment.CONTEXT == ContextTypes.APP) {
            document.addEventListener('deviceready', function () { _this.init(); }, false);
        }
        else {
            this.init();
        }
        this.router.events.subscribe(function (event) {
            if (event instanceof RouteConfigLoadStart) {
                _this.loaderTimeout = window.setTimeout(function () { _this.applicationState.loading = true; _this.loaderTimeout = null; }, 300);
            }
            else if (event instanceof RouteConfigLoadEnd) {
                _this.applicationState.loading = false;
                if (_this.loaderTimeout) {
                    clearTimeout(_this.loaderTimeout);
                    _this.loaderTimeout = null;
                }
            }
            else if (event instanceof NavigationStart) {
                if (_this.prehomeTimeout) {
                    clearTimeout(_this.prehomeTimeout);
                    _this.prehomeTimeout = null;
                }
            }
            else if (event instanceof NavigationEnd) {
                _this.applicationState.backURL2 = null;
                _this.prehomeTimeout = window.setTimeout(function () {
                    if (!_this.applicationState.preventScreensaver) {
                        _this.applicationData.emptyCart();
                        _this.router.navigate([_this.localize.translateRoute('/')]);
                    }
                }, environment.IDLE_TIME * 1000);
            }
        });
        this.navigation.startup();
    }
    /**
     * Init App
     */
    AppComponent.prototype.init = function () {
        var _this = this;
        this.applicationState.state = ApplicationStates.READY;
        this.applicationState.backURL = '/applications';
        this.analyticsService.init(environment.GOOGLE_ANALYTICS_ID);
        FastClick.attach(document.body);
        if (environment.CONTEXT == ContextTypes.APP) {
            // Windows HACK
            environment.BASE = window['cordova'].file.dataDirectory;
            if (navigator.userAgent.match(/(iPod|iPad|iPhone)/)) {
                window['cordova'].plugins.Keyboard.hideKeyboardAccessoryBar(false);
            }
            // Force fullscreen
            if (typeof AndroidFullScreen !== 'undefined') {
                AndroidFullScreen.isSupported(function () {
                    AndroidFullScreen.immersiveMode();
                }, function () {
                });
            }
            if (typeof Fullscreen !== 'undefined') {
                Fullscreen.on();
            }
            // Hide splash
            this.splashScreen.hide();
            // Listen states
            document.addEventListener('pause', function () { _this.pause(); }, false);
            document.addEventListener('resume', function () { _this.resume(); }, false);
            if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
                this.currentBodyOffset = 0;
                window.addEventListener('native.keyboardshow', function (e) {
                    var lastKnownKeyboardHeight = e.keyboardHeight;
                    if (document.activeElement) {
                        if ((document.activeElement.tagName === 'INPUT') || (document.activeElement.tagName === 'TEXTAREA')) {
                            var _focusedElmt = document.activeElement.getBoundingClientRect();
                            if ((_focusedElmt.bottom - _this.currentBodyOffset) > (window.innerHeight * 0.9 - lastKnownKeyboardHeight)) {
                                var _desiredYPosition = (window.innerHeight - (lastKnownKeyboardHeight + _focusedElmt.height)) * .5;
                                var _delta = _desiredYPosition - (_focusedElmt.top - _this.currentBodyOffset);
                                _this.currentBodyOffset = _delta;
                                document.body.style.transform = 'translateY(' + _delta + 'px)';
                                document.body.style.webkitTransform = 'translateY(' + _delta + 'px)';
                            }
                            else {
                                document.body.style.transform = 'translateY(0)';
                                document.body.style.webkitTransform = 'translateY(0)';
                                _this.currentBodyOffset = 0;
                            }
                        }
                        else {
                            document.body.style.transform = 'translateY(0)';
                            document.body.style.webkitTransform = 'translateY(0)';
                            _this.currentBodyOffset = 0;
                        }
                    }
                    else {
                        document.body.style.transform = 'translateY(0)';
                        document.body.style.webkitTransform = 'translateY(0)';
                        _this.currentBodyOffset = 0;
                    }
                });
                window.addEventListener('native.keyboardhide', function () {
                    document.body.style.transform = 'translateY(0)';
                    document.body.style.webkitTransform = 'translateY(0)';
                    _this.currentBodyOffset = 0;
                });
            }
        }
        else {
        }
        // Init config
        this.userService.init();
        this.configuration.init().then(function (response) {
            if (environment.CONTEXT == ContextTypes.APP) {
                _this.applicationData.load().catch(function (e) {
                });
            }
        }).catch(function (e) {
        });
    };
    /**
       * Cordova app states
       */
    AppComponent.prototype.pause = function () {
        this.applicationState.state = ApplicationStates.PAUSED;
    };
    AppComponent.prototype.resume = function () {
        this.applicationState.state = ApplicationStates.READY;
    };
    /**
    * Logout
    */
    AppComponent.prototype.logout = function () {
        this.userService.logout();
    };
    /**
     * getState
     */
    AppComponent.prototype.getState = function (outlet) {
        return outlet.activatedRouteData.state;
    };
    return AppComponent;
}());
export { AppComponent };
