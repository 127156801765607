/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "localize-router/src/localize-router.pipe";
import * as i5 from "localize-router/src/localize-router.service";
import * as i6 from "@ngx-translate/core/src/translate.pipe";
import * as i7 from "@ngx-translate/core/src/translate.service";
import * as i8 from "@ngx-translate/core/src/translate.directive";
import * as i9 from "../menu/menu.component.ngfactory";
import * as i10 from "../menu/menu.component";
import * as i11 from "../data/application.state";
import * as i12 from "../services/tools.service";
import * as i13 from "../data/application.data";
import * as i14 from "../modal/modal.service";
import * as i15 from "@ionic-native/file/index";
import * as i16 from "./header.component";
import * as i17 from "../services/user.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: { "animation": [{ type: 7, name: "menuState", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "translateY(0)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateY(-100%)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 1 }, offset: null }] }, timings: 100 }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1, transform: "translateY(0)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 0, transform: "translateY(-100%)", offset: 1 }, offset: null }] }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "header__top-container__menu-toggle _va-child"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "header__top-container__menu-toggle__burger-icon"]], [[2, "--is-active", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuIsOpen; _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "a", [["class", "header__top-container__account _va-child"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i4.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(3, 1), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "header__top-container__cart-elements__icon icon-account"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icon-lock"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("/auth"))); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 7, 0, "router-link-active"); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ul", [["class", "header__auth-menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.requestLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("HEADER.LOGOUT")); _ck(_v, 5, 0, currVal_0); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "a", [["class", "header__top-container__account _va-child"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAuthMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "header__top-container__cart-elements__icon icon-account"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon-logged"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "router-link-active"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.authMenuIsOpen; _ck(_v, 11, 0, currVal_1); }, null); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "a", [["class", "header__top-container__cart-elements _va-child"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i4.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(3, 1), i1.ɵpod(4, { pop: 0 }), i1.ɵpod(5, { outlets: 0 }), i1.ɵpad(6, 2), i1.ɵdid(7, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "header__top-container__cart-elements__icon icon-trolley"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["class", "header__top-container__cart-elements__chip"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(15, 8536064, null, 0, i8.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 6, 0, i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("/")), _ck(_v, 5, 0, _ck(_v, 4, 0, _ck(_v, 3, 0, "cart")))); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, "router-link-active"); _ck(_v, 7, 0, currVal_3); var currVal_4 = ""; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _co.applicationData.cartSize; _ck(_v, 16, 0, currVal_5); }); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], [[24, "@menuState", 0]], [[null, "state"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("state" === en)) {
        var pd_0 = (_co.updateMenuState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MenuComponent_0, i9.RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i10.MenuComponent, [i11.ApplicationState, i12.ToolsService, i13.ApplicationData, i14.ModalService, i5.LocalizeRouterService, i15.File, i2.Router], null, { state: "state" })], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "header__top-container _va-parent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "header__background"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/header/background.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(22, 0, null, null, 6, "a", [["class", "header__logo-container"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.initSetup() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i4.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(25, 1), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "img", [["class", "header__logo-container__logo-image"], ["src", "assets/images/common/logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(32, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fullFunctionnalities; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.fullFunctionnalities && !_co.userService.loggedIn); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.fullFunctionnalities && _co.userService.loggedIn); _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.fullFunctionnalities; _ck(_v, 14, 0, currVal_3); var currVal_6 = _ck(_v, 25, 0, i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("/applications"))); _ck(_v, 23, 0, currVal_6); var currVal_7 = _co.menuIsOpen; _ck(_v, 32, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 23).target; var currVal_5 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_4, currVal_5); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i16.HeaderComponent, [i17.UserService, i14.ModalService, i13.ApplicationData, i2.Router, i5.LocalizeRouterService], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i16.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
