import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationStates } from '~/app/shared/data/application.state';
import { UserService } from '~/app/shared/services/user.service';

@Injectable()
export class DataGuard implements CanActivate {
  constructor(protected applicationData: ApplicationData, protected router: Router, private localize: LocalizeRouterService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
    if (this.applicationData.dataState == ApplicationStates.READY) {
      return true;
    } else {
      this.router.navigate([this.localize.translateRoute('/setup')]);
      return false;
    }

  }
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(protected userService: UserService, protected router: Router, private localize: LocalizeRouterService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
    if (environment.CONTEXT == ContextTypes.APP && !this.userService.loggedIn) {
      this.userService.redirectAfterLogin = [state.url];
      this.router.navigate([this.localize.translateRoute('/auth')]);
      return false;
    } else {
      return true;
    }

  }
}
