import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { File } from '@ionic-native/file';
import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';

import { ToolsService } from '~/app/shared/services/tools.service';

declare let cordova:any;
declare let device:any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public environment = environment;
  public ContextTypes = ContextTypes;
  public catalogs;
  public catalog1URL;
  public catalog2URL;

  @Output() state = new EventEmitter<any>();

  constructor(
    public applicationState:ApplicationState,
    public toolsService: ToolsService,
    private applicationData: ApplicationData,
    private modalService: ModalService,
    private localize: LocalizeRouterService,
    private file: File,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.catalogs = this.applicationData.getMediaByType(2).filter((item) => {
      return item.isInMenu
    });
  }

  openLink(e:Event) {
    if (environment.CONTEXT == ContextTypes.APP) {
      e.preventDefault();
      cordova.InAppBrowser.open((<HTMLLinkElement>e.currentTarget).href, '_blank', 'location=no');
    }
  }

  /**
   * Close
   */
  close() {
    this.state.emit({
      state: false
    });
  }

}
