import { FileOpener } from '@ionic-native/file-opener';
import { DocumentViewer } from '@ionic-native/document-viewer';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { ApplicationState } from '~/app/shared/data/application.state';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
var ToolsService = (function () {
    /**
       * Constructor
       */
    function ToolsService(applicationState, fileOpener, documentViewer, screenOrientation) {
        this.applicationState = applicationState;
        this.fileOpener = fileOpener;
        this.documentViewer = documentViewer;
        this.screenOrientation = screenOrientation;
    }
    /**
     * Open file
     */
    ToolsService.prototype.openFile = function (e) {
        if (environment.CONTEXT == ContextTypes.APP) {
            e.preventDefault();
            if (device.platform == "Android") {
                this.fileOpener.open(e.currentTarget.href, 'application/pdf').then(function (response) { });
            }
            else if (device.platform == "windows") {
                this.applicationState.iFramedPDF = e.currentTarget.href;
                e.preventDefault();
            }
            else {
                // this.inAppRef = cordova.InAppBrowser.open((<HTMLLinkElement>e.currentTarget).href, '_blank', 'location=no,enableViewportScale=yes');
                // this.screenOrientation.unlock();
                //
                // this.inAppRef.addEventListener('exit', this.unlockOrientation.bind(this));
                //
                // this.screenOrientation.lock('portrait');
                // //ref.addEventListener('loaderror', this.unlockOrientation.bind(this));
                this.applicationState.iFramedPDF = e.currentTarget.href;
                e.preventDefault();
            }
        }
    };
    ToolsService.prototype.unlockOrientation = function (event) {
        this.screenOrientation.lock('landscape-primary');
        if (this.inAppRef) {
            event.target.removeEventListener('exit', this.unlockOrientation);
        }
    };
    return ToolsService;
}());
export { ToolsService };
