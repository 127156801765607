var ModalService = (function () {
    function ModalService() {
        this.hModals = {};
    }
    ModalService.prototype.add = function (modal) {
        var id = modal.id;
        if (!id) {
            throw new Error('add(): Attempt to add a modal without an id');
        }
        this.hModals[id] = modal;
    };
    ModalService.prototype.remove = function (id) {
        delete this.hModals[id];
    };
    ModalService.prototype.open = function (id, data) {
        var modal = this.hModals[id];
        if (!modal) {
            throw new Error("open(): There is no modal with id '" + id + "'");
        }
        modal.open(data);
    };
    ModalService.prototype.cancel = function (id) {
        var modal = this.hModals[id];
        if (!modal) {
            throw new Error("cancel(): There is no modal with id '" + id + "'");
        }
        modal.close();
    };
    return ModalService;
}());
export { ModalService };
