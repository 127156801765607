import { Router, NavigationEnd } from '@angular/router';
var AnalyticsService = (function () {
    /**
       * Constructor
       */
    function AnalyticsService(router) {
        this.router = router;
    }
    /**
       * Init
       */
    AnalyticsService.prototype.init = function (id) {
        this.analyticsID = id;
        // // tslint:disable
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.googletagmanager.com/gtag/js?id=' + this.analyticsID, 'ga');
        // // tslint:enable
        gtag('js', new Date());
        gtag('config', this.analyticsID);
        this.router.events.subscribe(function (event) {
            console.log(event, gtag);
            if (event instanceof NavigationEnd && gtag) {
                gtag('event', 'page_view', {
                    page_title: event.urlAfterRedirects,
                    page_path: event.urlAfterRedirects,
                    page_location: event.urlAfterRedirects
                });
            }
        });
    };
    return AnalyticsService;
}());
export { AnalyticsService };
