import { Component } from '@angular/core';
import { animate, AnimationEvent, query, stagger, style, transition, trigger } from '@angular/animations';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';


export const mediaTransition = trigger('mediaTransition', [
  transition(':enter', [
    query('._nav-item', style({ opacity: 0 }), { optional: true }),
    query('._nav-item', stagger(100, [
      style({ transform: 'translateY(100px)' }),
      animate('0.4s cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateY(0px)', opacity: 1 })),
    ]), { optional: true }),
  ]),
  transition(':leave', [
    query('._nav-item', stagger(100, [
      style({ transform: 'translateY(0px)', opacity: 1 }),
      animate('0.4s cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateY(100px)', opacity: 0 })),
    ]), { optional: true }),
  ])
]);

/**
 * This class represents the medialibrary component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-medialibrary',
  templateUrl: 'medialibrary.component.html',
  styleUrls: ['medialibrary.component.scss'],
  animations: [mediaTransition],
  host: {
    'class': 'screen',
    '[@mediaTransition]': ''
  }
})

export class MedialibraryComponent {

  public environment = environment;

  /**
	 * Constructor
	 */
  constructor(
    public applicationData:ApplicationData,
    public applicationState:ApplicationState,
  ) {
    //this.applicationState.backURL = '/applications';
  }

  /**
	 * On init
	 */
  ngOnInit() {
    this.applicationState.selectedFilter = '-1';
    this.applicationState.selectedFilter2 = '-1';
    this.applicationState.newsFilter = false;
  }

}
