import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from 'localize-router';
import { ApplicationState } from '~/app/shared/data/application.state';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
export var UserCategory;
(function (UserCategory) {
    UserCategory[UserCategory["SYLVANIA"] = 1] = "SYLVANIA";
    UserCategory[UserCategory["APP"] = 2] = "APP";
    UserCategory[UserCategory["WEB"] = 3] = "WEB";
    UserCategory[UserCategory["UNKNONW"] = 0] = "UNKNONW";
})(UserCategory || (UserCategory = {}));
var UserService = (function () {
    /**
     * Creates a new UserService with the injected Http.
     */
    function UserService(applicationState, http, router, localize, translate) {
        this.applicationState = applicationState;
        this.http = http;
        this.router = router;
        this.localize = localize;
        this.translate = translate;
        this._headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.resetAfterLogin();
    }
    /**
     * Init
     */
    UserService.prototype.init = function () {
        if (localStorage.getItem('auth_date')) {
            var cookieDate = new Date(localStorage.getItem('auth_date'));
            var limitDate = new Date(cookieDate);
            limitDate.setDate(limitDate.getDate() + 7);
            if (new Date().getTime() < limitDate.getTime()) {
                this.loggedIn = !!localStorage.getItem('auth_token');
                if (this.loggedIn) {
                    this.id = localStorage.getItem('auth_token');
                    this.type = +localStorage.getItem('auth_type');
                    this.category = +localStorage.getItem('auth_category');
                    this.position = localStorage.getItem('auth_position');
                    this.company = localStorage.getItem('auth_company');
                    this.firstname = localStorage.getItem('auth_firstname');
                    this.lastname = localStorage.getItem('auth_lastname');
                    this.countryCode = localStorage.getItem('auth_countryCode');
                    this.phone = localStorage.getItem('auth_phone');
                    this.optin = localStorage.getItem('auth_optin');
                }
            }
            else {
                this.logout();
            }
        }
        this.email = localStorage.getItem('auth_email');
        this.applicationState.userID = this.email;
    };
    Object.defineProperty(UserService.prototype, "loggedIn", {
        /**
       * loggedIn
       */
        get: function () {
            return this._loggedIn;
        },
        set: function (value) {
            this._loggedIn = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Login
     */
    UserService.prototype.login = function (email, password) {
        var _this = this;
        var data = {
            login: email,
            password: password,
            access_token: environment.API_TOKEN,
            lang: this.translate.currentLang,
        };
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.REMOTE_SERVER + '/api/rest/login', { params: data, observe: 'response' }).subscribe(function (responseData) {
                var res = responseData.body;
                if (res.code === 200) {
                    if (res.data.needUpdate == '1') {
                        _this.token = res.data.token;
                        _this.needUpdate = true;
                    }
                    else {
                        _this.token = null;
                        _this.needUpdate = false;
                        _this.updateWithData(res.data);
                        localStorage.setItem('auth_password', password);
                    }
                }
                else {
                    _this.logout(false);
                }
                resolve(res);
            }, function (err) {
                reject(false);
            });
        });
    };
    /**
     * Check login
     */
    UserService.prototype.check = function (email, password) {
        var _this = this;
        var data = {
            login: email,
            password: password,
            access_token: environment.API_TOKEN,
            lang: this.translate.currentLang,
        };
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.REMOTE_SERVER + '/api/rest/login', { params: data, observe: 'response' }).subscribe(function (responseData) {
                var res = responseData.body;
                resolve(res);
            }, function (err) {
                reject(false);
            });
        });
    };
    /**
     * Logout
     */
    UserService.prototype.logout = function (redirect) {
        if (redirect === void 0) { redirect = true; }
        this.loggedIn = false;
        this.id = null;
        this.position = null;
        this.company = null;
        this.firstname = null;
        this.lastname = null;
        this.countryCode = null;
        this.phone = null;
        this.email = null;
        this.applicationState.userID = this.email;
        this.optin = null;
        localStorage.removeItem('auth_login');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_password');
        localStorage.removeItem('auth_type');
        localStorage.removeItem('auth_category');
        localStorage.removeItem('auth_position');
        localStorage.removeItem('auth_company');
        localStorage.removeItem('auth_firstname');
        localStorage.removeItem('auth_lastname');
        localStorage.removeItem('auth_countryCode');
        localStorage.removeItem('auth_phone');
        // localStorage.removeItem('auth_email');
        localStorage.removeItem('auth_optin');
        localStorage.removeItem('auth_date');
        if (redirect) {
            this.router.navigate([this.localize.translateRoute('/')]);
        }
    };
    /**
       * Send Password
       */
    UserService.prototype.sendPassword = function (email) {
        var _this = this;
        var data = {
            login: email,
            lang: this.translate.currentLang,
            origin: '' + environment.CONTEXT,
        };
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.REMOTE_SERVER + '/api/rest/password?access_token=' + environment.API_TOKEN, { params: data, observe: 'response' }).subscribe(function (responseData) {
                var res = responseData.body;
                resolve(res);
            }, function (err) {
                reject(false);
            });
        });
    };
    /**
       * reset password
       */
    UserService.prototype.resetPassword = function (password, token, origin) {
        var _this = this;
        var body = new URLSearchParams();
        body.set('lang', this.translate.currentLang);
        body.set('password', password);
        body.set('token', token);
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.REMOTE_SERVER + '/api/rest/users/reset?access_token=' + environment.API_TOKEN, body.toString(), { headers: _this._headers, observe: 'response' }).subscribe(function (responseData) {
                var res = responseData.body;
                if (res.code == 200 && origin == ContextTypes.WEB) {
                    _this.updateWithData(res.data);
                }
                resolve(res);
            }, function (err) {
                reject(false);
            });
        });
    };
    /**
     * Update with data
     */
    UserService.prototype.updateWithData = function (data) {
        this.loggedIn = true;
        this.id = data.id;
        this.type = data.type;
        this.category = data.category;
        this.position = data.position;
        this.company = data.company;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.countryCode = data.countryCode;
        this.phone = data.phone;
        this.email = data.email;
        this.applicationState.userID = this.email;
        this.optin = data.optin;
        localStorage.setItem('auth_login', this.email);
        localStorage.setItem('auth_token', this.id);
        localStorage.setItem('auth_type', '' + this.type);
        localStorage.setItem('auth_category', '' + this.category);
        localStorage.setItem('auth_position', this.position);
        localStorage.setItem('auth_company', this.company);
        localStorage.setItem('auth_firstname', this.firstname);
        localStorage.setItem('auth_lastname', this.lastname);
        localStorage.setItem('auth_countryCode', this.countryCode);
        localStorage.setItem('auth_phone', this.phone);
        localStorage.setItem('auth_email', this.email);
        localStorage.setItem('auth_optin', this.optin);
        localStorage.setItem('auth_date', new Date().toString());
    };
    /**
     * Register
     */
    UserService.prototype.register = function (type, company, surname, name, phone, email, password, optin) {
        var _this = this;
        var body = new URLSearchParams();
        body.append('lang', this.translate.currentLang);
        body.append('type', type);
        body.append('category', environment.CONTEXT == ContextTypes.WEB ? '3' : '2');
        body.append('company', company ? company : '');
        body.append('surname', surname);
        body.append('name', name);
        body.append('phone', phone);
        body.append('email', email);
        if (password) {
            body.append('password', password);
        }
        body.append('optin', optin ? optin : '0');
        return new Promise(function (resolve, reject) {
            _this.http.put(environment.REMOTE_SERVER + '/api/rest/users?access_token=' + environment.API_TOKEN, body.toString(), { headers: _this._headers, observe: 'response' }).subscribe(function (responseData) {
                var res = responseData.body;
                if (res.code == 200) {
                    if (environment.CONTEXT == ContextTypes.WEB) {
                        _this.updateWithData(res.data);
                    }
                }
                resolve(res);
            }, function (err) {
                reject(false);
            });
        });
    };
    /**
     * reset redirect after login
     */
    UserService.prototype.resetAfterLogin = function () {
        this.redirectAfterLogin = [this.localize.translateRoute('/applications')];
    };
    return UserService;
}());
export { UserService };
