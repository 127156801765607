import { Component } from '@angular/core';

/**
 * This class represents the medialibrary component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-legals',
  templateUrl: 'legals.component.html',
  styleUrls: ['legals.component.scss'],
  host: {'class': 'screen'}
})

export class LegalsComponent {

  /**
	 * Constructor
	 */
  constructor() {

  }

  /**
	 * On init
	 */
  ngOnInit() {

  }

}
