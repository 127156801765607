import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationEvent, transition, trigger } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { UserService } from '~/app/shared/services/user.service';

declare let TimelineMax:any;
declare let Cubic:any;

export const prehomeTransition = trigger('prehomeTransition', [
  transition(':leave', [
  ])
]);

@Component({
  moduleId: module.id,
  selector: 'app-prehome',
  templateUrl: 'prehome.component.html',
  styleUrls: ['prehome.component.scss'],
  animations: [prehomeTransition],
  host: {
    '[@prehomeTransition]': '',
    '(@prehomeTransition.done)': 'animationEnded($event)',
    'class': 'screen'
  }
})
export class PreHomeComponent implements OnInit, OnDestroy {

  public isApp:boolean;
  public isiOS:boolean;
  public isAndroid:boolean;

  @ViewChild('cta')
  private cta: ElementRef;

  @ViewChild('slider')
  private slider;

  public SwiperConfig: SwiperConfigInterface = {
    grabCursor: false,
    speed: 800,
    autoplay: {
      delay: 4000,
    },
    loop: true
  };

  /**
	 * Constructor
	 */
  constructor(
    private userService: UserService,
    private router:Router,
    private localize: LocalizeRouterService
  ) {

    this.isApp = environment.CONTEXT == ContextTypes.APP;
    this.isiOS = (/iPhone|iPad|iPod|Safari/i.test(navigator.userAgent)) && !navigator.userAgent.match(/Chrome/i); 
    this.isAndroid = (/Android/i.test(navigator.userAgent));
  }

  /**
	 * On init
	 */
  ngOnInit() {
    // const tl = new TimelineMax({repeat:-1, repeatDelay:2});
    //
    // tl.to(this.cta.nativeElement, 0.5, {
    //  autoAlpha:0,
    //  ease: Cubic.easeInOut
    // });
    //
    // tl.to(this.cta.nativeElement, 0.5, {
    //   autoAlpha:1,
    //   ease: Cubic.easeInOut
    // });
  }

  /**
	 * On click
	 */
  @HostListener('click', ['$event'])
  onClick(e) {
    if (environment.CONTEXT == ContextTypes.APP && !this.userService.loggedIn) {
      this.router.navigate([this.localize.translateRoute('/auth')]);
    } else {
      this.router.navigate([this.localize.translateRoute('/applications')]);
    }
  }

  /**
	 * Animation Ended
	 */
  animationEnded(e: AnimationEvent) {
    if (e.toState == 'void') {
      if (this.slider) {
        this.slider.destroy();
      }
    }
  }

  /**
	 * On destroy
	 */
  ngOnDestroy() {
  }
}
