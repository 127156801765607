<nav class="_nav">
  <ul class="_nav__items">
    <a class="_nav-item" [routerLink]="[('/applications' | localize)]" [routerLinkActive]="['active']" (click)="close()">
      <li class="_nav-item__content">
        <i class="_nav-item__content__icon icon-lamp"></i>
        <span class="_nav-item__content__label" translate>MENU.ITEM_1</span>
      </li>
    </a>
    <a class="_nav-item" [routerLink]="[('/about' | localize)]" (click)="close()">
      <li class="_nav-item__content">
        <i class="_nav-item__content__icon icon-people"></i>
        <span class="_nav-item__content__label" translate>MENU.ITEM_2</span>
      </li>
    </a>
    <a class="_nav-item" [routerLink]="[('/' | localize),{outlets: { pop: ['cat'] } }]" (click)="close()">
      <li class="_nav-item__content">
        <i class="_nav-item__content__icon icon-catalog"></i>
        <span class="_nav-item__content__label" translate>MENU.ITEM_3</span>
      </li>
    </a>
    <a class="_nav-item" [routerLink]="[('/medialibrary' | localize)]" (click)="close()">
      <li class="_nav-item__content">
        <i class="_nav-item__content__icon icon-circle-play"></i>
        <span class="_nav-item__content__label" translate>MENU.ITEM_4</span>
      </li>
    </a>
    <a class="_nav-item" href="http://www.sylvania-lighting.com/fr-fr" target="_blank" (click)="openLink($event)">
      <li class="_nav-item__content">
        <i class="_nav-item__content__icon icon-globe"></i>
        <span class="_nav-item__content__label" translate>MENU.ITEM_5</span>
      </li>
    </a>
    <ul class="_nav__subitems">
      <a *ngFor="let item of catalogs" class="_nav__subitem"  [href]="applicationData.convertFilePath(item.file) | safeUrl" [attr.data-title]="item.titleRef" (click)="toolsService.openFile($event)" target="_blank">
        <li [innerHTML]="item.titleRef + '<br>(pdf)'"></li>
      </a>
    </ul>
  </ul>
</nav>

<a class="_nav__extra" [routerLink]="[('/privacy' | localize)]">
  <span class="_nav-extra__content__label" translate>MENU.PRIVACY</span>
</a>

<a class="_nav__extra-bis" [routerLink]="[('/legals' | localize)]">
  <span class="_nav-extra__content__label" translate>MENU.LEGALS</span>
</a>
