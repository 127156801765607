import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs/Observable';
var NavigationService = (function () {
    /**
       * Constructor
       */
    function NavigationService(router) {
        this.router = router;
    }
    /**
       * Startup
       */
    NavigationService.prototype.startup = function () {
        var _this = this;
        this.params = new Observable(function (observer) {
            _this.currentState = _this.splitURL(_this.router.url);
            _this.routeSub = _this.router.events.subscribe(function (event) {
                if (event instanceof NavigationStart) {
                    _this.currentState = _this.splitURL(event.url);
                    observer.next(_this.currentState);
                }
            });
        });
    };
    /**
       * split URL
       */
    NavigationService.prototype.splitURL = function (url) {
        var u = url.replace(/[\(\)]/gi, "").split('/');
        var r = [];
        r['id'] = u[3];
        r['id2'] = u[5];
        r['id3'] = u[6];
        return r;
    };
    /**
       * Cleanup
       */
    NavigationService.prototype.cleanup = function () {
        this.routeSub.unsubscribe();
    };
    return NavigationService;
}());
export { NavigationService };
