import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { transition, trigger } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { UserService } from '~/app/shared/services/user.service';
export var prehomeTransition = trigger('prehomeTransition', [
    transition(':leave', [])
]);
var PreHomeComponent = (function () {
    /**
       * Constructor
       */
    function PreHomeComponent(userService, router, localize) {
        this.userService = userService;
        this.router = router;
        this.localize = localize;
        this.SwiperConfig = {
            grabCursor: false,
            speed: 800,
            autoplay: {
                delay: 4000,
            },
            loop: true
        };
        this.isApp = environment.CONTEXT == ContextTypes.APP;
        this.isiOS = (/iPhone|iPad|iPod|Safari/i.test(navigator.userAgent)) && !navigator.userAgent.match(/Chrome/i);
        this.isAndroid = (/Android/i.test(navigator.userAgent));
    }
    /**
       * On init
       */
    PreHomeComponent.prototype.ngOnInit = function () {
        // const tl = new TimelineMax({repeat:-1, repeatDelay:2});
        //
        // tl.to(this.cta.nativeElement, 0.5, {
        //  autoAlpha:0,
        //  ease: Cubic.easeInOut
        // });
        //
        // tl.to(this.cta.nativeElement, 0.5, {
        //   autoAlpha:1,
        //   ease: Cubic.easeInOut
        // });
    };
    /**
       * On click
       */
    PreHomeComponent.prototype.onClick = function (e) {
        if (environment.CONTEXT == ContextTypes.APP && !this.userService.loggedIn) {
            this.router.navigate([this.localize.translateRoute('/auth')]);
        }
        else {
            this.router.navigate([this.localize.translateRoute('/applications')]);
        }
    };
    /**
       * Animation Ended
       */
    PreHomeComponent.prototype.animationEnded = function (e) {
        if (e.toState == 'void') {
            if (this.slider) {
                this.slider.destroy();
            }
        }
    };
    /**
       * On destroy
       */
    PreHomeComponent.prototype.ngOnDestroy = function () {
    };
    return PreHomeComponent;
}());
export { PreHomeComponent };
