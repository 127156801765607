// https://plnkr.co/edit/O7vV42pxlQ2y0wKDz0fj?p=preview
import {
  Component,
  ElementRef,
  Input,
  Output,
  HostBinding,
  OnInit,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { AnimationEvent, trigger, state, animate, style, transition } from '@angular/animations';

import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('*', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)')),
      transition('hidden => shown', animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)')),
    ])
  ],
  host: {
    '(@visibilityChanged.start)': 'animationStarted($event)',
    '(@visibilityChanged.done)': 'animationEnded($event)'
  }
})
export class ModalComponent implements OnInit, OnDestroy {

  @HostBinding('@visibilityChanged') visiblityState = 'hidden';

  @Input() id: string;
  @Input() width: number;
  @Input() onClose: Function;

  @Output() onOK: EventEmitter<any>;

  public data:any;

  body: any;

  constructor(private modalSvc: ModalService, private element: ElementRef) {
    this.onOK = new EventEmitter<any>();
  }

  ngOnInit(): void {

    if (!this.id) {
      throw new Error('modal must have an id');
    }

    this.body = document.getElementsByTagName('body')[0];
    this.body.appendChild(this.element.nativeElement);

    this.modalSvc.add(this);
  }

  animationStarted(e:AnimationEvent) {
    if (e.toState == 'shown') {
      this.element.nativeElement.style.display = 'block';
    }
  }

  animationEnded(e:AnimationEvent) {
    if (e.toState == 'hidden') {
      this.element.nativeElement.style.display = 'none';
    }
  }

  getStyles(): Object {
    if (this.width) {
      return {
        'max-width': this.width.toString() + 'px',
        'margin-left': 'auto',
        'margin-right': 'auto'
      };
    }
    return {};
  }

  ngOnDestroy(): void {
    this.modalSvc.remove(this.id);
    this.element.nativeElement.remove();
  }

  open(d?:any): void {
    this.data = d;
    this.body.classList.add('modal-open');
    this.visiblityState = 'shown';
  }

  close(): void {
    this.visiblityState = 'hidden';
    this.body.classList.remove('modal-open');

    if (this.onClose) {
      this.onClose();
    }
  }
}
