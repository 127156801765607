import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
  transform(list: any, keyValues: any) {
    return list.filter((item:any) => {
      for (const key in keyValues) {
        if (typeof keyValues[key] === 'object') {
          if (keyValues[key].length > 0) {
            if (item[key]) {
              if (typeof item[key] === 'object') {
                return item[key].every((elem: any) => keyValues[key].indexOf(elem) > -1);
              } else {
                if (keyValues[key].indexOf(item[key]) === -1) {
                  return false;
                }
              }
            }
          }
        } else {
          return !(item[key] && item[key] !== keyValues[key] && keyValues[key] && keyValues[key] !== -1 && keyValues[key] !== '-1');
        }
      }
      return true;
    });
  }
}

@Pipe({name: 'nl2br'})
export class Nl2BrPipe implements PipeTransform {

    transform(value:any) {
      return value.replace(/\n/g, '<br>');
    }

}
