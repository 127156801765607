<div class="setup__container">
  <div class="screen__container">
    <p class="_title-1" translate>SETUP.TITLE</p>
    <span class="setup-info-msg setup-info-msg--loading" *ngIf="state == SetupStates.LOADING">Loading...</span>
    <ng-container *ngIf="state == SetupStates.READY">
      <span class="setup-info-msg setup-info-msg--ready" translate>SETUP.INFO_MSG.READY</span>
      <div class="">
        <button class="_button-container__button" type="button" [routerLink]="[('/applications' | localize)]">
          <span translate>FORM.SUBMISSION_MSG.BTN</span>
        </button>
      </div>
    </ng-container>
    <span class="setup-info-msg setup-info-msg--error" *ngIf="state == SetupStates.ERROR" translate>SETUP.INFO_MSG.ERROR</span>
    <ng-container *ngIf="state == SetupStates.READY || state == SetupStates.NEED_UPDATE">
      <div class="_button-container" *ngIf="state == SetupStates.NEED_UPDATE">
        <span class="_button-container__info-msg">{{ 'SETUP.INFO_MSG.NEED_UPDATE' | translate }} :</span>
        <button class="_button-container__button" type="button" (click)="requestAccess(synchronize)">
          <i class="icon-sync"></i>
          <span translate>SETUP.UPDATE_BTN</span>
        </button>
        <span class="_button-container__info-msg _button-container__info-msg--small" translate>SETUP.INFO_MSG.NEED_UPDATE.SMALL</span>
      </div>
      <div class="setup-list">
        <div class="setup-list__header">
          <span class="setup-list__cell setup-list__cell--date">{{ 'SETUP.DATE' | translate }}</span>
          <span class="setup-list__cell setup-list__cell--name">{{ 'SETUP.NAME' | translate }}</span>
          <span class="setup-list__cell setup-list__cell--company">{{ 'SETUP.COMPANY' | translate }}</span>
          <!-- <span class="setup-list__cell setup-list__cell--company">{{ 'SETUP.EMAIL' | translate }}</span> -->
          <span class="setup-list__cell setup-list__cell--status">{{ 'SETUP.STATUS' | translate }}</span>
        </div>
        <ul class="setup-list__body">
          <li class="setup-list__body__item" *ngFor="let request of applicationData.requests">
            <span class="setup-list__cell setup-list__cell--date">{{ request.time | date:'yyyy-MM-dd HH:mm' }}</span>
            <span class="setup-list__cell setup-list__cell--name">{{ request.name }}</span>
            <span class="setup-list__cell setup-list__cell--company">{{ request.company }}</span>
            <!-- <span class="setup-list__cell setup-list__cell--company">{{ request.email }}</span> -->
            <span class="setup-list__cell setup-list__cell--status --sent" *ngIf="request.status != 'pending'">
              <i class="icon-check"></i>
              <span>{{ 'SETUP.SENT' | translate }}</span>
            </span>
            <span class="setup-list__cell setup-list__cell--status --unsent" *ngIf="request.status == 'pending'">
              <i class="icon-clock"></i>
              <span>{{ 'SETUP.PENDING' | translate }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div class="_button-container --bottom" *ngIf="state == SetupStates.NEED_UPDATE ||state == SetupStates.READY">
        <button class="_button-container__button" type="button" (click)="requestAccess(synchronizeRequests)">
          <i class="icon-sync"></i>
          <span translate>SETUP.SYNC_BTN</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div class="version">
  APP {{ environment.VERSION }}
</div>

<div class="dbversion">
  DB {{ applicationData.version }}
</div>

<app-modal id="modal-request-password" width="640" #mrp>
  <form (submit)="processAccess(mrp.data?.callback)" #passwordForm2="ngForm">
    <h1 class="page-title">{{ 'SETUP.MODAL.PASSWORD_TITLE' | translate }}</h1>
    <div class="_form-input" [class.error]="passwordSetupField.dirty && passwordSetupField.errors">
      <label class="_form-input__label" for="passwordSetup">{{ 'SETUP.MODAL.PASSWORD' | translate }}</label>
      <input class="_form-input__input" [(ngModel)]="passwordSetup" type="password" name="passwordSetup" id="passwordSetup" #passwordSetupField="ngModel" required/>
    </div>

    <div class="button-container">
      <button class="_button-container__button _button-container__button--bluegreen" type="submit" [disabled]="!passwordForm2.valid || loging">
        <span>{{ 'SETUP.MODAL.SUBMIT' | translate }}</span>
      </button>
    </div>
  </form>
</app-modal>
