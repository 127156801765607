import { Inject, Injectable } from '@angular/core';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

declare let cordova:any;

@Injectable()
export class ConfigurationData {

  public dataDirectory:any = 'assets';

  /**
	 * Constructor
	 */
  constructor() {

  }

  /**
	 * Init
	 */
  init() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

}
