// https://plnkr.co/edit/O7vV42pxlQ2y0wKDz0fj?p=preview
import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {
  private hModals: any = {};

  add(modal: any) {
    const id = modal.id;
    if (! id) {
      throw new Error('add(): Attempt to add a modal without an id');
    }

    this.hModals[id] = modal;
  }

  remove(id: string) {
    delete this.hModals[id];
  }

  open(id: string, data ?: any) {
    const modal = this.hModals[id];
    if (!modal) {
      throw new Error(`open(): There is no modal with id '${id}'`);
    }
    modal.open(data);
  }

  cancel(id: string) {
    const modal = this.hModals[id];
    if (!modal) {
      throw new Error(`cancel(): There is no modal with id '${id}'`);
    }
    modal.close();
  }
}
