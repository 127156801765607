import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationStart, NavigationEnd } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ApplicationState, ApplicationStates } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ConfigurationData } from '~/app/shared/data/configuration.data';
import { UserService } from '~/app/shared/services/user.service';
import { ModalService } from '~/app/shared/modal/modal.service';
import { AnalyticsService } from '~/app/shared/services/analytics.service';
import { NavigationService } from '~/app/shared/services/navigation.service';

import { appTransition } from './app.animations';

declare let FastClick:any;
declare let AndroidFullScreen:any;
declare var Fullscreen : any; // Windows fullscreen (cordova-fs-plugin-fullscreen)
declare let TweenMax:any;
declare let Cubic:any;
declare let ga:any;
declare let analytics:any;

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [ appTransition ],
})
export class AppComponent {

  public environment = environment;
  public ContextTypes = ContextTypes;

  private currentBodyOffset:number;

  private loaderTimeout:number;
  private prehomeTimeout:number;

  /**
	 * Constructor
	 */
  constructor(public applicationState:ApplicationState, private applicationData:ApplicationData,
              private translate:TranslateService, private localize:LocalizeRouterService,
              private userService:UserService, private modalService: ModalService,
              private analyticsService:AnalyticsService, private router:Router, private navigation:NavigationService,
              private splashScreen:SplashScreen, private configuration:ConfigurationData) {

    this.translate.setDefaultLang('fr');

    if (environment.CONTEXT == ContextTypes.APP) {
      document.addEventListener('deviceready', () => { this.init(); }, false);
    } else {
      this.init();
    }

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loaderTimeout = window.setTimeout(() => { this.applicationState.loading = true; this.loaderTimeout = null; }, 300);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.applicationState.loading = false;
        if (this.loaderTimeout) {
          clearTimeout(this.loaderTimeout);
          this.loaderTimeout = null;
        }
      } else if (event instanceof NavigationStart) {
        if (this.prehomeTimeout) {
          clearTimeout(this.prehomeTimeout);
          this.prehomeTimeout = null;
        }
      } else if (event instanceof NavigationEnd) {
        this.applicationState.backURL2 = null;
        this.prehomeTimeout = window.setTimeout(() => {
          if (!this.applicationState.preventScreensaver) {
            this.applicationData.emptyCart();
            this.router.navigate([this.localize.translateRoute('/')]);
          }
        }, environment.IDLE_TIME * 1000);
      }
    });

    this.navigation.startup();
  }

  /**
   * Init App
   */
  public init():void {

    this.applicationState.state = ApplicationStates.READY;
    this.applicationState.backURL = '/applications';

    this.analyticsService.init(environment.GOOGLE_ANALYTICS_ID);

    FastClick.attach(document.body);

    if (environment.CONTEXT == ContextTypes.APP) {



	  // Windows HACK
	  environment.BASE = window['cordova'].file.dataDirectory;

		if(navigator.userAgent.match(/(iPod|iPad|iPhone)/)){
			window['cordova'].plugins.Keyboard.hideKeyboardAccessoryBar(false);
		}

      // Force fullscreen
      if (typeof AndroidFullScreen !== 'undefined') {
        AndroidFullScreen.isSupported(() => {
          AndroidFullScreen.immersiveMode();
        }, () => {
        });
      }
	  if(typeof Fullscreen !== 'undefined'){
        Fullscreen.on();
      }


      // Hide splash
      this.splashScreen.hide();

      // Listen states
      document.addEventListener('pause', () => { this.pause(); }, false);
      document.addEventListener('resume', () => { this.resume(); }, false);


      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        this.currentBodyOffset = 0;
        window.addEventListener('native.keyboardshow', (e:any) => {
          const lastKnownKeyboardHeight:number = e.keyboardHeight;
          if (document.activeElement) {
            if ((document.activeElement.tagName === 'INPUT') || (document.activeElement.tagName === 'TEXTAREA')) {
              const _focusedElmt:any = document.activeElement.getBoundingClientRect();
              if ((_focusedElmt.bottom - this.currentBodyOffset) > (window.innerHeight * 0.9 - lastKnownKeyboardHeight)) {
                const _desiredYPosition = (window.innerHeight - (lastKnownKeyboardHeight + _focusedElmt.height)) * .5;
                const _delta = _desiredYPosition - (_focusedElmt.top - this.currentBodyOffset);
                this.currentBodyOffset = _delta;
                document.body.style.transform = 'translateY(' + _delta + 'px)';
                document.body.style.webkitTransform = 'translateY(' + _delta + 'px)';
              } else {
                document.body.style.transform = 'translateY(0)';
                document.body.style.webkitTransform = 'translateY(0)';
                this.currentBodyOffset = 0;
              }
            } else {
              document.body.style.transform = 'translateY(0)';
              document.body.style.webkitTransform = 'translateY(0)';
              this.currentBodyOffset = 0;
            }
          } else {
            document.body.style.transform = 'translateY(0)';
            document.body.style.webkitTransform = 'translateY(0)';
            this.currentBodyOffset = 0;
          }
        });

        window.addEventListener('native.keyboardhide', () => {
          document.body.style.transform = 'translateY(0)';
          document.body.style.webkitTransform = 'translateY(0)';
          this.currentBodyOffset = 0;
        });
      }
    } else {

    }


    // Init config
    this.userService.init();
    this.configuration.init().then(response => {
      if (environment.CONTEXT == ContextTypes.APP) {
        this.applicationData.load().catch((e:Error) => {
        });
      }
    }).catch((e:Error) => {

    });
  }

  /**
	 * Cordova app states
	 */
  pause():void {
    this.applicationState.state = ApplicationStates.PAUSED;
  }
  resume():void {
    this.applicationState.state = ApplicationStates.READY;
  }

  /**
  * Logout
  */
 logout() {
   this.userService.logout();
 }

  /**
   * getState
   */
  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
