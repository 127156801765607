import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationData, DocumentType } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
import { LocalizeRouterService } from 'localize-router';
import { UserService, UserCategory } from '~/app/shared/services/user.service';
import { ToolsService } from '~/app/shared/services/tools.service';
/**
 * This class represents the medialibrary component.
 */
var MediaListComponent = (function () {
    /**
       * Constructor
       */
    function MediaListComponent(applicationData, applicationState, toolsService, userService, modalService, router, route, localize) {
        this.applicationData = applicationData;
        this.applicationState = applicationState;
        this.toolsService = toolsService;
        this.userService = userService;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.localize = localize;
        this.environment = environment;
        this.filter = "-1";
        this.filter2 = "-1";
        this.DocumentType = DocumentType;
        this._selectedFilter = '-1';
        this._selectedFilter2 = '-1';
        this._newsFilter = false;
        this.isApp = environment.CONTEXT == ContextTypes.APP;
    }
    Object.defineProperty(MediaListComponent.prototype, "selectedFilter", {
        get: function () {
            return this._selectedFilter;
        },
        set: function (value) {
            this._selectedFilter = value;
            this.filter = this.selectedFilter;
            this.applicationState.selectedFilter = this.selectedFilter;
            this.updateMedia();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaListComponent.prototype, "selectedFilter2", {
        get: function () {
            return this._selectedFilter2;
        },
        set: function (value) {
            this._selectedFilter2 = value;
            this.filter2 = this.selectedFilter2;
            this.applicationState.selectedFilter2 = this.selectedFilter2;
            this.updateMedia();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaListComponent.prototype, "newsFilter", {
        get: function () {
            return this._newsFilter;
        },
        set: function (value) {
            this._newsFilter = value;
            this.filter2 = this.newsFilter ? '1' : '-1';
            this.applicationState.newsFilter = this.newsFilter;
            this.updateMedia();
        },
        enumerable: true,
        configurable: true
    });
    /**
       * On init
       */
    MediaListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.applicationState.backURL2 = '/medialibrary';
        this.applications = this.applicationData.applications;
        this.routeSub = this.route.params.subscribe(function (params) {
            _this.mediaType = +params['id'];
            _this._selectedFilter = _this.applicationState.selectedFilter;
            _this._selectedFilter2 = _this.applicationState.selectedFilter2;
            _this._newsFilter = _this.applicationState.newsFilter;
            _this.filter = _this._selectedFilter;
            _this.filter2 = _this.newsFilter ? '1' : _this._selectedFilter2;
            _this.allMedia = _this.applicationData.getMediaByType(_this.mediaType);
            _this.media = _this.allMedia;
            _this.ranges = _this.applicationData.ranges;
            _this.updateMedia();
        });
    };
    /**
     * Update media
     */
    MediaListComponent.prototype.updateMedia = function () {
        var _this = this;
        switch (this.mediaType) {
            case DocumentType.PRODUCT:
                this.media = this.allMedia.filter(function (data) {
                    if (data.visibility != 0 && _this.userService.category != UserCategory.SYLVANIA) {
                        return false;
                    }
                    if (_this.filter2 != '-1' && _this.filter != '-1') {
                        return (data.ranges.includes(_this.filter) && (data.isNew || data.brandNew));
                    }
                    else if (_this.filter != '-1') {
                        return (data.ranges.includes(_this.filter));
                    }
                    else if (_this.filter2 != '-1') {
                        return (data.isNew || data.brandNew);
                    }
                    else {
                        return true;
                    }
                });
                break;
            case DocumentType.CATALOG:
                this.media = this.allMedia.filter(function (data) {
                    if (data.visibility != 0 && _this.userService.category != UserCategory.SYLVANIA) {
                        return false;
                    }
                    return (_this.filter == '-1' || (data.catalogueTarif == _this.filter));
                });
                break;
            case DocumentType.SPECIFIC:
                this.media = this.allMedia.filter(function (data) {
                    if (data.visibility != 0 && _this.userService.category != UserCategory.SYLVANIA) {
                        return false;
                    }
                    if (_this.filter2 != '-1' && _this.filter != '-1') {
                        return (data.applications.includes(_this.filter) && data.typeBrochure == _this.filter2);
                    }
                    else if (_this.filter != '-1') {
                        return (data.applications.includes(_this.filter));
                    }
                    else if (_this.filter2 != '-1') {
                        return (data.typeBrochure == _this.filter2);
                    }
                    else {
                        return true;
                    }
                });
                break;
            default:
                this.media = this.allMedia;
        }
    };
    /**
       * Add to cart
       */
    MediaListComponent.prototype.addToCart = function (e, pid) {
        e.preventDefault();
        this.applicationData.addToCart(pid);
    };
    /**
     * Remove from cart
     */
    MediaListComponent.prototype.removeFromCart = function (e, pid) {
        e.preventDefault();
        this.applicationData.removeFromCart(pid);
    };
    /**
       * Goto
       */
    MediaListComponent.prototype.goto = function (url) {
        this.applicationState.fromURL = '/medialist/' + this.mediaType;
        this.router.navigate([this.localize.translateRoute(url.join('/'))]);
    };
    /**
     * On destroy
     */
    MediaListComponent.prototype.ngOnDestroy = function () {
        this.routeSub.unsubscribe();
    };
    return MediaListComponent;
}());
export { MediaListComponent };
