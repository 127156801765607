// https://plnkr.co/edit/O7vV42pxlQ2y0wKDz0fj?p=preview
import { ElementRef, EventEmitter, } from '@angular/core';
import { ModalService } from './modal.service';
var ModalComponent = (function () {
    function ModalComponent(modalSvc, element) {
        this.modalSvc = modalSvc;
        this.element = element;
        this.visiblityState = 'hidden';
        this.onOK = new EventEmitter();
    }
    ModalComponent.prototype.ngOnInit = function () {
        if (!this.id) {
            throw new Error('modal must have an id');
        }
        this.body = document.getElementsByTagName('body')[0];
        this.body.appendChild(this.element.nativeElement);
        this.modalSvc.add(this);
    };
    ModalComponent.prototype.animationStarted = function (e) {
        if (e.toState == 'shown') {
            this.element.nativeElement.style.display = 'block';
        }
    };
    ModalComponent.prototype.animationEnded = function (e) {
        if (e.toState == 'hidden') {
            this.element.nativeElement.style.display = 'none';
        }
    };
    ModalComponent.prototype.getStyles = function () {
        if (this.width) {
            return {
                'max-width': this.width.toString() + 'px',
                'margin-left': 'auto',
                'margin-right': 'auto'
            };
        }
        return {};
    };
    ModalComponent.prototype.ngOnDestroy = function () {
        this.modalSvc.remove(this.id);
        this.element.nativeElement.remove();
    };
    ModalComponent.prototype.open = function (d) {
        this.data = d;
        this.body.classList.add('modal-open');
        this.visiblityState = 'shown';
    };
    ModalComponent.prototype.close = function () {
        this.visiblityState = 'hidden';
        this.body.classList.remove('modal-open');
        if (this.onClose) {
            this.onClose();
        }
    };
    return ModalComponent;
}());
export { ModalComponent };
