import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FileTransfer } from '@ionic-native/file-transfer';
import { File } from '@ionic-native/file';
import { FileOpener } from '@ionic-native/file-opener';
import { DocumentViewer } from '@ionic-native/document-viewer';
import { ScreenOrientation } from '@ionic-native/screen-orientation';


import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { SafeUrlPipe, FilterPipe, Nl2BrPipe } from './shared.pipes';

import { ModalService } from './modal/modal.service';
import { ModalComponent } from './modal/modal.component';

import { LoaderComponent } from './loader/loader.component';

import { AnalyticsService } from './services/analytics.service';
import { NavigationService } from './services/navigation.service';
import { ToolsService } from './services/tools.service';
import { UserService } from './services/user.service';
import { ApplicationData } from './data/application.data';
import { ApplicationState } from './data/application.state';
import { ConfigurationData } from './data/configuration.data';
import { DataGuard, AuthGuard } from '../app-routing.guards';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SwiperModule,
    TranslateModule.forChild({})
  ],
  declarations: [
    FilterPipe,
    LoaderComponent,
    ModalComponent,
    SafeUrlPipe,
    Nl2BrPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FilterPipe,
    LoaderComponent,
    ModalComponent,
    SafeUrlPipe,
    Nl2BrPipe,
    SwiperModule,
    TranslateModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AnalyticsService,
        NavigationService,
        ToolsService,
        UserService,
        ApplicationData,
        ApplicationState,
        ConfigurationData,
        DataGuard,
        AuthGuard,
        FileTransfer,
        File,
        FileOpener,
		    DocumentViewer,
        ModalService,
        ScreenOrientation,
        {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG
        }
      ]
    };
  }
}
