import { Component } from '@angular/core';

/**
 * This class represents the medialibrary component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-privacy',
  templateUrl: 'privacy.component.html',
  styleUrls: ['privacy.component.scss'],
  host: {'class': 'screen'}
})

export class PrivacyComponent {
  /**
	 * Constructor
	 */
  constructor() {

  }

  /**
	 * On init
	 */
  ngOnInit() {

  }

}
