import { EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, animate, style, transition } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';
import { ToolsService } from '~/app/shared/services/tools.service';
export var cartTransition = trigger('cartTransition', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)')
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0 })),
    ])
]);
/**
 * This class represents the cart component.
 */
var CartComponent = (function () {
    /**
       * Constructor
       */
    function CartComponent(toolsService, applicationData, applicationState, localize, router, route, location, modalService, userService) {
        this.toolsService = toolsService;
        this.applicationData = applicationData;
        this.applicationState = applicationState;
        this.localize = localize;
        this.router = router;
        this.route = route;
        this.location = location;
        this.modalService = modalService;
        this.userService = userService;
        this.modalClose = new EventEmitter();
        this.mediaToDelete = [];
        this.type = "-1";
        this.typeDetail = "";
        this.confirmDelete = false;
        this.itemSelected = false;
        this.privacyLink = this.localize.translateRoute('/privacy');
    }
    Object.defineProperty(CartComponent.prototype, "canDelete", {
        get: function () {
            for (var id in this.mediaToDelete) {
                if (this.mediaToDelete[id]) {
                    return true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
       * On init
       */
    CartComponent.prototype.ngOnInit = function () {
        this.applicationState.backURL = ['/', { outlets: { pop: null } }];
        this.media = this.applicationData.cart;
        for (var i = 0; i < this.media.length; i++) {
            this.mediaToDelete[+this.media[i].id] = false;
        }
    };
    /**
       * Delete selected product
       */
    CartComponent.prototype.deleteSelected = function () {
        for (var id in this.mediaToDelete) {
            if (this.mediaToDelete[id]) {
                this.applicationData.removeFromCart(+id);
            }
        }
        this.mediaToDelete = [];
        this.media = this.applicationData.cart;
        for (var i = 0; i < this.media.length; i++) {
            this.mediaToDelete[+this.media[i].id] = false;
        }
        this.confirmDelete = false;
        this.itemSelected = false;
    };
    CartComponent.prototype.toggleModal = function () {
        this.confirmDelete = !this.confirmDelete;
    };
    /**
     * Register
     */
    CartComponent.prototype.register = function () {
        var _this = this;
        this.submiting = true;
        this.applicationData.saveRequest(this.type, this.typeDetail, this.name, this.surname, this.position, this.company, this.email, this.zip, this.phone, this.media, this.optin == '1' ? '1' : '0', this.userService.id).then(function (response) {
            _this.submitted = true;
            _this.submiting = false;
            _this.applicationData.emptyCart();
        }).catch(function (e) {
            _this.submiting = false;
            _this.modalService.open('modal-error', { title: 'FATAL ERROR', text: 'Fatal error. Please try again.' });
        });
        return false;
    };
    /**
       * Close
       */
    CartComponent.prototype.backHome = function () {
        var _this = this;
        this.router.navigate([this.localize.translateRoute('/'), { outlets: { pop: null } }]).then(function () { return _this.router.navigate([_this.localize.translateRoute('/applications')]); });
        ;
    };
    /**
       * Close
       */
    CartComponent.prototype.gotoPrivacy = function () {
        var _this = this;
        console.log('exit');
        this.router.navigate([{ outlets: { pop: null } }], {
            relativeTo: this.route.parent // <--- PARENT activated route.
        }).then(function () { return _this.router.navigate([_this.privacyLink]); });
    };
    /**
       * Close
       */
    CartComponent.prototype.closeModal = function ($event) {
        //this.router.navigate([this.localize.translateRoute('/'), { outlets: { pop: null } }]);
        if ($event === void 0) { $event = null; }
        if (history.length <= 1) {
            this.router.navigate([this.localize.translateRoute('/')]);
        }
        else {
            this.location.back();
        }
    };
    return CartComponent;
}());
export { CartComponent };
