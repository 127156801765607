import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule, LocalizeRouterSettings, LocalizeParser } from 'localize-router';
import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { VideoComponent } from '~/app/video/video.component';
import { CartComponent } from '~/app/cart/cart.component';
import { CatalogComponent } from '~/app/catalog/catalog.component';
import { MedialibraryComponent } from '~/app/medialibrary/medialibrary.component';
import { MediaListComponent } from '~/app/medialist/medialist.component';
import { AboutComponent } from './about/about.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LegalsComponent } from './legals/legals.component';
import { ResetComponent } from './reset/reset.component';

import { AuthGuard, DataGuard } from './app-routing.guards';
import { AppCustomPreloader } from './app-routing.loader';

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

const routes = [
  { path: 'applications', loadChildren: './applications/applications.module#ApplicationsModule', data: { preload: true } },
  { path: 'applications/:id', redirectTo:'applications/:id/zones', pathMatch: 'full' },
  { path: 'applications/:id/zones', loadChildren: './applications/applications.module#ApplicationsModule' },
  { path: 'applications/:id/zones/gallery', loadChildren: './applications/applications.module#ApplicationsModule'},
  { path: 'applications/:id/zones/:id2', loadChildren: './applications/applications.module#ApplicationsModule'},
  { path: 'applications/:id/zones/:id2/:id3', loadChildren: './applications/applications.module#ApplicationsModule'},
  { path: 'medialist', redirectTo:'medialist/1',},
  { path: 'medialist/:id', component: MediaListComponent, data: { state: 'medialist' }, canActivate: [ AuthGuard ]},
  { path: 'medialibrary', component: MedialibraryComponent, data: { state: 'medialibrary' }, canActivate: [ AuthGuard ]},
  { path: 'about', component: AboutComponent, canActivate: [ AuthGuard ]},
  { path: 'privacy', component: PrivacyComponent },
  { path: 'legals', component: LegalsComponent },
  { path: 'reset/:id/:id2', component: ResetComponent},
  { path: ':id', component: VideoComponent, outlet: 'video', canActivate: [ AuthGuard ] },
  { path: 'cat', component: CatalogComponent, outlet: 'pop', canActivate: [ AuthGuard ] },
  { path: 'cart', component: CartComponent, outlet: 'pop', canActivate: [ AuthGuard ] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  providers:[ AppCustomPreloader ],
  exports: [ RouterModule, LocalizeRouterModule ]
})
export class AppRoutingModule {}
