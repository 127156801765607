import { ActivatedRoute, Router } from '@angular/router';
import { transition, trigger } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';
import { ContextTypes } from '~/environments/environment.types';
import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';
export var resetTransition = trigger('resetTransition', [
    transition(':leave', [])
]);
var ResetComponent = (function () {
    /**
       * Constructor
       */
    function ResetComponent(modalService, userService, router, route, localize) {
        var _this = this;
        this.modalService = modalService;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.localize = localize;
        this.sendingPassword = false;
        this._showConfirmModal = false;
        this.tokenSubscription = this.route.params.subscribe(function (params) {
            _this.origin = +params['id'];
            _this.token = params['id2'];
        });
    }
    /**
       * On init
       */
    ResetComponent.prototype.ngOnInit = function () {
    };
    /**
     * Send password
     */
    ResetComponent.prototype.sendPassword = function (modal) {
        var _this = this;
        if (modal === void 0) { modal = false; }
        this.sendingPassword = true;
        this.userService.resetPassword(this.password, this.token, this.origin).then(function (result) {
            if (result.code == 200) {
                _this.modalService.open('modal-reset-confirm', { title: 'AUTH.PASSWORD_UPDATED_TITLE', text: 'AUTH.PASSWORD_UPDATED_TEXT' });
            }
            else {
                _this.modalService.open('modal-reset-confirm', { title: 'AUTH.PASSWORD_UPDATED_TITLE', text: 'AUTH.PASSWORD_UPDATED_TEXT_KO' });
            }
            _this.sendingPassword = false;
        }).catch(function (e) {
            console.log(e);
            _this.sendingPassword = false;
        });
        return false;
    };
    /**
     * On close
     */
    ResetComponent.prototype.onCloseConfirm = function () {
        var _this = this;
        return function () {
            if (_this.origin == ContextTypes.WEB) {
                _this.router.navigate(_this.userService.redirectAfterLogin);
            }
            else {
            }
        };
    };
    /**
       * On destroy
       */
    ResetComponent.prototype.ngOnDestroy = function () {
        this.tokenSubscription.unsubscribe();
    };
    return ResetComponent;
}());
export { ResetComponent };
