import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, animate, style, transition, query } from '@angular/animations';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';
import { ToolsService } from '~/app/shared/services/tools.service';

declare let cordova:any;
declare let device:any;

export const cartTransition = trigger('cartTransition', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)')
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.6s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0 })),
  ])
]);

/**
 * This class represents the cart component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-cart',
  templateUrl: 'cart.component.html',
  styleUrls: ['cart.component.scss'],
  animations: [cartTransition],
  host: {
    '[@cartTransition]': '',
    'class': 'popscreen popscreen--wh'
  },

})

export class CartComponent implements OnInit {

  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  public privacyLink:string;

  public media: Array<any>;
  public mediaToDelete: Array<any> = [];

  public get canDelete(): boolean {
    for (const id in this.mediaToDelete) {
      if (this.mediaToDelete[id]) {
        return true;
      }
    }
  }

  public optin: string;
  public type: string = "-1";
  public typeDetail: string = "";
  public name: string;
  public surname: string;
  public position: string;
  public company: string;
  public email: string;
  public zip: string;
  public phone: string;
  public submiting: boolean;
  public submitted: boolean;
  public confirmDelete: boolean;
  public itemSelected: boolean;

  /**
	 * Constructor
	 */
  constructor(
    public toolsService: ToolsService,
    private applicationData: ApplicationData,
    private applicationState: ApplicationState,
    private localize: LocalizeRouterService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    private modalService: ModalService,
    private userService: UserService,
  ) {
    this.confirmDelete = false;
    this.itemSelected = false;

    this.privacyLink = this.localize.translateRoute('/privacy') as string;
  }

  /**
	 * On init
	 */
  ngOnInit() {
    this.applicationState.backURL = ['/', { outlets: { pop: null } }];
    this.media = this.applicationData.cart;

    for (let i = 0; i < this.media.length; i++) {
      this.mediaToDelete[+this.media[i].id] = false;
    }
  }

  /**
	 * Delete selected product
	 */
  deleteSelected() {
    for (const id in this.mediaToDelete) {
      if (this.mediaToDelete[id]) {
        this.applicationData.removeFromCart(+id);
      }
    }

    this.mediaToDelete = [];
    this.media = this.applicationData.cart;
    for (let i = 0; i < this.media.length; i++) {
      this.mediaToDelete[+this.media[i].id] = false;
    }

    this.confirmDelete = false;
    this.itemSelected = false;
  }

  toggleModal() {
    this.confirmDelete = !this.confirmDelete;
  }

  /**
   * Register
   */
  register(): boolean {
    this.submiting = true;

    this.applicationData.saveRequest(
                                  this.type,
                                  this.typeDetail,
                                  this.name,
                                  this.surname,
                                  this.position,
                                  this.company,
                                  this.email,
                                  this.zip,
                                  this.phone,
                                  this.media,
                                  this.optin == '1' ? '1' : '0',
                                  this.userService.id
                                ).then(response => {
      this.submitted = true;
      this.submiting = false;
      this.applicationData.emptyCart();
    }).catch((e: Error) => {
      this.submiting = false;
      this.modalService.open('modal-error', { title: 'FATAL ERROR', text: 'Fatal error. Please try again.' });
    });

    return false;
  }

  /**
	 * Close
	 */
  backHome() {
    this.router.navigate([this.localize.translateRoute('/'), { outlets: { pop: null } }]).then(() => this.router.navigate([this.localize.translateRoute('/applications')]));;
  }

  /**
	 * Close
	 */
  gotoPrivacy() {
    console.log('exit');
    this.router.navigate([{ outlets: { pop: null } }],
    {
        relativeTo: this.route.parent // <--- PARENT activated route.
    }).then(() => this.router.navigate([this.privacyLink]));
  }

  /**
	 * Close
	 */
  closeModal($event = null) {
    //this.router.navigate([this.localize.translateRoute('/'), { outlets: { pop: null } }]);

    if (history.length <= 1) {
      this.router.navigate([this.localize.translateRoute('/')]);
    } else {
      this.location.back();
    }

  }

}
