import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';
import { UserService } from '~/app/shared/services/user.service';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';
/**
 * This class represents the footer component.
 */
var FooterComponent = (function () {
    /**
       * Constructor
       */
    function FooterComponent(userService, applicationData, applicationState, location, router, localize) {
        this.userService = userService;
        this.applicationData = applicationData;
        this.applicationState = applicationState;
        this.location = location;
        this.router = router;
        this.localize = localize;
        this.environment = environment;
        this.isApp = environment.CONTEXT == ContextTypes.APP;
        this.isiOS = (/iPhone|iPad|iPod/i.test(navigator.userAgent));
        this.isAndroid = (/Android/i.test(navigator.userAgent));
    }
    Object.defineProperty(FooterComponent.prototype, "fullFunctionnalities", {
        /**
           * Full functionnalities ?
           */
        get: function () {
            if (environment.CONTEXT == ContextTypes.WEB) {
                return (this.router.url + '/' != this.localize.translateRoute('/')
                    && this.router.url != this.localize.translateRoute('/auth')
                    && this.router.url != this.localize.translateRoute('/register')
                    && this.router.url.indexOf(this.localize.translateRoute('/reset')) < 0);
            }
            else {
                return (this.userService.loggedIn
                    && this.router.url + '/' != this.localize.translateRoute('/')
                    && this.router.url != this.localize.translateRoute('/auth')
                    && this.router.url != this.localize.translateRoute('/register')
                    && this.router.url.indexOf(this.localize.translateRoute('/reset')) < 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Toggle fullscreen
     */
    FooterComponent.prototype.toggleFullscreen = function (e) {
        e.preventDefault();
        document.body.classList.toggle('fullscreen');
    };
    /**
       * Back
       */
    FooterComponent.prototype.back = function () {
        if (history.length <= 1) {
            this.router.navigate([this.localize.translateRoute('/')]);
        }
        else {
            this.location.back();
        }
    };
    /**
       * Switch lang
       */
    FooterComponent.prototype.switchLanguage = function (lang) {
        this.localize.changeLanguage(lang);
    };
    FooterComponent.prototype.openLink = function (e) {
        if (environment.CONTEXT == ContextTypes.APP) {
            e.preventDefault();
            cordova.InAppBrowser.open(e.currentTarget.href, '_blank', 'location=no');
        }
    };
    return FooterComponent;
}());
export { FooterComponent };
