import { AuthComponent } from './auth/auth.component';
import { RegisterComponent } from './register/register.component';
var ɵ0 = { state: 'auth' }, ɵ1 = { state: 'register' };
var authRoutes = [
    { path: 'auth', component: AuthComponent, data: ɵ0, pathMatch: 'full' },
    { path: 'register', component: RegisterComponent, data: ɵ1, pathMatch: 'full' }
];
var AuthRoutingModule = (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
export { ɵ0, ɵ1 };
