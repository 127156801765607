import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreHomeComponent } from './prehome/prehome.component';
import { LocalizeRouterModule } from 'localize-router';

const prehomeRoutes: Routes = [
  { path: '',  component: PreHomeComponent, data: { state: 'prehome' }, pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forChild(prehomeRoutes),
    LocalizeRouterModule.forChild(<any> prehomeRoutes)
  ],
  exports: [ RouterModule, LocalizeRouterModule ]
})
export class PreHomeRoutingModule { }
