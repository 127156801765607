import { PreHomeComponent } from './prehome/prehome.component';
var ɵ0 = { state: 'prehome' };
var prehomeRoutes = [
    { path: '', component: PreHomeComponent, data: ɵ0, pathMatch: 'full' }
];
var PreHomeRoutingModule = (function () {
    function PreHomeRoutingModule() {
    }
    return PreHomeRoutingModule;
}());
export { PreHomeRoutingModule };
export { ɵ0 };
