import { Router } from '@angular/router';
import { transition, trigger } from '@angular/animations';
import { LocalizeRouterService } from 'localize-router';
import { ModalService } from '~/app/shared/modal/modal.service';
import { UserService } from '~/app/shared/services/user.service';
export var authTransition = trigger('authTransition', [
    transition(':leave', [])
]);
var AuthComponent = (function () {
    /**
       * Constructor
       */
    function AuthComponent(userService, modalService, router, localize) {
        this.userService = userService;
        this.modalService = modalService;
        this.router = router;
        this.localize = localize;
        this.loging = false;
        this.sendingPassword = false;
        this.loginEmail = this.userService.email;
    }
    /**
       * On init
       */
    AuthComponent.prototype.ngOnInit = function () {
        this.registerLink = this.localize.translateRoute('/register');
    };
    /**
     * Login
     */
    AuthComponent.prototype.login = function (event) {
        var _this = this;
        if (event === void 0) { event = null; }
        if (event) {
            event.stopImmediatePropagation();
        }
        this.loging = true;
        this.userService.login(this.loginEmail, this.loginPassword).then(function (result) {
            _this.loging = false;
            if (result.code === 200) {
                if (_this.userService.needUpdate) {
                    _this.router.navigate([_this.localize.translateRoute('/reset/1/' + _this.userService.token)]);
                }
                else {
                    _this.router.navigate(_this.userService.redirectAfterLogin);
                }
            }
            else {
                _this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: result.data });
            }
        }).catch(function (e) {
            _this.loging = false;
            _this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: 'Veuillez vérifier que vous êtes connecté à internet avant de recommencer' });
        });
        return false;
    };
    /**
     * Open lost password modal
     */
    AuthComponent.prototype.openLostPasswordModal = function () {
        this.passwordEmail = this.loginEmail;
        this.modalService.open('modal-login-lost');
    };
    /**
     * Send password
     */
    AuthComponent.prototype.sendPassword = function (modal) {
        var _this = this;
        if (modal === void 0) { modal = false; }
        this.sendingPassword = true;
        this.userService.sendPassword(this.passwordEmail).then(function (result) {
            if (result.code == 200) {
                _this.modalService.cancel('modal-login-lost');
                _this.modalService.open('modal-login-confirm', { title: 'AUTH.PASSWORD_SENT', text: 'AUTH.PASSWORD_SENT_TEXT' });
            }
            else {
                //this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: result.data });
                _this.modalService.cancel('modal-login-lost');
                _this.modalService.open('modal-login-confirm', { title: 'AUTH.PASSWORD_SENT', text: 'AUTH.PASSWORD_SENT_TEXT' });
            }
            _this.sendingPassword = false;
        }).catch(function (e) {
            _this.modalService.open('modal-login-message', { title: 'GLOBAL.ERROR', text: 'GLOBAL.ERROR_UNKNOWN' });
            _this.sendingPassword = false;
        });
        return false;
    };
    /**
     * On close
     */
    AuthComponent.prototype.onCloseConfirm = function () {
        var _this = this;
        return function () {
            _this.router.navigate([_this.localize.translateRoute('/')]);
        };
    };
    /**
       * On destroy
       */
    AuthComponent.prototype.ngOnDestroy = function () {
        this.userService.resetAfterLogin();
    };
    return AuthComponent;
}());
export { AuthComponent };
