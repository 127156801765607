import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';

import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { File } from '@ionic-native/file';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { UserService, UserCategory } from '~/app/shared/services/user.service';
import { ModalService } from '~/app/shared/modal/modal.service';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ApplicationState } from '~/app/shared/data/application.state';

import { FakeData } from '~/app/shared/data/fake.data';

export enum SetupStates {
  LOADING = 1,
  READY = 2,
  ERROR = 3,
  NEED_UPDATE = 4,
}

declare var zip:any;

@Component({
  moduleId: module.id,
  selector: 'app-setup',
  templateUrl: 'setup.component.html',
  styleUrls: ['setup.component.scss'],
  host: {
    'class': 'popscreen popscreen--wh'
  },
})
export class SetupComponent implements OnInit, OnDestroy {

  public environment = environment;
  public state:SetupStates = SetupStates.LOADING;
  public SetupStates = SetupStates;

  public loging: boolean = false;
  public passwordSetup: string;

  public newData:any;

  private currentFile:string = "";

  /**
   * Constructor
   */
  constructor(
    public applicationData:ApplicationData,
    private userService: UserService,
    private http: HttpClient,
    private applicationState:ApplicationState,
    private transfer: FileTransfer,
    private file: File,
    private modalService: ModalService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    private zone: NgZone
  ) {
  }

  /**
   * On init
   */
  ngOnInit() {
    if (window['plugins'] && window['plugins'].insomnia) {
      window['plugins'].insomnia.keepAwake();
    }

    this.applicationState.preventScreensaver = true;

    console.log("Get online data...");
    this.http.get(environment.REMOTE_SERVER+'/api/?c='+(new Date().getTime())+'&uid='+this.applicationState.userID).subscribe( (responseData) => {
      let data: any = responseData;
      if (this.applicationState.userID == 'fr.info@sylvania-lighting.com') {
        data.data = FakeData.defaults;
      }

      console.log("  Online version:", data.data['version']);
      console.log("  Local version:", this.applicationData.version);
      if (data.data['version'] == this.applicationData.version) {
        console.log("    -> ok");
        this.state = SetupStates.READY;
      } else {
        console.log("    -> update");
        this.newData = data;
        this.state = SetupStates.NEED_UPDATE;
      }
    }, (err) => {
      console.log("    Are we offline?");
      this.state = SetupStates.ERROR;
    });

    this.state = SetupStates.NEED_UPDATE;
    // console.log("Get dir content");
    // this.file.listDir(environment.BASE + '/api/uploads', 'e-catalog').then(console.log.bind(console))
    //   .catch(console.error.bind(console));
  }

  createParentDirectoriesIfNoExist(dataDirectoryRelativeFilePath:string){
	  // console.log("createParentDirectoriesIfNoExist("+dataDirectoryRelativeFilePath+")");
	  return new Promise<void>((resolve:any, reject:any) => {
		  let _pathLvls:Array<string> = dataDirectoryRelativeFilePath.split("/");
		  _pathLvls.pop();
		  let _levelCount:number = _pathLvls.length;
		  // console.log("\t_pathLvls", _pathLvls);
		  let _current:number = 0;
		  let _currentPath:string = this.file.dataDirectory;

		  let _createNextLevel:Function = () => {
			  if(_current>=_levelCount){
				  // console.log("\tALL CHECKED");
				  resolve();
			  } else if(_pathLvls[_current] == ""){
				  _current++;
				  _createNextLevel();
			  } else {
				this.file.checkDir(_currentPath, _pathLvls[_current]).then(() => {
					// console.log("\tcheckDir("+_pathLvls[_current]+") FROM "+_currentPath+" => EXIST");
					_currentPath += _pathLvls[_current]+"/";
					_current++;
					_createNextLevel();
				}).catch((err:any) => {
					// console.log("\tcheckDir("+_pathLvls[_current]+") FROM "+_currentPath+" => DON'T EXIST");
					this.file.createDir(_currentPath, _pathLvls[_current], false).then(() => {
						// console.log("\t\tDIR "+_pathLvls[_current]+" CREATED.");
						_currentPath += _pathLvls[_current]+"/";
						_current++;
						_createNextLevel();
					}).catch((err:any) => {
						// console.log("\t\tDIR "+_pathLvls[_current]+" CREATION FAIL !");
						reject(err);
					});
				});

			  }
		  }

		  _createNextLevel();

	  });
  }

  /**
   * Request access
   */
  requestAccess(callback) {
    this.modalService.open('modal-request-password', { callback: callback });
  }

  /**
   * Request access
   */
  processAccess(callback) {
    this.loging = true;
    this.userService.check(
                          this.userService.email,
                          this.passwordSetup
                        ).then((result: any) => {
    this.loging = false;
    this.modalService.cancel('modal-request-password');
    if (result.code === 200) {
      if (callback) {
        callback.bind(this)();
      }
    } else {
      this.modalService.open('modal-error', { title: 'GLOBAL.ERROR', text: result.data });
    }
  }).catch((e: Error) => {
    console.log(e);
    this.loging = false;
    this.modalService.cancel('modal-request-password');
    this.modalService.open('modal-error', { title: 'GLOBAL.ERROR', text: 'Veuillez vérifier que vous êtes connecté à internet avant de recommencer' });
  });
  }

  /**
   * Synchronize
   */
  synchronize() {
    console.log("Synchronize...");
    if (environment.CONTEXT == ContextTypes.APP) {
      this.applicationState.loading = true;
      this.state = SetupStates.LOADING;

      // Changed Files
      let addFiles:Array<string> = [];
      for (let i = 0; i < this.newData.data.library.length; i++) {
        if (this.newData.data.library[i].src) {
          if (this.applicationData.data && this.applicationData.data.library) {
            let search = this.applicationData.data.library.find((data) => {
              return data.src == this.newData.data.library[i].src && data.hash == this.newData.data.library[i].hash;
            });

            if (!search) {
              console.log("  Add:",this.newData.data.library[i].src);
              addFiles.push('/api/uploads/'+this.newData.data.library[i].src);
            }
          } else {
            console.log("  Add:",this.newData.data.library[i].src);
            addFiles.push('/api/uploads/'+this.newData.data.library[i].src);
          }
        }
      }

      // Removed Files
      let removePromises:Array<Promise<any>> = [];
      if (this.applicationData.data && this.applicationData.data.library) {
        for (let i = 0; i < this.applicationData.data.library.length; i++) {
          if (this.applicationData.data.library[i].src) {
            let search = this.newData.data.library.find((data) => {
              return data.src == this.applicationData.data.library[i].src;
            });
            if (!search) {
              removePromises.push(this.file.removeFile(environment.BASE+'/api/uploads', this.applicationData.data.library[i].src));
            }
          }
        }
      }

      // Locales
      console.log("  Update: locales.json");
      addFiles.push('/assets/locales.json');

      console.log("  Update: locales/xx.json");
      addFiles.push('/assets/locales/fr.json');
      //addFiles.push('/assets/locales/en.json');

      let options = {
        headers: {
          Connection: 'close'
        }
      };

      addFiles.reduce((p, theFile) => {

        return p.then(() => {
          this.currentFile = theFile;
    			this.applicationState.loadingLabel = "Download " + theFile;
    			return new Promise<void>((resolve:any, reject:any) => {
    				this.createParentDirectoriesIfNoExist(theFile).then(()=>{
              let t:any = this.transfer.create();
              t.onProgress((progressEvent) => {
                this.zone.run(() => {
                  if (progressEvent.lengthComputable) {
                    var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
                    this.applicationState.loadingLabel = "Download " + theFile + " ("+perc+"%)";
                    this.ref.detectChanges();
                  }
                });
              });
    					//t.download('http://sylvania.passerelle.com' + theFile, environment.BASE + theFile, true, options).then(()=>{
    					t.download(environment.REMOTE_SERVER + theFile, environment.BASE + theFile, true, options).then(()=>{
                var re = /(?:\.([^.]+))?$/;
                if ((re.exec(theFile)[1]).toLowerCase() == 'zip') {
                  zip.unzip(environment.BASE + theFile, environment.BASE+'/api/uploads', () => {
                    this.file.removeFile(environment.BASE+'/api/uploads', theFile.replace(/^.*[\\\/]/, '')).then(()=>{});

                    resolve();
                  }, (progress) => {

                  });
                } else {
                  resolve();
                }
    					}, (error) => {
                reject(error);
              });
    				}).catch(error => {
    					reject(error);
    				});
    			});
    		});

      }, Promise.resolve()).then(()=>{
        console.log("  -> All files ready");

        console.log("  Delete orphan files");
        Promise.all(removePromises).then(values => {
          console.log("  --> Removed");
        }).catch(reasons => {
          console.log("  --> Failed");
          console.log(reasons);
        });

        this.translate.reloadLang('fr').subscribe();
        //this.translate.reloadLang('en').subscribe();

        console.log("  Update: data/data.json");

        this.applicationState.loadingLabel = "Download " + "data/data.json";

        // Data
        this.transfer.create().download(environment.REMOTE_SERVER+'/api/', environment.BASE+'assets/data/datas.json').then((entry) => {
          this.applicationData.load().then(response => {
            this.state = SetupStates.READY;
            this.applicationState.loading = false;
          }).catch((e:Error) => {
            this.modalService.open('modal-error', {title:'GLOBAL.FATAL_ERROR', text:'SETUP.FATAL_ERROR'});
            this.state = SetupStates.ERROR;
            this.applicationState.loading = false;
          });
        }, (error) => {
          this.modalService.open('modal-error', {title:'GLOBAL.FATAL_ERROR', text:'SETUP.FATAL_ERROR'});
          this.state = SetupStates.ERROR;
          this.applicationState.loading = false;
        });
      }).catch(reasons => {
        console.log("  -> Failed");
        console.log(reasons);
        this.modalService.open('modal-error', {title:'GLOBAL.FATAL_ERROR', text:'SETUP.FATAL_ERROR'});
        this.state = SetupStates.NEED_UPDATE;
        this.applicationState.loading = false;
      });
    }
  }

  /**
   * Synchronize requets
   */
  synchronizeRequests() {
    this.applicationState.loading = true;
    this.applicationData.synchronizeRequests().then(response => {
      this.applicationState.loading = false;
    }).catch((e:Error) => {
      this.modalService.open('modal-error', {title:'GLOBAL.FATAL_ERROR', text:'SETUP.FATAL_ERROR_SYNC'});
      this.applicationState.loading = false;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this.applicationState.preventScreensaver = false;

    if (window['plugins'] && window['plugins'].insomnia) {
      window['plugins'].insomnia.allowSleepAgain();
    }
  }
}
